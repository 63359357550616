import React from 'react';
import { assetsUri } from '../../constants/AppConstants';
import { Vocab } from '../../store/VocabStore';
import { renameFile } from '../../utilities';

interface Props {
    vocab: Vocab;
    textClass: string;
}

interface WordProps {
    word: string;
    textClass: string;
}

const AudioWord: React.FunctionComponent<WordProps> = (props: WordProps) => {
    const {word, textClass} = props;
    const file = `${assetsUri}/audio/firstoccurrence/${renameFile(word)}.mp3`;
    
    const play = () => {
        const audio = new Audio(file);
        audio.play();
    }
    return <span className={`cursor-pointer ${textClass} font-bold`} onClick={play}>{word}</span>
}

const VocabWord: React.FunctionComponent<Props> = (props: Props) => {
    const { vocab, textClass } = props;
    const pluralNoun = vocab.pluralNoun ? `[<b>${vocab.pluralNoun}</b>]` : '';
    let addition = '';
    // add verb-specific content
    if (vocab.simplePast) {
        addition += `[<b>`;
        if (vocab.stemChange) {
            addition += `${vocab.stemChange} | `;
        }
        addition += `${vocab.simplePast} | ${vocab.pastPart}</b>]`;
    }
    return (
        <span>
            {props.vocab.article} <AudioWord word={vocab.word} textClass={textClass}/> <span dangerouslySetInnerHTML={{__html: pluralNoun}}/> <span dangerouslySetInnerHTML={{__html: addition}}/> <span className="translation" dangerouslySetInnerHTML={{__html:props.vocab.translation}}/>
        </span>
    );
}

export default VocabWord;
