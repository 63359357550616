import { ValueType } from "react-select/src/types";

export type Option<T> = {value: T, label: string}
export type NumberOption = Option<number> | null | undefined
export type NumberValueType = ValueType<{value: number, label: string}, any>
export type StringOption = Option<string> | null | undefined
export type StringValueType = ValueType<{value: string, label: string}, any>

export const singleSelectOnChange = (option: NumberValueType, handler: (option: NumberOption) => void) => {
    if (Array.isArray(option)) {
        throw new Error("Unexpected type");
    }

    handler(option as NumberOption);
}
