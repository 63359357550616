export const priorityCompare = (keysToCompare: string[], query: string) => {
    const regex = RegExp(query, "i");

    // compare two results and determine which should appear first
    // heuristic: take the one that has the match starting earlier
    return function(a: any, b: any) {
        let indexA = Infinity;
        let indexB = Infinity;

        keysToCompare.forEach((key) => {
            const aValue = a[key];
            const bValue = b[key];
            if (aValue && bValue)
            {
                let locA = a[key].search(regex);
                if (locA >= 0 && locA < indexA) {
                    indexA = locA;
                }
    
                let locB = b[key].search(regex);
                if (locB >= 0 && locB < indexB) {
                    indexB = locB;
                }
            }
        });
 
        // break ties with shorter word length (match takes up more of word)
        if (indexA !== indexB) {
            return indexA - indexB;
        }

        // TODO: break ties by length with proper typing
        // return a[keyA].length - b[keyB].length;
        return 0;
    };
}

export function getObjectValues<T>(object: {[key: string]: T} & {[key: number]: T}) {
    return Object.keys(object).map(x => object[x]);
}

export function renameFile(text: string) {
    text = text.toLowerCase();
    text = text.replace(/ /g, "_");
    text = text.replace(/\//g, "_");
    text = text.replace(/ä/g, "ae");
    text = text.replace(/ö/g, "oe");
    text = text.replace(/ü/g, "ue");
    text = text.replace(/ß/g, "ss");
    text = text.replace(/•/g, "_");
    text = text.replace("...", "_");
    text = text.replace("…", "_");
    return text;
}

export function makeId(length: number) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
