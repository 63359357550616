import React, { useState, useEffect, useRef } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faStop, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faPlay, faStop, faPause);

interface Props {
    readonly type: "play" | "stop" | "pause";
    readonly color: string;
    readonly handleButtonClick: Function;
}

const AudioButton: React.FC<Props> = (props: Props) => {
    const [hovered, setHovered] = useState(false);
    const setHoveredTrue = () => setHovered(true);
    const setHoveredFalse = () => setHovered(false);

    const node: React.RefObject<HTMLDivElement> | null = useRef(null);

    useEffect(() => {
        const currentNode = node && node.current;
        if (currentNode) {
            currentNode.addEventListener('mouseover', setHoveredTrue);
            currentNode.addEventListener('mouseout', setHoveredFalse);
        }

        return () => {
            if (currentNode) {
                currentNode.removeEventListener('mouseover', setHoveredTrue);
                currentNode.removeEventListener('mouseout', setHoveredFalse);
            }
        }
    }, []);

    const backgroundColor = hovered ? 'black' : props.color;
    const divStyle: React.CSSProperties = {
        backgroundColor: backgroundColor,
        width: '25px',
        height: '25px',
        display: 'inline-block',
        borderRadius: '13px',
        position: 'relative',
        cursor: 'pointer'
    };

    const left = props.type === 'play' ? '9px' : '8px';
    const iconStyle: React.CSSProperties = {
        color: 'white',
        position: 'absolute',
        left: left,
        top: '6px'
    };

    return (
        <div style={divStyle} ref={node} onClick={() => props.handleButtonClick(props.type)}>
            <FontAwesomeIcon icon={props.type} style={iconStyle}/>
        </div>
    )
}

export default AudioButton;
