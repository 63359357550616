import React from 'react';

import { assetsUri } from '../../constants/AppConstants';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { kapitelSubject, kapitelValueSubject, themaValueSubject, bgThemaSubject } from '../../store/EnvironmentStore';
import ThemaLink from '../Content/ThemaLink';

const Banner: React.FunctionComponent = () => {
  const kapitel = useBehaviorSubject(kapitelSubject);
  const bgThema = useBehaviorSubject(bgThemaSubject);
  const kapitelValue = useBehaviorSubject(kapitelValueSubject);
  const kapitelTitle = (kapitelValue && kapitelValue.name) || '';
  const themaValue = useBehaviorSubject(themaValueSubject);
  const themaTitle = (themaValue && themaValue.name) || '';

  let bannerStyle = {
    backgroundImage: `url(${assetsUri}/images/kapitel${kapitel}/smalltalkbanner.jpg)`,
  };

  return (
    <section className={`bg-cover bg-no-repeat bg-center h-120 grid`} style={bannerStyle}>
      <div className="w-content justify-self-center">
        <h1 className='mt-4'>{kapitelTitle}</h1>
        <div className='flex'>
          <h2 className={`${bgThema} text-white px-4 py-2`} dangerouslySetInnerHTML={{__html:themaTitle}}/>
        </div>
        <div className='flex'>
          <div className='bg-black h-14 inline-flex content-center justify-start text-3xl items-center text-center'>
            <div className='flex-none p-2.5 text-white font-open'>Thema</div>
            <ThemaLink kapitel={kapitel} thema={1}/>
            <ThemaLink kapitel={kapitel} thema={2}/>
            <ThemaLink kapitel={kapitel} thema={3}/>
            <ThemaLink kapitel={kapitel} thema={4}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
