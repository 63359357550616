import React, { useEffect } from 'react';

import FullLayout from '../Layout/FullLayout';
import Banner from './Banner';
import Section from '../Sections/Section';
import Loader from '../Core/Loader';

import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { colorSubject, kapitelSubject, themaSubject } from '../../store/EnvironmentStore';
import { requestSlides, requestSlideOrders, currentContentSlidesBySlideOrderSubject, slideRequestStatusSubject, slideOrderRequestStatusSubject } from '../../store/SlideStore';
import { requestVocab } from '../../store/VocabStore';
import { activeFormIdSubject, currentContentResponsesSubject, requestUserResponses, responseStatusesSubject } from '../../store/ResponseStore';
import { isAuthenticatedSubject, userDataSubject } from '../../store/AuthStore';
import { createPortal } from 'react-dom';
import SubmitStatus from '../Core/SubmitStatus';


const fetchData = (kapitel: number, thema: number, isAuthenticated: boolean) => {
    requestVocab(kapitel, thema);
    if (isAuthenticated) {
        requestSlides(kapitel, thema);
        requestSlideOrders(kapitel, thema);
        requestUserResponses(kapitel, thema);
    }
}

const ContentPage = () => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const isAuthenticated = useBehaviorSubject(isAuthenticatedSubject);
    const userData = useBehaviorSubject(userDataSubject);
    const slides = useBehaviorSubject(currentContentSlidesBySlideOrderSubject);
    const slidesRequestStatus = useBehaviorSubject(slideRequestStatusSubject);
    const slideOrdersRequestStatus = useBehaviorSubject(slideOrderRequestStatusSubject);
    const responses = useBehaviorSubject(currentContentResponsesSubject);
    const activeFormId = useBehaviorSubject(activeFormIdSubject);
    const color = useBehaviorSubject(colorSubject);
    const responseStatuses = useBehaviorSubject(responseStatusesSubject);

    useEffect(() => {
        fetchData(kapitel, thema, isAuthenticated);
    }, [kapitel, thema, isAuthenticated]);

    const className = 'thema' + thema;

    const int1Slides = slides.filter(x => x.slideTypeId === 1);
    const struktSlides = slides.filter(x => x.slideTypeId === 2);
    const int2Slides = slides.filter(x => x.slideTypeId === 3);
    const int1Responses = responses;
    const int2Responses = responses;
    const struktResponses = responses;
    const submitStatusElement = document.getElementById(`submit_status_${activeFormId}`);
    const responseStatus = (activeFormId !== null && userData !== null && responseStatuses[`${activeFormId}_${userData.id}`]) || null;

    return (
        <FullLayout>
            <div className={className}>
                <Loader isLoading={slidesRequestStatus === 'PROCESSING' || slideOrdersRequestStatus === 'PROCESSING'}/>
                <Banner />
                <Section slides={int1Slides} responses={int1Responses} type='int1' />
                <Section slides={struktSlides} responses={struktResponses} type='strukt' />
                <Section slides={int2Slides} responses={int2Responses} type='int2' />
            </div>
            {submitStatusElement !== null && createPortal(<SubmitStatus status={responseStatus} color={color}/>, submitStatusElement)}
        </FullLayout>
    );
}

export default ContentPage;
