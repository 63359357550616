import React from 'react';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { bgThemaSubject } from '../../store/EnvironmentStore';
import { Slide } from '../../store/SlideStore';

interface Props {
  slide: Slide;
  isActive: boolean;
  setSelected: () => void;
}

const SideBarLabel = (props: Props) => {
  const bgThema = useBehaviorSubject(bgThemaSubject);
  const background = props.isActive ? 'bg-black' : bgThema;
  const hover = props.isActive ? '' : 'hover:bg-stone-700 transition ease-in-out duration-400';
  return (
    <div className={`${background} h-[4.5rem] flex items-center justify-center border-b-white
      border-b text-center cursor-pointer ${hover}`}
      onClick={props.setSelected}
      >
      <h5 className='font-bold font-open text-base'>{props.slide.label}</h5>
    </div>
  );
};

export default SideBarLabel;
