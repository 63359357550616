import React from 'react';
import Modal, {Styles} from 'react-modal';

import Button from '../Core/Button';

import { uploadUsersFile, UploadUser } from '../../store/UserStore';

interface UserFileUploadModalProps {
    readonly show: boolean;
    readonly close: () => void;
    readonly createOrUpdateUsers: (users: UploadUser[]) => void;
    readonly color: string;
    readonly users: UploadUser[];
}

const UserFileUploadModal: React.SFC<UserFileUploadModalProps> = (props: UserFileUploadModalProps) => {
    const { users } = props;

    const setUsersFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            uploadUsersFile(file);
        }
    }

    const renderTable = () => {
        if (users.length === 0) {
            return null;
        }
        return (
            <table className="table width-100pc data-table">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Institution</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                {users.map(user =>
                    <tr key={`user_add_modal_${user.email}`}>
                        <td>{user.email}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.institution}</td>
                        <td>{user.role}</td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    const renderBody = () => {
        if (users.length === 0) {
            return (
                <div>
                    <p>Use this feature to add or update multiple users at once. If a given user with the same email address already exists, its values will be updated.</p>
                    <p>The uploaded file must be a CSV file with columns for email, first name, last name, institution, and role.</p>
                </div>
            )
        }
        return (
            <div>
                <p>Please review the changes below and then click 'Create' to create or update the users.</p>
            </div>
        )
    }

    const { show, close, color, createOrUpdateUsers } = props;
    return (
        <Modal isOpen={show} onRequestClose={close} style={modalStyle}>
            <div className="modal-header">
                <h4>User Upload</h4>
            </div>
            <div className="modal-body">
                {renderBody()}
                {renderTable()}
            </div>
            <div className="modal-footer">
                <Button onClick={close} color={color} _key={'cancel_create/update_users_button'} style={{marginRight: '20px'}}>
                    Cancel
                </Button>
                {
                    users.length === 0
                    ? <span>
                        <label htmlFor="user-upload" style={{...buttonStyle, backgroundColor: color, marginRight: '20px'}}>Choose File</label>
                        <input style={{display: 'none'}} id="user-upload" type="file" name="userUpload" onChange={(ev) => setUsersFile(ev)} accept=".csv" />
                    </span>
                    : <Button onClick={() => {createOrUpdateUsers(users); close()}} color={color} _key={'create/update_users_button'}>
                        Create/Update Users
                    </Button>
                }
            </div>
        </Modal>
    );
}

const buttonStyle: React.CSSProperties = {
    color: 'white',
    padding: 10,
    textTransform: 'uppercase',
    border: 'none',
    fontFamily: 'Helvetica, Arial, sans',
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
};

const modalStyle: Styles = {
    content: {
        top: '200px',
        width: '960px',
        margin: 'auto',
        zIndex: 1000,
        position: 'relative',
        maxHeight: '50%',
        overflowY: 'auto',
    }
}

export default UserFileUploadModal;
