import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import produce from 'immer';
import { getJson, postJson, del } from '../utilities/Requests';
import memoize from 'fast-memoize';
import { getObjectValues } from '../utilities';
import history from '../history';
import { RequestStatus, Roles, apiUri } from '../constants/AppConstants';
import { addArrayToBehaviorMap, addSingleToBehaviorMap, deleteSingleFromBehaviorMap } from '../utilities/SubjectHelpers';
import { apiCall } from '../utilities/RequestHelpers';
import { jwtSubject } from './AuthStore';
import { handleErrorMessage, showInfoModal } from './ErrorStore';

export interface User {
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string;
    readonly roleIds: number[];
    readonly institutionId: number;
    readonly email: string;
}

export interface UploadUser {
    readonly firstName: string;
    readonly lastName: string;
    readonly role: string;
    readonly institution: number;
    readonly email: string;
}

export interface UserWithRoles extends User {
    readonly roles: string[];
}

export interface CognitoUser {
    readonly enabled: boolean;
    readonly userCreateDate: Date;
    readonly userLastModifiedDate: Date;
    readonly username: string;
    readonly userStatus: { value : string };
    readonly attributes: { name: string, value: string }[];
}

export const importedUsersSubject = new BehaviorSubject<UploadUser[]>([]);
export const createOrUpdateUserStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const requestUserStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const usersSubject = new BehaviorSubject<{ [key: number]: UserWithRoles }>({});
export const userCognitoUsers = new BehaviorSubject<{ [key: number]: CognitoUser[] }>({});

export const usersToList = memoize((users: { [key: number]: UserWithRoles }) => getObjectValues(users));

export const uploadUsersFile = async (file: any) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const nextData = await axios.post<UploadUser[]>(`${apiUri}/users/importFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${jwtSubject.value}`
            }
        })
        importedUsersSubject.next(produce(importedUsersSubject.value, () => nextData.data));
    }
    catch (err) {
        handleErrorMessage('dddUploadUserFileError', 'An error occurred while uploading the users file. This could be because the input file is not correctly formatted. For more assistance in resolving this error, please contact the site administrator.', err.stackTrace, true);
    }
}

export const createOrUpdateUser = async (user: User) => {
    await apiCall(async () => {
        const response = await postJson<UserWithRoles>({
            url: `${apiUri}/users`,
            data: JSON.stringify(user)
        }, jwtSubject.value);

        addSingleToBehaviorMap(usersSubject, response);

        history.push('/users/' + response.id)
    }, createOrUpdateUserStatusSubject);
}

export const createOrUpdateUsers = async (usersToUpdate: UploadUser[]) => {
    await apiCall(async () => {
        const response = await postJson<UserWithRoles[]>({
            url: `${apiUri}/users/import`,
            data: JSON.stringify(usersToUpdate)
        }, jwtSubject.value);

        addArrayToBehaviorMap(usersSubject, response);
        showInfoModal('dddCreateOrUpdateUsersModalNotification', `${response.length} users successfully added or updated.`, 'User upload success');
    }, createOrUpdateUserStatusSubject, 'Failed to upload users. Please notify the site administrator.');
}

export const requestUsers = async (query: string | null) => {
    await apiCall(async () => {
        const qs = query ? '?' + query : '';
        const response = await getJson<UserWithRoles[]>({
            url: `${apiUri}/users${qs}`,
        }, jwtSubject.value);

        addArrayToBehaviorMap(usersSubject, response);
    }, requestUserStatusSubject);
}

export const requestCognitoUser = async (email: string, userId: number) => {
    await apiCall(async () => {
        const response = await getJson<CognitoUser[]>({
            url: `${apiUri}/users/cognito?email=${email}`,
        }, jwtSubject.value);

        userCognitoUsers.next(produce(userCognitoUsers.value, (draft: { [id: number]: CognitoUser[] }) => {
            draft[userId] = response;
            return draft;
        }));
    }, requestUserStatusSubject);
}

export const cognitoEnableUser = async (username: string, email: string, userId: number) => {
    await postJson<{}>({
        url: `${apiUri}/users/cognito/enable`,
        data: JSON.stringify({ userName: username })
    }, jwtSubject.value);

    showInfoModal('dddCognitoEnableUser', `Login access for "${email}" was successfully enabled`, 'Confirmation');

    await requestCognitoUser(email, userId);
}

export const cognitoDisableUser = async (username: string, email: string, userId: number) => {
    await postJson<{}>({
        url: `${apiUri}/users/cognito/disable`,
        data: JSON.stringify({ userName: username })
    }, jwtSubject.value);

    showInfoModal('dddCognitoDisableUser', `Login access for "${email}" was successfully disabled`, 'Confirmation');

    await requestCognitoUser(email, userId);
}

export const cognitoResetUserPassword = async (username: string, email: string, userId: number) => {
    await postJson<{}>({
        url: `${apiUri}/users/cognito/resetpassword`,
        data: JSON.stringify({ userName: username })
    }, jwtSubject.value);

    showInfoModal('dddCognitoResetUserPassword', `Password for "${email}" was successfully reset. The user will be required to reset their password at their next login.`, 'Confirmation');

    await requestCognitoUser(email, userId);
}

export const cognitoConfirmUserSignup = async (username: string, email: string, userId: number) => {
    await postJson<{}>({
        url: `${apiUri}/users/cognito/confirm`,
        data: JSON.stringify({ userName: username })
    }, jwtSubject.value);

    showInfoModal('dddCognitoConfirmUserSignup', `Access for "${email}" is confirmed.`, 'Confirmation');

    await requestCognitoUser(email, userId);
}

export const cognitoVerifyUserEmail = async (username: string, email: string, userId: number) => {
    await postJson<{}>({
        url: `${apiUri}/users/cognito/verify_email`,
        data: JSON.stringify({ userName: username })
    }, jwtSubject.value);

    showInfoModal('dddCognitoVerifyUserEmail', `Email address for "${email}" is verified.`, 'Confirmation');

    await requestCognitoUser(email, userId);
}

export const deleteUser = async (userId: number) => {
    try {
        await del<number>({
            url: `${apiUri}/users/${userId}`,
        }, jwtSubject.value);

        deleteSingleFromBehaviorMap(usersSubject, userId);

        history.push('/users');
    }
    catch (err) {
        console.log(err);
    }
}

export const clearImportedUsers = () => {
    importedUsersSubject.next(produce(importedUsersSubject.value, () => []));
}

const isInstructor = (user: User) => user.roleIds.indexOf(Roles.CourseInstructor) > -1 || user.roleIds.indexOf(Roles.SectionInstructor) > -1;

export const instructorsSubject = new BehaviorSubject<User[]>(getObjectValues(usersSubject.value).filter(isInstructor));

usersSubject.subscribe(users => {
    instructorsSubject.next(getObjectValues(users).filter(isInstructor));
});

const isStudent = (user: User) => user.roleIds.indexOf(Roles.Student) > -1;

export const studentsSubject = new BehaviorSubject<User[]>(getObjectValues(usersSubject.value).filter(isStudent));

usersSubject.subscribe(users => {
    studentsSubject.next(getObjectValues(users).filter(isStudent));
});
