import React from 'react';

import DDDModal from '../Core/DDDModal';

interface Props {
    sectionId: number;
    name: string;
    show: boolean;
    close: () => void;
    deleteSection: (sectionId: number) => void;
    color: string;
}

const SectionDeleteModal = (props: Props) => {

    const handleDeleteSection = () => {
        props.deleteSection(props.sectionId);
        props.close();
    }

    const { name } = props;
    return (
        <DDDModal
            show={props.show}
            title='Delete section confirmation'
            onSubmit={handleDeleteSection}
            submitText='Delete'
            close={props.close}
            >
            Are you sure you want to delete section {name}?
        </DDDModal>
    )
}

export default SectionDeleteModal;
