const markdown = `
* [Tech liaison overview](/info/techliaison)
* [**Auth overview**](/info/auth)
* [Common issues](/info/techliaison/commonissues)

## Auth overview

This page documents the identity, access, and permission logic involved in administration of **der |
die | das** users.

### Authentication

Authentication is concerned with _identity_: who is the user, and should they be allowed in our
site?

Authentication in **der | die | das** involves two distinct but correlated systems.

1. **Identity:** We use [AWS Cognito](https://aws.amazon.com/cognito/) as our identity provider.
   This services the primary function of authentication - to verify that users signing in are who
   they say they are. We support two methods of doing so:

   * **Google sign-in:** In this method, users simply sign in with their Google account. We trust
     Google, so if Google trusts that the user owns their email address, we do likewise. This
     option is recommended in cases in which institutions issue _.edu_ email addresses which are
     federated with Google.
   * **Email + Password:** In this method, users sign up with username + password. To verify
     ownership of their email address, AWS Cognito sends them a confirmation email, which expires
     after 24 hours.

2. **Identity cross-reference:** To prevent unfettered access of **der | die | das** to the public,
   user accounts are only granted to members of participating academic institutions and select
   guests. As such, we maintain a separate list of users in the **der | die | das** database. User
   sign-ups and sign-ins cross-reference this list to ensure that only permissioned users are
   granted access.

### Authorization

Authorization is concerned with _access_: assuming we know who the user is, what should they be able
to do, or be prevented from doing?

One important dimension which scopes permissions is the user's _institution_, which is a piece of
metadata associated with their record in the **der | die | das** database.

The remainder of their permissions are governed by roles:

| Role          | Access             | Scope |
| ---------     | ------------------ | ----- |
| Guest         | Content, responses | Only their own responses | 
| Student       | Content, responses | Only their own responses | 
| Instructor I  | Content, sections, responses | Responses of students in their sections | 
| Instructor II | Content, sections, responses, user accounts | Responses and accounts of users in their institution | 
| Admin | All | All institutions | 

`

export default markdown;
