import * as React from 'react';
import { HashLink as Link } from '../Core/HashLink';

import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { bgThemaSubject } from '../../store/EnvironmentStore';

const Footer = () => {
    const bgThema = useBehaviorSubject(bgThemaSubject);
    const year = new Date().getFullYear();

    return (
        <footer className='w-full'>
            <div className='bg-black h-48 py-4 font-sans flex justify-center'>
                <div className='w-content flex'>
                    <Link className='text-white hover:text-slate-300 mr-8' to={"/"}>Home</Link>
                    <a className='text-white hover:text-slate-300' href="mailto:jrankin@princeton.edu">Kontakt</a>
                </div>
            </div>
            <div className={`text-white h-24 flex justify-center ${bgThema} py-4 font-serif`}>
                <div className='w-content flex justify-between'>
                    <span>&copy;{year} The Trustees of Princeton University</span>
                    <span>Site by: CAPE design</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
