import React, { useState, useEffect } from 'react';

import FullLayout from '../Layout/FullLayout';
import Loader from '../Core/Loader';
import SubmitStatus from '../Core/SubmitStatus';
import UserDeleteModal from './UserDeleteModal';
import Button from '../Core/Button';
import { Option } from '../Core/OptionTypes';
import Tippy from '@tippyjs/react';

import { userIdSelector, setRoleIds, setEmail, setFirstName, setLastName, setInstitutionId, activeUserSubject } from '../../store/UserEdit';
import { rolesSubject } from '../../store/RolesStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { locationSubject } from '../../history';
import { requestUsers, createOrUpdateUser, deleteUser, requestUserStatusSubject, createOrUpdateUserStatusSubject, requestCognitoUser } from '../../store/UserStore';
import { isAdminSubject, userDataSubject, isCourseInstructorSubject } from '../../store/AuthStore';
import { institutionsSubject } from '../../store/InstitutionStore';
import { colorSubject } from '../../store/EnvironmentStore';
import ThemedReactSelect from '../Core/ThemedReactSelect';
import CognitoUserSummary from '../Users/CognitoUserSummary';

const UserEditPage: React.FC = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const userId = userIdSelector(useBehaviorSubject(locationSubject));
    const isAdmin = useBehaviorSubject(isAdminSubject);
    const userData = useBehaviorSubject(userDataSubject);
    const institutions = useBehaviorSubject(institutionsSubject);
    const roles = useBehaviorSubject(rolesSubject);
    const requestUserStatus = useBehaviorSubject(requestUserStatusSubject);
    const updateUserStatus = useBehaviorSubject(createOrUpdateUserStatusSubject);
    const activeUser = useBehaviorSubject(activeUserSubject);
    const color = useBehaviorSubject(colorSubject);
    const isCourseInstructor = useBehaviorSubject(isCourseInstructorSubject);

    const shouldDisableEdit = !isAdmin && !isCourseInstructor;

    useEffect(() => {
        if (userId !== null) {
            requestUsers(`userId=${userId}`);
        }
    }, [userId]);

    const email = (activeUser && activeUser.email) || '';
    const firstName = (activeUser && activeUser.firstName) || '';
    const lastName = (activeUser && activeUser.lastName) || '';
    const institutionId = (activeUser && activeUser.institutionId) || null;
    const roleIds = (activeUser && activeUser.roleIds) || [];

    useEffect(() => {
        if (userId) {
            requestCognitoUser(email, userId);
        }
    }, [email, userId]);

    const handleUpdate = () => {
        if (!email || !institutionId || !firstName || !lastName || !roleIds) {
            return null;
        }

        createOrUpdateUser({
            id: userId || 0,
            email,
            firstName,
            lastName,
            institutionId,
            roleIds
        });
    }

    const handleRoleUpdate = (option: Option<number>[]) => {
        if (option === null) {
            setRoleIds([]);
        }

        else {
            setRoleIds(option.map(x => x.value));
        }
    }

    const renderUpdateButton = () => {
        if (!email || !institutionId || !firstName || !lastName || !roleIds || shouldDisableEdit) {
            return <div />
        }
        const buttonText = userId ? "Update" : "Add";
        return <Button _key={'user_edit_button'} color={color} onClick={() => handleUpdate()}>{buttonText}</Button>;
    }

    const renderDeleteButton = () => {
        if (!userId || !(isAdmin || isCourseInstructor)) {
            return null;
        }

        return <Button _key={'user_edit_button'} style={{ marginLeft: 20 }} color={color} onClick={() => setShowDeleteModal(true)}>Delete</Button>;
    }

    let roleOptions = roles.map(role => ({ value: role.id, label: role.name }));
    let institutionOptions = institutions.map(i => ({ value: i.id, label: i.name }));

    if (!isAdmin) {
        institutionOptions = institutionOptions.filter(i => i.value === (userData && userData.institutionId));
        roleOptions = roleOptions.filter(r => r.label !== 'admin');
    }

    const institutionOption = institutionOptions.find(x => x.value === institutionId);
    const selectedRoles = roleOptions.filter(x => roleIds.indexOf(x.value) > -1);

    const unauthorizedTooltipWrapper = (component: any) => {
        if (shouldDisableEdit) {
            return <Tippy content={'You do not have permission to create or edit users. Please contact a course or site administrator if you wish to make changes to users.'} duration={100} hideOnClick={false}>
                <span>{component}</span>
            </Tippy>
        }

        return component;
    }

    return (
        <FullLayout>
            <div className='grid place-content-center'>
            <div className='w-content bg-white p-10 my-10'>
                <Loader isLoading={requestUserStatus === 'PROCESSING'} />
                <div className='grid grid-cols-2 font-serif'>
                    <div>
                        <h3>Basic Info</h3>
                        <div className='my-2'>
                            <label className='font-bold'>Email</label>
                            <div>{unauthorizedTooltipWrapper(<input className='w-48' disabled={shouldDisableEdit} type="text" name="email" value={email} onChange={(ev) => setEmail(ev.target.value)} />)}</div>
                        </div>
                        <div className='my-2'>
                            <label className='font-bold'>First Name</label>
                            <div>{unauthorizedTooltipWrapper(<input className='w-48' disabled={shouldDisableEdit} type="text" name="firstName" value={firstName} onChange={(ev) => setFirstName(ev.target.value)} />)}</div>
                        </div>
                        <div className='my-2'>
                            <label className='font-bold'>Last Name</label>
                            <div>{unauthorizedTooltipWrapper(<input className='w-48' disabled={shouldDisableEdit} type="text" name="lastName" value={lastName} onChange={(ev) => setLastName(ev.target.value)} />)}</div>
                        </div>
                        <div className='my-2'>
                            <label className='font-bold'>Institution</label>
                            <div style={{ width: 200 }}>
                                {unauthorizedTooltipWrapper(
                                    <ThemedReactSelect name="institutionId"
                                        isDisabled={shouldDisableEdit}
                                        color={color}
                                        options={institutionOptions}
                                        value={institutionOption}
                                        isClearable={false}
                                        onChange={(option: any) => setInstitutionId(option && option.value)} />)}
                            </div>
                        </div>
                        <div className='my-2'>
                            <label className='font-bold'>Roles</label>
                            <div style={{ width: 200 }}>
                                {unauthorizedTooltipWrapper(<ThemedReactSelect isDisabled={shouldDisableEdit} color={color} style={{ width: 200 }} isMulti={true} name="roles" options={roleOptions} value={selectedRoles} onChange={(option: any) => handleRoleUpdate(option)} />)}
                            </div>
                        </div>
                        <div className='my-5'>
                            {renderUpdateButton()}
                            {renderDeleteButton()}
                            <SubmitStatus color={color} status={updateUserStatus} />
                        </div>
                    </div>
                    <div>
                        <CognitoUserSummary />
                    </div>
                </div>
                {
                    userId && (
                        <UserDeleteModal
                            userId={userId}
                            firstName={firstName}
                            lastName={lastName}
                            close={() => setShowDeleteModal(false)}
                            show={showDeleteModal}
                            deleteUser={deleteUser}
                            isAdmin={isAdmin}
                            color={color}
                        />
                    )
                }
                </div>
            </div>
        </FullLayout>
    );
}

export default UserEditPage;
