import React, { useState, useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HashLink as Link } from '../Core/HashLink';
import FullLayout from '../Layout/FullLayout';
import Loader from '../Core/Loader';
import Button from '../Core/Button';

import { requestUsers, instructorsSubject } from '../../store/UserStore';
import { requestSections, sectionsSubject, sectionRequestStatusSubject, deleteSection } from '../../store/SectionStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { institutionsSubject } from '../../store/InstitutionStore';
import { getObjectValues } from '../../utilities';
import { colorSubject } from '../../store/EnvironmentStore';
import { isAdminSubject, isCourseInstructorSubject, isSectionInstructorSubject } from '../../store/AuthStore';

library.add(faTimes);

const SectionListPage: React.SFC = () => {
    const [sectionSearch, setSectionSearch] = useState('');

    const sections = getObjectValues(useBehaviorSubject(sectionsSubject));
    const sectionRequestStatus = useBehaviorSubject(sectionRequestStatusSubject);
    const institutions = useBehaviorSubject(institutionsSubject);
    const instructors = useBehaviorSubject(instructorsSubject);
    const color = useBehaviorSubject(colorSubject);
    const isAdmin = useBehaviorSubject(isAdminSubject);
    const isCourseInstructor = useBehaviorSubject(isCourseInstructorSubject);
    const isSectionInstructor = useBehaviorSubject(isSectionInstructorSubject);

    useEffect(() => {
        requestUsers(null);
        requestSections();
    }, []);

    let newSections = sections.slice();

    if (sectionSearch !== '') {
        let re = new RegExp(sectionSearch, 'i');
        newSections = sections.filter(u =>
            (u.name && u.name.match(re))
            || (instructors.filter(i => u.instructorIds.indexOf(i.id) > -1 && (i.lastName.match(re) || i.firstName.match(re))).length > 0)
            || ((institutions.find(i => i.id === u.institutionId) && JSON.stringify(institutions.find(i => i.id === u.institutionId)).match(re))))
    }

    const renderSectionAddButton = () => {
        if (isAdmin || isCourseInstructor) {
            return <Link to={`/sections/add`}>
                <Button _key={`add_sections_button`} color={color} onClick={() => {}} style={{ marginRight: '10px' }}>
                    Add Section
                </Button>
            </Link>
        }

        return null;
    }

    const renderSectionSearch = () => {
        if (sections.length < 8) {
            return null
        }

        return <>
            <p><label>Section Search</label></p>
            <p><input type="text" name="sectionSearch" value={sectionSearch} onChange={(ev) => setSectionSearch(ev.target.value)} /></p>
        </>
    }

    const renderSectionList = () => {
        if (newSections.length > 0) {
            return <table className='w-full'>
                <tbody className='divide-y divide-stone-300'>
                    <tr>
                        <th>Name</th>
                        <th>Instructor(s)</th>
                        <th>Number of Students</th>
                        <th/>
                    </tr>
                    {newSections.map(section => {
                        let instructorText = instructors
                            .filter(x => section.instructorIds.indexOf(x.id) > -1)
                            .map(x => `${x.firstName} ${x.lastName}`)
                            .join(', ');

                        return (
                            <tr key={`section_${section.id}`}>
                                <td><Link to={`/sections/${section.id}/edit`} className='text-thema1'>{section.name}</Link></td>
                                <td>{instructorText}</td>
                                <td>{section.studentIds.length}</td>
                                {isAdmin || isCourseInstructor ? <td onClick={() => deleteSection(section.id)}><FontAwesomeIcon icon="times"/></td> : null}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }

        if (!isAdmin && !isCourseInstructor && isSectionInstructor) {
            return <div>
                <p>You are not an instructor for any sections yet. Please contact a course instructor or administrator to create a course section.</p>
            </div>
        }

        if (isAdmin || isCourseInstructor) {
            return <div>
                <p>There are no visible sections. Please create one using the 'Add Section' button above.</p>
            </div>
        }
    }

    return (
        <FullLayout>
            <div style={{width: 940, marginRight: 'auto', marginLeft: 'auto', marginTop: 70, fontSize: 12, background: 'white', padding: 20, marginBottom: 50}}>
                <Loader isLoading={sectionRequestStatus === 'PROCESSING'}/>
                <div className="clearfix" style={{ paddingBottom: 20 }}>
                    <div className="floatleft">
                        {renderSectionSearch()}
                    </div>
                    <div className="floatright">
                        {renderSectionAddButton()}
                    </div>
                </div>
                {renderSectionList()}
            </div>
        </FullLayout>
    );
}

export default SectionListPage;
