export interface MenuItem {
    kapitel: number;
    thema: number;
    link: string;
    title: string;
}

export const MenuContent: MenuItem[] = [
    { kapitel: 1, thema: 1, link: 'A', title: 'A. der / die / das' },
    { kapitel: 1, thema: 1, link: 'B.1', title: 'B. Verbs with <strong>ich / du / Sie (singular)</strong>' },
    { kapitel: 1, thema: 2, link: 'C', title: 'C. Indefinite pronouns: <strong>ein / eine / ein</strong>' },
    { kapitel: 1, thema: 2, link: 'D', title: 'D. Noun plurals' },
    { kapitel: 1, thema: 2, link: 'E.1', title: 'E. Verbs with <strong>er / sie / es / sie (plural)</strong>' },
    { kapitel: 1, thema: 3, link: 'F.1', title: 'F. Pronouns and gender' },
    { kapitel: 1, thema: 3, link: 'G.1', title: 'G. Verbs with <strong>wir / ihr / Sie (plural)</strong>' },
    { kapitel: 1, thema: 3, link: 'H.1', title: 'H. Saying: <em>I like that / I like to do that</em>' },
    { kapitel: 1, thema: 4, link: 'I.1', title: 'I. Time / calendar expressions' },
    { kapitel: 1, thema: 4, link: 'J.1', title: 'J. Verbs: Summary' },

    { kapitel: 2, thema: 1, link: 'A.1', title: 'A. Nominative case' },
    { kapitel: 2, thema: 1, link: 'B', title: 'B. Using <strong>man</strong>' },
    { kapitel: 2, thema: 2, link: 'C.1', title: 'C. Using <strong>welch-/dies-</strong>' },
    { kapitel: 2, thema: 2, link: 'D', title: 'D. Using <strong>jed-/all-</strong>' },
    { kapitel: 2, thema: 3, link: 'E.1', title: 'E. Negation: <strong>nicht/kein</strong>' },
    { kapitel: 2, thema: 3, link: 'F', title: 'F. Using <strong>werden</strong>' },
    { kapitel: 2, thema: 4, link: 'G.1', title: 'G. Asking questions' },
    { kapitel: 2, thema: 4, link: 'I.1', title: 'H. Possessive adjectives' },

    { kapitel: 3, thema: 1, link: 'A.1', title: 'A. Using <strong>etwas</strong>' },
    { kapitel: 3, thema: 1, link: 'B.1', title: 'B. StemChange verbs' },
    { kapitel: 3, thema: 2, link: 'C', title: 'C. To go [jogging]: gehen + ...' },
    { kapitel: 3, thema: 2, link: 'D.1', title: 'D. Nominative: adjective endings' },
    { kapitel: 3, thema: 3, link: 'E.1', title: 'E. Separable-prefix verbs' },
    { kapitel: 3, thema: 3, link: 'F', title: 'F. Using <strong>war/hatte</strong>' },
    { kapitel: 3, thema: 4, link: 'G', title: 'G. Review: Negation' },
    { kapitel: 3, thema: 4, link: 'H', title: 'H. Negating Verbs' },

    { kapitel: 4, thema: 1, link: 'A.1', title: 'A. Accusative case' },
    { kapitel: 4, thema: 1, link: 'B', title: 'B. Position vs. case' },
    { kapitel: 4, thema: 1, link: 'C.1', title: 'C. Saying <em>there is/are</em>' },
    { kapitel: 4, thema: 2, link: 'D.1', title: 'D. Accusative: <strong>der-</strong>words/<strong>ein-</strong>words' },
    { kapitel: 4, thema: 2, link: 'E.1', title: 'E. Accusative: prepositions' },
    { kapitel: 4, thema: 3, link: 'F.1', title: 'F. Accusative: pronouns' },
    { kapitel: 4, thema: 3, link: 'G', title: 'G. Using <strong>mögen</strong>: <em>I like</em>' },
    { kapitel: 4, thema: 3, link: 'H.1', title: 'H. Negating direct objects' },
    { kapitel: 4, thema: 4, link: 'I.1', title: 'I. Accusative: adjective endings' },
    { kapitel: 4, thema: 4, link: 'J.1', title: 'J. N-nouns' },

    { kapitel: 5, thema: 1, link: 'A', title: 'A. Review: <strong>war/hatte</strong></span>' },
    { kapitel: 5, thema: 1, link: 'B.1', title: 'B. Perfect tense vs. Simple past</span>' },
    { kapitel: 5, thema: 1, link: 'C.1', title: 'C. Perfect tense: past participles</span>' },
    { kapitel: 5, thema: 2, link: 'D.1', title: 'D. Ordinal numbers</span>' },
    { kapitel: 5, thema: 2, link: 'E.1', title: 'E. Perfect tense: verbs with <strong>sein</strong></span>' },
    { kapitel: 5, thema: 3, link: 'F.1', title: 'F. Dative: articles/prepositions</span>' },
    { kapitel: 5, thema: 3, link: 'G', title: 'G. Dative: pronouns</span>' },
    { kapitel: 5, thema: 4, link: 'H.1', title: 'H. Dative: verbs/indirect objects</span>' },
    { kapitel: 5, thema: 4, link: 'I.1', title: 'I. Dative: adjective endings</span>'},

    { kapitel: 6, thema: 1, link: 'A', title: 'A. Review: V1 - V2 structure' },
    { kapitel: 6, thema: 1, link: 'B.1', title: 'B. Modals: können / müssen / wollen' },
    { kapitel: 6, thema: 2, link: 'C.1', title: 'C. Modals: dürfen / sollen' },
    { kapitel: 6, thema: 2, link: 'D.1', title: 'D. Modals: mögen-möchte' },
    { kapitel: 6, thema: 3, link: 'E.1', title: 'E. Negating modal verbs' },
    { kapitel: 6, thema: 3, link: 'F.1', title: 'F. Modals: Simple past' },
    { kapitel: 6, thema: 4, link: 'G.1', title: 'G. Kennen / wissen' },
    { kapitel: 6, thema: 4, link: 'H.1', title: 'H. Stand-alone modal verbs' },

    { kapitel: 7, thema: 1, link: 'A.1', title: 'A. Review: Prepositions (Acc./Dat.)' },
    { kapitel: 7, thema: 1, link: 'B.1', title: 'B. Variable prepositions: Dat.' },
    { kapitel: 7, thema: 1, link: 'C.1', title: 'C. Variable prepositions: Acc.' },
    { kapitel: 7, thema: 2, link: 'D.1', title: 'D. Reflexive pronouns: Acc.' },
    { kapitel: 7, thema: 2, link: 'E.1', title: 'E. Reflexive pronouns: Dat.' },
    { kapitel: 7, thema: 3, link: 'F.1', title: 'F. Verbs + prepositions' },
    { kapitel: 7, thema: 3, link: 'G.1', title: 'G. Reflexive verbs + prepositions' },
    { kapitel: 7, thema: 4, link: 'H.1', title: 'H. Da-compounds' },
    { kapitel: 7, thema: 4, link: 'I.1', title: 'I. Wo-compounds' },

    { kapitel: 8, thema: 1, link: 'A.1', title: 'A. Subject-verb inversion' },
    { kapitel: 8, thema: 1, link: 'B.1', title: 'B. Review: Grammatical cases' },
    { kapitel: 8, thema: 2, link: 'C.1', title: 'C. Infinitive clauses' },
    { kapitel: 8, thema: 2, link: 'D.1', title: 'D. Review: Pronouns' },
    { kapitel: 8, thema: 2, link: 'E.1', title: 'E. Review: Adjective endings' },
    { kapitel: 8, thema: 3, link: 'F.1', title: 'F. Review: Present tense' },
    { kapitel: 8, thema: 3, link: 'G.1', title: 'G. Review: Stem-change verbs' },
    { kapitel: 8, thema: 3, link: 'H.1', title: 'H. Review: Modal verbs' },
    { kapitel: 8, thema: 4, link: 'I.1', title: 'I. Review: Negation' },
    { kapitel: 8, thema: 4, link: 'J.1', title: 'J. Review: Perfect tense' },

    { kapitel: 9, thema: 1, link: 'A.1', title: 'A. Infinitive clauses' },
    { kapitel: 9, thema: 1, link: 'B.1', title: 'B. How to say in order to...' },
    { kapitel: 9, thema: 2, link: 'C.1', title: 'C. Simple Past vs. Perfect tense' },
    { kapitel: 9, thema: 2, link: 'D.1', title: 'D. Simple past I: modal verbs / mixed verbs' },
    { kapitel: 9, thema: 2, link: 'E.1', title: 'E. Simple Past II: weak verbs' },
    { kapitel: 9, thema: 3, link: 'F.1', title: 'F. Simple Past III: Strong verbs' },
    { kapitel: 9, thema: 3, link: 'G.1', title: 'G. Past Perfect tense' },
    { kapitel: 9, thema: 4, link: 'H.1', title: 'H. wurde / würde' },
    { kapitel: 9, thema: 4, link: 'I.1', title: 'I. Genitive case' },
    { kapitel: 9, thema: 4, link: 'J', title: 'J. Genitive prepositions' },

    { kapitel: 10, thema: 1, link: 'A.1', title: 'A. Review: Verbs in independent clauses' },
    { kapitel: 10, thema: 1, link: 'B.1', title: 'B. Dependent clauses / subordinating conjunctions' },
    { kapitel: 10, thema: 2, link: 'C.1', title: 'C. Wenn | als | wann | ob' },
    { kapitel: 10, thema: 2, link: 'D.1', title: 'D. Questions in subordinate clauses' },
    { kapitel: 10, thema: 3, link: 'E.1', title: 'E. Review: Adjective endings' },
    { kapitel: 10, thema: 3, link: 'F.1', title: 'F. Adjectival nouns' },
    { kapitel: 10, thema: 4, link: 'G', title: 'G. Genitive adjective endings: der/ein' },
    { kapitel: 10, thema: 4, link: 'H.1', title: 'H. Unpreceded adjectives + Genitive' },

    { kapitel: 11, thema: 1, link: 'A.1', title: 'A. Comparative / Superlative adverbs: Forms' },
    { kapitel: 11, thema: 1, link: 'B.1', title: 'B. Comparative / Superlative adverbs: Function' },
    { kapitel: 11, thema: 2, link: 'C.1', title: 'C. Comparative / Superlative adjectives: Forms' },
    { kapitel: 11, thema: 2, link: 'D.1', title: 'D. Comparative / Superlative adjectives: Function' },
    { kapitel: 11, thema: 3, link: 'E', title: 'E. Imperative mood' },
    { kapitel: 11, thema: 3, link: 'F.1', title: 'F. Imperative: Sie | du' },
    { kapitel: 11, thema: 4, link: 'G.1', title: 'G. Imperative: ihr | wir' },
    { kapitel: 11, thema: 4, link: 'H.1', title: 'H. Infinitival imperatives' },

    { kapitel: 12, thema: 1, link: 'A.1', title: 'A. Review: werden' },
    { kapitel: 12, thema: 1, link: 'B.1', title: 'B. Future tense' },
    { kapitel: 12, thema: 2, link: 'C.1', title: 'C. Review: Personal pronouns' },
    { kapitel: 12, thema: 2, link: 'D.1', title: 'D. Nouns and pronouns: Word order' },
    { kapitel: 12, thema: 3, link: 'E.1', title: 'E. Fixed combinations' },
    { kapitel: 12, thema: 3, link: 'F.1', title: 'F. Correlative conjunctions' },
    { kapitel: 12, thema: 4, link: 'G.1', title: 'G. Present Participles' },
    { kapitel: 12, thema: 4, link: 'H.1', title: 'H. Review: Reflexive Pronouns' },
    { kapitel: 12, thema: 4, link: 'I.1', title: 'I. Review: Reflexive verbs' },

    { kapitel: 13, thema: 1, link: 'A.1', title: 'A: Review: Strong verbs I' },
    { kapitel: 13, thema: 1, link: 'B.1', title: 'B: Review: Strong verbs II' },
    { kapitel: 13, thema: 2, link: 'C.1', title: 'C. The passive voice' },
    { kapitel: 13, thema: 2, link: 'D.1', title: 'D. Passive: Present tense' },
    { kapitel: 13, thema: 3, link: 'E.1', title: 'E. Passive: Simple past tense' },
    { kapitel: 13, thema: 3, link: 'F.1', title: 'F. Passive: by / by means of / with' },
    { kapitel: 13, thema: 4, link: 'G.1', title: 'G. Passive: Modal verbs' },
    { kapitel: 13, thema: 4, link: 'H.1', title: 'H. Passive: Perfect tense' },

    { kapitel: 14, thema: 1, link: 'A.1', title: 'A. Relative clauses' },
    { kapitel: 14, thema: 1, link: 'B.1', title: 'B. Relative clauses: Accusative' },
    { kapitel: 14, thema: 2, link: 'C.1', title: 'C. Relative clauses: Dative' },
    { kapitel: 14, thema: 2, link: 'D.1', title: 'D. hin | her' },
    { kapitel: 14, thema: 3, link: 'E', title: 'E. Relative clauses: Genitive' },
    { kapitel: 14, thema: 3, link: 'F.1', title: 'F. Relative clauses: Prepositions' },
    { kapitel: 14, thema: 4, link: 'G.1', title: 'G. Relative pronouns: was' },
    { kapitel: 14, thema: 4, link: 'H', title: 'H. Relative pronouns: Summary' },

    { kapitel: 15, thema: 1, link: 'A.1', title: 'A. Subjunctive' },
    { kapitel: 15, thema: 1, link: 'B.1', title: 'B. Present time subjunctive' },
    { kapitel: 15, thema: 2, link: 'C.1', title: 'C. Wenn ..., dann ... / Subjunctive modal verbs' },
    { kapitel: 15, thema: 2, link: 'D.1', title: 'D. Subjunctive forms: Strong + Weak verbs' },
    { kapitel: 15, thema: 3, link: 'E.1', title: 'E. Past subjunctive' },
    { kapitel: 15, thema: 3, link: 'F.1', title: 'F. Past subjunctive: Modal verbs' },
    { kapitel: 15, thema: 4, link: 'G.1', title: 'G. als ob / als hätte' },
    { kapitel: 15, thema: 4, link: 'H.1', title: 'H. Indirect Speech / Subjunctive I' },

    { kapitel: 16, thema: 1, link: 'A', title: 'A. Review: Introduction'},
    { kapitel: 16, thema: 1, link: 'B', title: 'B. Past Perfect tense'},
    { kapitel: 16, thema: 1, link: 'C.1', title: 'C. Simple Past'},
    { kapitel: 16, thema: 1, link: 'D', title: 'D. Future Tense'},
    { kapitel: 16, thema: 2, link: 'E', title: 'E. Imperative Mood'},
    { kapitel: 16, thema: 2, link: 'F.1', title: 'F. Subjunctive Mood'},
    { kapitel: 16, thema: 2, link: 'G.1', title: 'G. Passive Voice'},
    { kapitel: 16, thema: 3, link: 'H.1', title: 'H. Genitive case'},
    { kapitel: 16, thema: 3, link: 'I.1', title: 'I. Comparative/superlative modifiers'},
    { kapitel: 16, thema: 4, link: 'J.1', title: 'J. Subordinate clauses'},
    { kapitel: 16, thema: 4, link: 'K.1', title: 'K. Relative clauses'}
];
