import * as React from 'react';
import { Link } from 'react-router-dom';

import { MenuContent } from '../../constants/MenuContent';
import { themas } from '../../constants/AppConstants';

interface VocabMenuProps {
    kapitel: number;
    thema: number;
}

class VocabMenu extends React.PureComponent<VocabMenuProps> {
    render() {
        const { kapitel } = this.props;
        const kapitelMenuContent = MenuContent.filter(mc => mc.kapitel === kapitel);
        const menuContentByThemas = themas.map(thema => kapitelMenuContent.filter(mc => mc.thema === thema));

        const menuContent = menuContentByThemas.map(function(themaContent, themaIndex) {
        const links = themaContent.map(function(content, index) {
            return (
                <li key={'second_vocab_menu_' + index}
                    className={`h-10 grid content-center pl-4 bg-black hover:bg-stone-700
                    cursor-pointer invisible group-${themaIndex}-hover:visible
                    h-0 group-${themaIndex}-hover:h-10 transition-height ease-out`}>
                    <Link to={{ pathname: `/content/${content.kapitel}/${content.thema}`, state: {strukt: content.link, active: 'strukt', update: true}} }>
                        <span dangerouslySetInnerHTML={{__html:content.title}}/>
                    </Link>
                </li>
            );
        });
        return (
            <li key={'first_vocab_menu_' + themaIndex}
                className={`grid content-center pl-4 h-0 bg-black invisible hover:bg-stone-700 group-hover:visible
                    cursor-pointer relative group-${themaIndex} transition-height -out group-hover:h-10`}>
                <Link to={`/content/${kapitel}/${themaIndex+1}`}>
                    <span>Thema {themaIndex + 1}</span>
                </Link>
                <ul className={`absolute left-44 bg-black normal-case invisible h-0
                    group-${themaIndex}-hover:h-10 group-${themaIndex}-hover:visible
                    transition-height w-80 z-10 font-light`}>
                    {links}
                </ul>
            </li>
        )
        });
        return (
            <div className='group relative h-20 grid place-content-center'>
                <Link to={`/content/${kapitel}/1`} className='w-full'>
                    <div className='h-20 grid place-content-center w-44 hover:bg-stone-700'>
                    ← Kapitel {kapitel}
                    </div>
                </Link>
                <ul className="absolute top-20 bg-black normal-case
                    h-0 group-hover:h-full w-44 z-10 font-light text-sm leading-none">
                    {menuContent}
                </ul>
            </div>
          )
    }
}

export default VocabMenu;
