import * as React from 'react';
import Modal, { Styles } from 'react-modal';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { colorSubject } from '../../store/EnvironmentStore';

import Button from '../Core/Button';

interface Props {
    show: boolean;
    close: () => void;
    title: string;
    submitText?: string;
    onSubmit?: () => void;
    dismissText?: string;
    style?: Partial<Styles>;
}

const DDDModal: React.FunctionComponent<Props> = (props) => {
    const color = useBehaviorSubject(colorSubject);
    const { show, title, children, dismissText, submitText, onSubmit, close, style} = props;
    const stylesToApply = { ...baseModalStyles, style };

    return (
        <Modal isOpen={show} style={stylesToApply}>
            <h4>{title}</h4>
            <div className='border-b border-slate-300 my-4'/>
            <div className='font-serif'>
                {children}
            </div>
            <div className='border-b border-slate-300 my-4'/>
            <div className='mt-4'>
                <Button _key={'modal_button_dismiss'} className='mr-5' color={color} onClick={close}>
                    {dismissText ?? 'Cancel'}
                </Button>
                {  submitText && onSubmit &&
                    <Button _key={'modal_button_submit'} color={color} onClick={onSubmit}>
                        {submitText}
                    </Button>
                }
            </div>
        </Modal>
    )
}

const baseModalStyles: Styles = {
    content: {
        width: '530px',
        margin: 'auto',
        padding: '40px',
        zIndex: 1000,
        position: 'relative',
    },
    overlay: {
        zIndex: 200
    }
};

export default DDDModal;

