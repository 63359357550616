import {createBrowserHistory, Location} from "history"
import {BehaviorSubject} from 'rxjs';

const history = createBrowserHistory({basename: '/'});
export const locationSubject = new BehaviorSubject<Location>(history.location);

history.listen(location => {
    locationSubject.next(location);
});

export default history;