import React from 'react';
import { Link } from 'react-router-dom';
import { assetsUri } from '../../constants/AppConstants';

interface LinkProps {
    readonly kapitel: number,
    readonly title: string,
    readonly widthClass: string,
}

const KapitelLink = (props: LinkProps) => {
    const { kapitel, title, widthClass } = props;
    return (
        <Link className={widthClass} to={`/content/${kapitel}/1`}>
            <div className='relative text-white bg-thema1 w-full h-72'>
                <img
                    className='hover:opacity-20 w-full h-full transition ease-in-out duration-400'
                    src={`${assetsUri}/images/general/k${kapitel}.jpg`}
                    alt={`Kapitel ${kapitel}`} />
                <p className='font-serif absolute bottom-14 left-5'>Kapitel {kapitel}</p>
                <h3 className='font-open text-2xl absolute bottom-3 left-5'>{title}</h3>
            </div>
        </Link>
    );
}

export default KapitelLink;
