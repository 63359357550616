import { CustomErrorType } from "./AppConstants";

export class JsonError extends Error {
    body: any;

    constructor(body: any) {
        super(`JSON error: ${JSON.stringify(body)}`);

        this.body = body;
    }
}

export interface DDDError {
    exceptionTypeId: CustomErrorType;
    message?: string;
}