import { BehaviorSubject, combineLatest } from 'rxjs';
import { kapitelSubject, themaSubject } from './EnvironmentStore';
import { Slide, currentContentSlidesSubject, slidesSubject } from './SlideStore';
import { map } from 'rxjs/operators';
import { User, studentsSubject } from './UserStore';
import { userInstitutionIdSubject, isAdminSubject } from './AuthStore';
import { Section, sectionsSubject } from './SectionStore';
import { getObjectValues } from '../utilities';
import { Response, repsonsesSubject } from './ResponseStore';

export const slideTypeIdSubject = new BehaviorSubject<number | null>(null);
export const sectionIdSubject = new BehaviorSubject<number | null>(null);
export const labelSubject = new BehaviorSubject<string | null>(null);
export const studentIdSubject = new BehaviorSubject<number | null>(null);
export const currentResponsesSubject = new BehaviorSubject<Response[]>([]);

export const setCurrentSlideType = (slideType: number | null) => {
    slideTypeIdSubject.next(slideType);
    labelSubject.next(null);
}
export const setCurrentResponseLabel = (label: string | null) => labelSubject.next(label)
export const setCurrentResponseSectionId = (sectionId: number| null) =>  {
    sectionIdSubject.next(sectionId);
    studentIdSubject.next(null);
    currentResponsesSubject.next([]);
}
export const setCurrentResponseStudentId = (studentId: number| null) => {
    studentIdSubject.next(studentId);
    if (studentId === null) {
        currentResponsesSubject.next([]);
    }
}

combineLatest([kapitelSubject, themaSubject])
    .subscribe(() => {
        setCurrentSlideType(null);
        setCurrentResponseLabel(null);
        currentResponsesSubject.next([]);
    });

export const currentResponseLabelsSelector = (slides: Slide[], slideTypeId: number | null) => Array.from(new Set(slides
    .filter(x => x.slideTypeId === slideTypeId)
    .map(x => x.label)));

export const currentResponseLabelsSubject = new BehaviorSubject<string[]>([]);

combineLatest(currentContentSlidesSubject, slideTypeIdSubject)
    .pipe(map(([slides, slideTypeId]) => currentResponseLabelsSelector(slides, slideTypeId)))
    .subscribe(labels => {
        currentResponseLabelsSubject.next(labels);
    });

const currentResponseCourseSectionSubject = new BehaviorSubject<Section | null>(null);

combineLatest(sectionsSubject, sectionIdSubject)
    .pipe(map(([sections, sectionId]) => getObjectValues(sections).find(x => x.id === sectionId) || null))
    .subscribe(section => currentResponseCourseSectionSubject.next(section));

export const currentResponseStudentsSubject = new BehaviorSubject<User[]>([]);

const currentResponseStudentsSelector = (institutionId: number | null, isAdmin: boolean, students: User[], courseSection: Section | null) => 
    students.filter(s => {
        if (!isAdmin && (institutionId !== s.institutionId)) {
            return false;
        }
        
        if (courseSection) {
            return courseSection.studentIds.indexOf(s.id) > -1;
        }
        
        return true;
    });

combineLatest(userInstitutionIdSubject, isAdminSubject, studentsSubject, currentResponseCourseSectionSubject)
    .pipe(map(([institutionId, isAdmin, students, courseSection]) => currentResponseStudentsSelector(institutionId, isAdmin, students, courseSection)))
    .subscribe(students => currentResponseStudentsSubject.next(students));

const currentResponsesSelector = (
    responses: Response[],
    kapitel: number,
    thema: number,
    students: User[],
    slides: {[id: number]: Slide},
    studentId: number | null,
    slideTypeId: number | null,
    label: string | null): Response[] => 
    responses
        // first filter down to responses based on 'easy to get' metadata
        .filter(x => 
            x.kapitel === kapitel
            && x.thema === thema
            && (x.userId === studentId || !studentId)
            && students.map(x => x.id).indexOf(x.userId) > -1
        )
        // then obtain the actual slide corresponding to the response
        .map(x => ({...x, slide: slides[x.slideId]}))
        // finally, apply final filtering by slide
        .filter(x =>
            (!slideTypeId || (x.slide && x.slide.slideTypeId === slideTypeId))
            && (!label || (x.slide && x.slide.label === label))
        )
        .map(r => ({
            response: r,
            user: students.find(s => r.userId === s.id)
        }))
        .sort((a, b) => {
            if (!a.user || !b.user)
            {
                return 0;
            }
            return a.user.lastName.localeCompare(b.user.lastName)
        })
        .map(x => x.response);

combineLatest(repsonsesSubject, kapitelSubject, themaSubject, currentResponseStudentsSubject, slidesSubject, studentIdSubject, slideTypeIdSubject, labelSubject)
    // @ts-ignore
    .pipe(map(([responses, kapitel, thema, students, slides, studentId, slideTypeId, label]) =>
        currentResponsesSelector(getObjectValues(responses), kapitel, thema, students, slides, studentId, slideTypeId, label)))
    // @ts-ignore
    .subscribe((responses: Response[]) => currentResponsesSubject.next(responses));