import React from 'react';

interface Props {
    src: string;
    children: any;
    style: any;
}

const AudioFile = (props: Props) => {
    const audio = new Audio(props.src);

    const play = () => {
        audio.play();
    }

    return <span style={props.style} onClick={play}>{props.children}</span>
}

export default AudioFile;