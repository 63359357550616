import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface AudioTimelineProps {
    readonly handleTimelineClick: Function;
    readonly progress: string;
    readonly loaded: string;
}

class AudioTimeline extends React.PureComponent<AudioTimelineProps> {
    constructor(props: AudioTimelineProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent<HTMLDivElement>) {
        const domNode = ReactDOM.findDOMNode(this);
        if (domNode)
        {
            const el = (domNode as Element);
            const rect = el.getBoundingClientRect();
            const left = rect.left + document.body.scrollLeft;
            const percentage = (event.pageX - left) / el.clientWidth;
            this.props.handleTimelineClick(percentage);
        }
    }

    render() {
        return (
            <div onClick={(ev) => this.handleClick(ev)}>
                <div className="progress" style={{width: this.props.progress}}></div>
                <div className="loaded" style={{width: this.props.loaded}}></div>
            </div>
        )
    }
}

export default AudioTimeline;
