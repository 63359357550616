import React, { useEffect, useState } from 'react';

import FullLayout from '../Layout/FullLayout';
import Loader from '../Core/Loader';
import SubmitStatus from '../Core/SubmitStatus';
import Button from '../Core/Button';
import SectionDeleteModal from './SectionDeleteModal';

import { Option } from '../Core/OptionTypes';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { activeSectionSubject, sectionIdSelector, setInstructorIds, setStudentIds, setInstitutionId, setName } from '../../store/SectionEdit';
import { requestSections, createOrUpdateSection, deleteSection, sectionRequestStatusSubject, createorUpdateSectionStatusSubject } from '../../store/SectionStore';
import { requestUsers, instructorsSubject, studentsSubject } from '../../store/UserStore';
import { locationSubject } from '../../history';
import { isAdminSubject, userInstitutionIdSubject, isCourseInstructorSubject } from '../../store/AuthStore';
import { institutionsSubject } from '../../store/InstitutionStore';
import { colorSubject } from '../../store/EnvironmentStore';
import ThemedReactSelect from '../Core/ThemedReactSelect';
import Tippy from '@tippyjs/react';

const SectionEditPage: React.SFC = () => {
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const activeSection = useBehaviorSubject(activeSectionSubject);
    const sectionId = sectionIdSelector(useBehaviorSubject(locationSubject));
    const isAdmin = useBehaviorSubject(isAdminSubject);
    const instructors = useBehaviorSubject(instructorsSubject);
    const students = useBehaviorSubject(studentsSubject);
    const institutions = useBehaviorSubject(institutionsSubject);
    const sectionRequestStatus = useBehaviorSubject(sectionRequestStatusSubject);
    const sectionUpdateStatus = useBehaviorSubject(createorUpdateSectionStatusSubject);
    const userInstitutionId = useBehaviorSubject(userInstitutionIdSubject);
    const color = useBehaviorSubject(colorSubject);
    const isCourseInstructor = useBehaviorSubject(isCourseInstructorSubject);

    const shouldDisableEdit = !isAdmin && !isCourseInstructor;

    useEffect(() => {
        requestSections();
        requestUsers(null);
    }, []);

    const name = (activeSection && activeSection.name) || '';
    const instructorIds = (activeSection && activeSection.instructorIds) || [];
    const studentIds = (activeSection && activeSection.studentIds) || [];
    const institutionId = (activeSection && activeSection.institutionId) || null;

    const handleUpdate = () => {
        if (!name || !institutionId || !instructorIds || !studentIds) {
            return null;
        }

        createOrUpdateSection({
            id: sectionId || 0,
            name,
            instructorIds,
            studentIds,
            institutionId,
        });
    }

    const handleStudentUpdate = (option: Option<number>[]) => {
        if (option === null) {
            setStudentIds([]);
        }

        else {
            setStudentIds(option.map(x => x.value));
        }
    }

    const handleInstructorUpdate = (option: Option<number>[]) => {
        if (option === null) {
            setInstructorIds([]);
        }

        else {
            setInstructorIds(option.map(x => x.value));
        }
    }

    const renderUpdateButton = () => {
        if (!name || !institutionId || !instructorIds || !studentIds) {
            return <div/>
        }
        const buttonText = sectionId ? "Update" : "Add";
        return <Button _key={'section_edit_button'} color={color} onClick={handleUpdate}>{buttonText}</Button>;
    }

    const renderDeleteButton = () => {
        if (!sectionId) {
            return null;
        }

        return <Button _key={'section_delete_button'} style={{marginLeft: 20}} color={color} onClick={() => setShowDeletionModal(true)}>Delete</Button>;
    }

    const unauthorizedTooltipWrapper = (component: any) => {
        if (shouldDisableEdit) {
            return <Tippy content={'You do not have permission to create or edit sections. Please contact a course or site administrator if you wish to make changes to section enrollment.'} duration={100} hideOnClick={false} >
                <span>{component}</span>
            </Tippy>
        }

        return component;
    }

    let instructorOptions = instructors.map(i => ({ value: i.id, label: `${i.firstName} ${i.lastName}`}));
    let studentOptions = students.map(i => ({ value: i.id, label: `${i.firstName} ${i.lastName}`}));
    let institutionOptions = institutions
        .filter(i => isAdmin || i.id === userInstitutionId)
        .map(i => ({ value: i.id, label: i.name }));
    const institutionOption = institutionOptions.find(x => x.value === institutionId);
    const selectedStudents = studentOptions.filter(x => studentIds.indexOf(x.value) > -1);
    const selectedInstructors = instructorOptions.filter(x => instructorIds.indexOf(x.value) > -1);

    return (
        <FullLayout>
            <Loader isLoading={sectionRequestStatus === 'PROCESSING'}/>
            <div className='grid place-content-center'>
            <div className='w-content bg-white p-10 my-10 font-serif'>
            <div>
                <div className='my-2'>
                    <label className='font-bold'>Name</label>
                    <div>
                        {unauthorizedTooltipWrapper(<input disabled={shouldDisableEdit} type="text" name="name" value={name} onChange={(ev) => setName(ev.target.value) }/>)}
                    </div>
                </div>
                <div className='my-2'>
                    <label className='font-bold'>Institution</label>
                    <div style={{width: 200}}>
                        {unauthorizedTooltipWrapper(<ThemedReactSelect name="institutionId"
                            isDisabled={shouldDisableEdit}
                            color={color}
                            options={institutionOptions}
                            value={institutionOption}
                            onChange={(option: any) => setInstitutionId(option.value)}/>)}
                    </div>
                </div>
                <div className='my-2'>
                    <label className='font-bold'>Instructors</label>
                    <div style={{width: 800}}>
                        {unauthorizedTooltipWrapper(<ThemedReactSelect style={{width: 800}}
                            isDisabled={shouldDisableEdit}
                            color={color}
                            isMulti={true}
                            name="instructors"
                            options={instructorOptions}
                            value={selectedInstructors}
                            onChange={(option: any) => handleInstructorUpdate(option)}/>)}
                    </div>
                </div>
                <div className='my-2'>
                    <label className='font-bold'>Students</label>
                    <div style={{width: 800}}>
                        {unauthorizedTooltipWrapper(<ThemedReactSelect style={{width: 800}}
                            isDisabled={shouldDisableEdit}
                            color={color}
                            isMulti={true}
                            name="students"
                            options={studentOptions}
                            value={selectedStudents}
                            onChange={(option: any) => handleStudentUpdate(option)}/>)}
                    </div>
                </div>
                {
                    shouldDisableEdit
                        ? null
                        : <div>
                            {renderUpdateButton()}
                            {renderDeleteButton()}
                            <SubmitStatus color={color} status={sectionUpdateStatus}/>
                        </div>
                }
            </div>
            {
                sectionId && (
                    <SectionDeleteModal
                        sectionId={sectionId}
                        name={name}
                        close={() => setShowDeletionModal(false)}
                        show={showDeletionModal}
                        deleteSection={deleteSection}
                        isAdmin={isAdmin}
                        color={color}
                        />
                )
            }
            </div>
            </div>
        </FullLayout>
    );
}

export default SectionEditPage;
