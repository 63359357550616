import React from 'react';
import { CognitoUser } from '../../store/UserStore';
import CognitoUserActions from './CognitoUserActions';

interface Props {
    readonly cognitoUser: CognitoUser,
    readonly email: string,
    readonly userId: number,
}

const CognitoUserComponent: React.FC<Props> = (props: Props) => {
    const { cognitoUser, email, userId } = props;
    const emailVerified = cognitoUser.attributes.find(a => a.name === 'email_verified');
    const dateOptions: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const userCreateDate = new Date(cognitoUser.userCreateDate)
    const userLastModifiedDate = new Date(cognitoUser.userLastModifiedDate)
    return (
        <>
            <Summary cognitoUser={cognitoUser} email={email} userId={userId} />
            <h4 className='mb-2'>Raw info</h4>
            <div className='p-6 border-2 border-stone-200'>
                <div>
                    <strong>User ID:</strong> {cognitoUser.username}
                </div>
                <div>
                    <strong>Enabled:</strong>{cognitoUser.enabled ? 'True' : 'False'}
                </div>
                <div>
                    <strong>Status:</strong> {cognitoUser.userStatus.value}
                </div>
                <div>
                    <strong>Email verified:</strong> {(emailVerified && emailVerified.value) || 'Unknown'}
                </div>
                <div>
                    <strong>Create date:</strong> {userCreateDate.toLocaleDateString('en-US', dateOptions)}
                </div>
                <div>
                    <strong>Last modified date:</strong> {userLastModifiedDate.toLocaleDateString('en-US', dateOptions)}
                </div>
            </div>
            <br />
            <CognitoUserActions cognitoUser={cognitoUser} email={email} userId={userId} />
        </>
    )
}

const Summary: React.FC<Props> = (props: Props) => {
    const {cognitoUser} = props;
    const {userStatus, attributes, enabled} = cognitoUser;
    const emailVerified = attributes.find(a => a.name === 'email_verified');
    if ((!enabled || (enabled as unknown) === "False")) {
        return (
            <div className="alert alert-warning" role="alert">
                User is NOT enabled and will be prevented from logging in. Click 'ENABLE' below to enable their access.
            </div>
        )
    }
    if (userStatus.value === 'UNCONFIRMED') {
        return (
            <div className="alert alert-warning" role="alert">
                User is has been created but not confirmed. Check that they have received and clicked on the confirmation email.
            </div>
        )
    }
    if (userStatus.value === 'ARCHIVED') {
        return (
            <div className="alert alert-warning" role="alert">
                User is no longer active and has been archived.
            </div>
        )
    }
    if (userStatus.value === 'RESET_REQUIRED') {
        return (
            <div className="alert alert-warning" role="alert">
                User is confirmed, but must reset their password before they can sign in.
            </div>
        )
    }
    if (userStatus.value === 'FORCE_CHANGE_PASSWORD') {
        return (
            <div className="alert alert-warning" role="alert">
                User is confirmed, but on next sign-in, must change their password.
            </div>
        )
    }
    if (userStatus.value !== 'EXTERNAL_PROVIDER' && emailVerified?.value === 'false') {
        return (
            <div className="alert alert-warning" role="alert">
                User is confirmed, but their email address is not verified. Actions involving email, such as password resets, may not complete.
            </div>
        )
    }
    if (userStatus.value === 'CONFIRMED' || userStatus.value === 'EXTERNAL_PROVIDER') {
        return (
            <div className="alert alert-success" role="alert">
                User is confirmed and should be able to successfully access the site.
            </div>
        )
    }

    return <div/>
}

export default CognitoUserComponent
