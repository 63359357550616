import { getJson, del, postJson, putJson } from '../utilities/Requests';
import { jwtSubject, UserData, userDataSubject } from './AuthStore';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { addArrayToBehaviorMap, addSingleToBehaviorMap } from '../utilities/SubjectHelpers';
import { kapitelSubject, themaSubject } from './EnvironmentStore';
import { map } from 'rxjs/operators';
import { getObjectValues } from '../utilities';
import { apiCall } from '../utilities/RequestHelpers';
import produce from 'immer';

export interface SlideOrder {
    readonly id: number;
    readonly dateModified: Date | undefined;
    readonly dateCreated: Date | undefined;
    readonly institutionId: number;
    readonly kapitel: number;
    readonly slideTypeId: number;
    readonly slideIds: number[];
    readonly thema: number;
}

export interface Slide {
    readonly kapitel: number;
    readonly thema: number;
    readonly label: string;
    readonly content: string;
    readonly slideTypeId: number;
    readonly dateModified: Date | undefined;
    readonly dateCreated : Date | undefined;
    readonly version?: string;
    readonly id: number;
}

export const slidesSubject = new BehaviorSubject<{[id: number]: Slide}>({});
export const slideRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const slideUpdateStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const slideOrdersSubject = new BehaviorSubject<{[id: number]: SlideOrder}>({});
export const slideOrderRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const currentContentSlidesSubject = new BehaviorSubject<Slide[]>([]);
export const currentContentSlideOrdersSubject = new BehaviorSubject<SlideOrder[]>([]);
export const currentContentSlidesBySlideOrderSubject = new BehaviorSubject<Slide[]>([]);

const currentContentSlidesSelector = (kapitel: number, thema: number, slides: {[id: number]: Slide}) => 
    getObjectValues(slides).filter(
        slide => slide.kapitel === kapitel
        && slide.thema === thema
    );

const currentContentSlideOrdersSelector = (kapitel: number, thema: number, slideOrders: {[id: number]: SlideOrder}, userData: UserData | null) => 
    getObjectValues(slideOrders).filter(
        so => so.kapitel === kapitel
        && so.thema === thema
        && (!userData || so.institutionId === userData.institutionId)
    );

const currentContentSlidesBySlideOrderSelector = (slides: Slide[], slideOrders: SlideOrder[]) =>
    slides.filter(
        s => slideOrders.some(so => so.slideIds.indexOf(s.id) > -1)
    )
    .sort((a, b) => {
        if (a.slideTypeId !== b.slideTypeId) {
            return a.slideTypeId - b.slideTypeId;
        }
        const slideOrderWithA = slideOrders.filter(x => x.slideIds.indexOf(a.id) > -1)[0];
        const slideOrderWithB = slideOrders.filter(x => x.slideIds.indexOf(b.id) > -1)[0];
        if (slideOrderWithA.id !== slideOrderWithB.id) {
            return slideOrderWithA.id - slideOrderWithB.id;
        }

        return slideOrderWithA.slideIds.indexOf(a.id) - slideOrderWithA.slideIds.indexOf(b.id);
    });

combineLatest(kapitelSubject, themaSubject, slidesSubject)
    .pipe(map(([kapitel, thema, slides]) => currentContentSlidesSelector(kapitel, thema, slides)))
    .subscribe(slides => {
        // TODO: check equality here
        if (slides !== currentContentSlidesSubject.value) {
            currentContentSlidesSubject.next(slides);
        }
    });

combineLatest(kapitelSubject, themaSubject, slideOrdersSubject, userDataSubject)
    .pipe(map(([kapitel, thema, slideOrders, userData]) => currentContentSlideOrdersSelector(kapitel, thema, slideOrders, userData)))
    .subscribe(slideOrders => {
        // TODO: check equality here
        if (slideOrders !== currentContentSlideOrdersSubject.value) {
            currentContentSlideOrdersSubject.next(slideOrders);
        }
    });

combineLatest(currentContentSlidesSubject, currentContentSlideOrdersSubject)
    .pipe(map(([slides, slideOrders]) => currentContentSlidesBySlideOrderSelector(slides, slideOrders)))
    .subscribe(slides => currentContentSlidesBySlideOrderSubject.next(slides));

export const requestSlides = async (kapitel: number, thema: number, includeAllInstitutions: boolean = false) => {
    await apiCall(async () => {
        const slides = await getJson<Slide[]>({
            url: `${apiUri}/slides/${kapitel}/${thema}?includeAllInstitutions=${includeAllInstitutions}`,
        }, jwtSubject.value);

        addArrayToBehaviorMap(slidesSubject, slides);
    }, slideRequestStatusSubject);
};

export const requestSlideOrders = async (kapitel: number, thema: number) => {
    await apiCall(async () => {
        const slideOrders = await getJson<SlideOrder[]>({
            url: `${apiUri}/slideOrders/${kapitel}/${thema}`,
        }, jwtSubject.value);

        addArrayToBehaviorMap(slideOrdersSubject, slideOrders);
    }, slideOrderRequestStatusSubject);
};

export const updateSlide = async (slide: Slide) => {
    await apiCall(async () => {
        const updatedSlide = await putJson<Slide>({
            url: `${apiUri}/slides`,
            data: JSON.stringify(slide),
        }, jwtSubject.value);

        addSingleToBehaviorMap(slidesSubject, updatedSlide);
    }, slideUpdateStatusSubject);
};

export const publishSlide = (slide: Slide, slideOrders: SlideOrder[], institutionIds: number[]) => {
    const currentEditSection = slide.slideTypeId;
    const currentEditSlideOrders = slideOrders
        .filter(x => x.slideTypeId === currentEditSection);
    const allSlides = slidesSubject.value;

    const slideOrdersToUpdate = currentEditSlideOrders
        .filter(so => institutionIds.indexOf(so.institutionId) > -1);

    const getIndexToUpdate = () => {
        if (!slide || !slideOrders) {
            return null;
        }

        const slideOrder = slideOrders[0];
        const slideOrderSlides = slideOrder.slideIds.map(x => allSlides[x]);
        return slideOrderSlides.findIndex(x => x.label === slide.label);
    }

    const indexToUpdate = getIndexToUpdate();

    if (slideOrdersToUpdate.length === 0 || indexToUpdate == null || indexToUpdate === -1 || !slide) {
        return;
    }

    const updatedSlideOrders = slideOrdersToUpdate.map(so => {
        let newSlideIds = so.slideIds.slice();
        newSlideIds[indexToUpdate] = slide.id;
        return {
            ...so,
            slideIds: newSlideIds
        };
    });

    updatedSlideOrders.forEach(async so => {
        const updatedSlideOrder = await putJson<SlideOrder>({
            url: `${apiUri}/slideOrders`,
            data: JSON.stringify(so),
        }, jwtSubject.value);

        addSingleToBehaviorMap(slideOrdersSubject, updatedSlideOrder);
    });
};

export const copySlide = async (slide: Slide) => {
    apiCall(async () => {
        const newSlide = await postJson<Slide>({
            url: `${apiUri}/slides`,
            data: JSON.stringify(slide)
        }, jwtSubject.value);

        addSingleToBehaviorMap(slidesSubject, newSlide);
    }, slideUpdateStatusSubject);
};

export const deleteSlide = (id: number) => {
    apiCall(async () => {
        await del({
            url: `${apiUri}/slides/${id}`,
        }, jwtSubject.value);

        slidesSubject.next(produce(slidesSubject.value, draft => {
            delete draft[id];
            return draft;
        }));
    }, slideUpdateStatusSubject);
}
