import { BehaviorSubject } from 'rxjs';
import { getJson, postJson } from '../utilities/Requests';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { apiCall } from '../utilities/RequestHelpers';
import { jwtSubject } from './AuthStore';
import { userDataSubject } from './AuthStore';
import { showInfoModal } from './ErrorStore';

export interface Version {
    roleId: number;
    version: string;
}

export const versionRequestStatus = new BehaviorSubject<RequestStatus>(null);
export const versionsSubject = new BehaviorSubject<Version[]>([]);
export const showVersionUpdateModalSubject = new BehaviorSubject<boolean>(false);

export const showVersionUpdateModal = () => showVersionUpdateModalSubject.next(true);
export const hideVersionUpdateModal = () => showVersionUpdateModalSubject.next(false);

const triggerVersionModalShowIfNecessary = (oldVersions: Version[], newVersions: Version[]) => {
    if (userDataSubject.value === null) {
        return false;
    }

    const roleIds = userDataSubject.value.roleIds;
    const oldFilteredVersions = oldVersions.filter(x => roleIds.indexOf(x.roleId) > -1);
    const newFilteredVersions = newVersions.filter(x => roleIds.indexOf(x.roleId) > -1);

    oldFilteredVersions.forEach(v => {
        const newVersion = newFilteredVersions.find(x => x.roleId === v.roleId);
        if (newVersion && newVersion.version !== v.version) {
            showInfoModal('dddSiteRefreshModalShown', "A new version of 'ddd' is available! Please refresh your browser window to enjoy the latest updates.", 'Site version update');
        }
    });
}

setInterval(() => {
    requestVersions();
}, 1000 * 60 * 5 /* 5 min */);

export const requestVersions = async () => {
    await apiCall(async () => {
        const nextData = await getJson<Version[]>({ url: `${apiUri}/frontendversions` });
        if (versionsSubject.value != null) {
            triggerVersionModalShowIfNecessary(versionsSubject.value, nextData);
        }
        versionsSubject.next(nextData);
    }, versionRequestStatus);
}

export const updateVersions = async (roles: number[]) => {
    await apiCall(async () => {
        const nextData = await postJson<Version[]>({ url: `${apiUri}/frontendversions`, data: JSON.stringify(roles) }, jwtSubject.value);
        versionsSubject.next(nextData);
    }, versionRequestStatus);
}