import React from 'react';
import { Link } from 'react-router-dom';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { themaSubject, bgThemaSubject } from '../../store/EnvironmentStore';

interface Props {
  kapitel: number;
  thema: number;
}

const ThemaLink: React.FunctionComponent<Props> = (props: Props) => {
  const currentThema = useBehaviorSubject(themaSubject);
  const bgThema = useBehaviorSubject(bgThemaSubject);

  const isActive = currentThema === props.thema;
  const activeBackground = () => isActive ? bgThema : 'bg-transparent';
  return (
    <Link to={`/content/${props.kapitel}/${props.thema}`}>
      <div className={`p-2.5 w-10 text-white font-sans leading-9 font-light text-lg
        flex-none ${activeBackground()} hover:bg-thema${props.thema} hover:text-white
        transition ease-in-out duration-400
        `}>
        {props.thema}
      </div>
    </Link>
  );
};

export default ThemaLink;

