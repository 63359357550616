import { Location } from 'history';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { sectionsSubject, Section } from './SectionStore';
import { locationSubject } from '../history';

const emptySection: Section = {
    id: 0,
    name: '',
    instructorIds: [],
    studentIds: [],
    institutionId: 1,
};

const isSectionEditRouteSelector = (location: Location | null) => location && /sections\/\d+/i.test(location.pathname);
export const sectionIdSelector = (location: Location | null) => {
    if (!isSectionEditRouteSelector(location)) {
        return null;
    }

    const match = location && location.pathname.match(/sections\/(?<sectionId>\d+)/i);

    if (match && match.groups && match.groups['sectionId']) {
        return parseInt(match.groups['sectionId'], 10);
    }

    return null;
}

export const activeSectionSubject = new BehaviorSubject<Section>(emptySection);
const activeSectionSelector = (sections: {[id: number]: Section}, location: Location | null) => {
    const sectionId = sectionIdSelector(location);
    return sectionId !== null ? sections[sectionId] : emptySection;
}
combineLatest(sectionsSubject, locationSubject)
    .pipe(map(([sections, location]) => activeSectionSelector(sections, location)))
    .subscribe(value => {
        if (value !== activeSectionSubject.value) {
            activeSectionSubject.next(value);
        }
    });

export const setName = (name: string) => { activeSectionSubject.next(activeSectionSubject.value && {...activeSectionSubject.value, name}); }
export const setInstitutionId = (institutionId: number) => { activeSectionSubject.next(activeSectionSubject.value && {...activeSectionSubject.value, institutionId}); }
export const setStudentIds = (studentIds: number[]) => { activeSectionSubject.next(activeSectionSubject.value && {...activeSectionSubject.value, studentIds}); }
export const setInstructorIds = (instructorIds: number[]) => { activeSectionSubject.next(activeSectionSubject.value && {...activeSectionSubject.value, instructorIds}); }