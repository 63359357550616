import React, { useEffect } from 'react';

import { SlideTypes } from '../../constants/AppConstants';
import {NumberOption, StringOption} from '../Core/OptionTypes';
import { setKapitel, setThema, kapitelSubject, themaSubject, colorSubject } from '../../store/EnvironmentStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { requestSlides, requestSlideOrders } from '../../store/SlideStore';
import { setCurrentResponseSectionId, setCurrentResponseStudentId, setCurrentSlideType, setCurrentResponseLabel, currentResponseLabelsSubject, currentResponseStudentsSubject, studentIdSubject, sectionIdSubject, slideTypeIdSubject, labelSubject } from '../../store/ResponseFilteringState';
import { sectionsSubject } from '../../store/SectionStore';
import { kapitelsSubject } from '../../store/KapitelStore';
import { themasSubject } from '../../store/ThemaStore';
import { getObjectValues } from '../../utilities';
import ThemedReactSelect from '../Core/ThemedReactSelect';

const ResponseFilterOptions = () => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const labels = useBehaviorSubject(currentResponseLabelsSubject);
    const sections = getObjectValues(useBehaviorSubject(sectionsSubject));
    const kapitels = useBehaviorSubject(kapitelsSubject);
    const themas = useBehaviorSubject(themasSubject);
    const students = useBehaviorSubject(currentResponseStudentsSubject);
    const studentId = useBehaviorSubject(studentIdSubject);
    const courseSectionId = useBehaviorSubject(sectionIdSubject);
    const slideTypeId = useBehaviorSubject(slideTypeIdSubject);
    const label = useBehaviorSubject(labelSubject);
    const color = useBehaviorSubject(colorSubject);

    useEffect(() => {
        requestSlides(kapitel, thema);
        requestSlideOrders(kapitel, thema);
    }, [kapitel, thema]);

    const handleCourseSectionChange = (option: NumberOption) => {
        if (option && option.value) {
            setCurrentResponseSectionId(option.value);
        }
        else {
            setCurrentResponseSectionId(null);
        }
    }

    const handleStudentChange = (option: NumberOption) => {
        if (option && option.value) {
            setCurrentResponseStudentId(option.value);
        }
        else {
            setCurrentResponseStudentId(null);
        }
    }

    const handleKapitelChange = (option: NumberOption) => {
        if (option && option.value) {
            setKapitel(option.value);
        } 
    }

    const handleThemaChange = (option: NumberOption) => {
        if (option && option.value) {
            setThema(option.value);
        }
    }

    const handleTypeChange = (option: NumberOption) => {
        if (option && option.value) {
            setCurrentSlideType(option.value);
        }
        else {
            setCurrentSlideType(null);
        }
    }

    const handleSlideChange = (option: StringOption) => {
        if (option && option.value) {
            setCurrentResponseLabel(option.value);
        }
        else {

            setCurrentResponseLabel(null);
        }
    }

    const studentOptions = students
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map(s => ({ value: s.id, label: `${s.firstName} ${s.lastName}`}));
    const studentOption = studentOptions.find(x => x.value === studentId) || null;

    const sectionOptions = sections
        .map(s => ({ value: s.id, label: s.name }))
        .sort((a, b) => a.label.localeCompare(b.label));
    const courseSectionOption = sectionOptions.find(x => x.value === courseSectionId);

    const kapitelOptions = kapitels
        .map(k => {
            return { value: k.kapitel, label: `${k.kapitel} - ${k.name}` };
        })
        .sort((a, b) => a.value - b.value);
    const kapitelOption = kapitelOptions.find(x => x.value === kapitel);

    const themaOptions = themas
        .filter(t => t.kapitel === kapitel)
        .sort((t1, t2) => t1.thema - t2.thema)
        .map(t => {
            return { value: t.thema, label: `T${t.thema} - ${t.name}`};
        });
    const themaOption = themaOptions.find(x => x.value === thema);

    const slideTypeOptions = [
        { value: SlideTypes.Interaktionen1, label: 'Interaktionen 1'},
        { value: SlideTypes.Strukturen, label: 'Strukturen'},
        { value: SlideTypes.Interactionen2, label: 'Interaktionen 2'},
    ];
    const slideTypeOption = slideTypeOptions.find(x => x.value === slideTypeId);

    const labelOptions = labels.map(x => ({ value: x, label: x}));
    const labelOption = labelOptions.find(x => x.value === label);

    return (
        <div>
            <p>Select a Kapitel and Thema below, as well as other optional filters, then generate a report of student responses matching the filters.</p>
            <div className="clearfix">
                <div style={{...selectDivStyle, width: 150}}>
                    <p><label>Kapitel</label></p>
                    <span style={{...selectSpanStyle, width: 150}}>
                        <ThemedReactSelect placeholder="Alle"
                            color={color}
                            isClearable={false}
                            value={kapitelOption}
                            options={kapitelOptions}
                            onChange={(x: any) => handleKapitelChange(x)}/>
                    </span>
                </div>
                <div style={{...selectDivStyle, width: 150}}>
                    <p><label>Thema</label></p>
                    <span style={{...selectSpanStyle, width: 150}}>
                        <ThemedReactSelect
                            color={color}
                            placeholder="Alle"
                            isClearable={false}
                            value={themaOption}
                            options={themaOptions}
                            valueRenderer={(option: any) => <span dangerouslySetInnerHTML={{__html: option.label}}/>}
                            optionRenderer={(option: any) => <span dangerouslySetInnerHTML={{__html: option.label}}/>}
                            onChange={(option: any) => handleThemaChange(option)}/>
                    </span>
                </div>
            </div>
            <div className="clearfix">
                <div style={{...selectDivStyle, width: 200}}>
                    <p><label>Kurs</label></p>
                    <span style={{...selectSpanStyle, width: 200}}>
                        <ThemedReactSelect
                            color={color}
                            placeholder="Alle"
                            isClearable={true}
                            value={courseSectionOption}
                            options={sectionOptions}
                            onChange={(x: any) => handleCourseSectionChange(x)}/>
                    </span>
                </div>
                <div style={{...selectDivStyle, width: 200}}>
                    <p><label>Student</label></p>
                    <span style={{...selectSpanStyle, width: 200}}>
                        <ThemedReactSelect placeholder="Alle"
                            color={color}
                            isClearable={true}
                            value={studentOption}
                            options={studentOptions}
                            onChange={(x: any) => handleStudentChange(x)}/>
                        </span>
                </div>
                <div style={{...selectDivStyle, width: 150}}>
                    <p><label>Unterthema</label></p>
                    <span style={{...selectSpanStyle, width: 150}}>
                        <ThemedReactSelect placeholder="Alle"
                            color={color}
                            isClearable={true}
                            value={slideTypeOption}
                            options={slideTypeOptions}
                            onChange={(x: any) => handleTypeChange(x)}/>
                    </span>
                </div>
                <div style={{...selectDivStyle, width: 150}}>
                    <p><label>Aufgabe</label></p>
                    <span style={{...selectSpanStyle, width: 150}}>
                        <ThemedReactSelect placeholder="Alle"
                            isClearable={true}
                            value={labelOption}
                            options={labelOptions}
                            color={color}
                            onChange={(x: any) => handleSlideChange(x)}/>
                    </span>
                </div>
            </div>
        </div>
    );
}

const selectSpanStyle: React.CSSProperties = {
    display: 'inline-block',
    width: 140
};

const selectDivStyle: React.CSSProperties = {
    float: 'left',
    marginRight: 10
}

export default ResponseFilterOptions;