import * as React from 'react';
import { RequestStatus } from '../../constants/AppConstants';

import { withFade } from './Fader';

import SubmitStatusIcon from './SubmitStatusIcon';
import {useState, useEffect, forwardRef} from 'react';

interface SubmitStatusProps {
    status: RequestStatus | null;
    color: string;
}

const SubmitStatus = forwardRef((props: SubmitStatusProps, ref: any) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const display = props.status === "SUCCESS" ? true : false;
        setDisplay(display);
    }, [props.status]);

    setTimeout(() => {
        setDisplay(false);
    }, 2000);

    return withFade(
        <SubmitStatusIcon
            ref={ref}
            color={props.color}
            status={props.status} />,
        display);
});

export default SubmitStatus;
