import * as React from 'react';

import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { locationSubject } from '../../history';
import { kapitelSubject } from '../../store/EnvironmentStore';
import { Link } from 'react-router-dom';

const Component = () => {
    const location = useBehaviorSubject(locationSubject);
    const kapitel = useBehaviorSubject(kapitelSubject);
    if (location) {
        const pathNameSplit = location.pathname.split('/');
        if (pathNameSplit.length >= 2 && pathNameSplit[1] === 'content')
          return <Link to={`/vocab/${kapitel}`}>
              <div className='hover:bg-stone-700 w-full h-20 grid content-center pl-4'>
            Wortschatz {kapitel}
            </div>
          </Link>;
        if (pathNameSplit.length >= 2 && pathNameSplit[1] === 'vocab')
          return <></>;
    }
    return <a href='mailto:jrankin@princeton.edu'>
      <div className='hover:bg-stone-700 w-full h-20 grid content-center pl-4'>Kontakt</div>
    </a>;
}

export default Component;
