import { RequestStatus } from "../constants/AppConstants";
import { BehaviorSubject } from "rxjs";
import { handleErrorMessage } from "../store/ErrorStore";

export const apiCall = async (apiCall: () => Promise<void>, requestStatusSubject: BehaviorSubject<RequestStatus>, customErrorMessage?: string) => {
    try {
        requestStatusSubject.next('PROCESSING');
        await apiCall();
        requestStatusSubject.next('SUCCESS');

        setTimeout(() => {
            requestStatusSubject.next(null);
        }, 3000);
    }
    catch (err) {
        requestStatusSubject.next('ERROR');
        handleErrorMessage('dddGenericApiCallError', customErrorMessage || err.message, err.stackTrace, customErrorMessage !== undefined);

        setTimeout(() => {
            requestStatusSubject.next(null);
        }, 3000);
    }
}
