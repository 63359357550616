export const getThemaColor = (thema: number) => {
    switch (thema) {
        case 1:
            return "rgb(233,102,84)";
        case 2:
            return "rgb(108,154,191)";
        case 3:
            return "rgb(100,152,123)";
        case 4:
            return "#6f7397";
        default:
            throw new Error(`Unmatched thema: ${thema}`);
    }
};

export const SLIDE_WIDTH = 864;
export const SLIDER_LABEL_HEIGHT = 72;
export const MAX_SLIDE_LABELS_IN_VIEW = 8;

export const storageConstants = {
    DDD_JWT: 'DDD_JWT',
    DDD_USER_DATA: 'DDD_USER_DATA',
    DDD_NEXT_PATHNAME: 'DDD_NEXT_PATHNAME'
};

export const authConstants = {
    DOMAIN: 'auth.dddgerman.org',
};

export type RequestStatus = 'SUCCESS' | 'ERROR' | 'PROCESSING' | null;

export const themas = [1, 2, 3, 4];
export const kapitels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

export const DDD_JWT = 'DDD_JWT';
export const DDD_USER_DATA = 'DDD_USER_DATA';
export const DDD_NEXT_PATHNAME = 'DDD_NEXT_PATHNAME';
export const DDD_TOKEN_EXPIRY_UTC = 'DDD_TOKEN_EXPIRY_UTC';
export const DDD_LOGIN_STATE = 'DDD_LOGIN_STATE';

export const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ü'];

export const containerDiv = 'root';
export const containerDivId = `#${containerDiv}`

export type SectionType = 'int1' | 'strukt' | 'int2';

export enum Roles {
    Admin = 1,
    CourseInstructor = 2,
    Student = 3,
    Guest = 4,
    SectionInstructor = 5
};

export enum SlideTypes {
    Interaktionen1 = 1,
    Strukturen = 2,
    Interactionen2 = 3,
};

export enum CustomErrorType
{
    UserNotFoundInDb = 1,
    ThirdPartyTokenVerificationFailed = 2,
};

export const apiUri = import.meta.env.VITE_API_URI;
export const baseUri = window.location.origin;
export const assetsUri = 'https://s3.amazonaws.com/dddassets';
