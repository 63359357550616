import React from 'react';

import VocabSearch from '../VocabSearch';
import NavMenu from './NavMenu'
import AuthLinks from './AuthLinks';
import { bgThemaSubject } from '../../store/EnvironmentStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import SecondMenu from './SecondMenu';

const Navbar: React.FunctionComponent = () => {
    const bgThema = useBehaviorSubject(bgThemaSubject);
    return (
        <header id="thestart">
            <div className="w-full h-20 bg-black text-white grid uppercase font-sans">
                <div className="w-content place-self-center grid grid-cols-3 gap-6">
                    <div className='col-span-2 flex justify-between'>
                        <nav className="grid content-center font-bold w-44">
                            <NavMenu />
                        </nav>
                        <nav className="grid content-center font-bold w-44 pl-4">
                            <SecondMenu />
                        </nav>
                        <div className="ml-5 w-64">
                            <VocabSearch />
                        </div>
                    </div>
                    <nav className={`ml-16 w-60 ${bgThema}`}>
                        <AuthLinks />
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
