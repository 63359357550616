import React, { useEffect } from 'react';

import VocabWord from './VocabWord';
import { getObjectValues } from '../../utilities';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { borderThemaSubject, kapitelSubject, textThemaSubject, themaSubject } from '../../store/EnvironmentStore';
import { requestVocab, vocabSubject, Vocab } from '../../store/VocabStore';
import { requestThemas, themasSubject } from '../../store/ThemaStore';

const ThemaVocabPage = () => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const themas = useBehaviorSubject(themasSubject);
    const vocab = getObjectValues(useBehaviorSubject(vocabSubject))
        .filter(x => x.kapitel === kapitel && x.thema === thema);
    const textThema = useBehaviorSubject(textThemaSubject);
    const borderThema = useBehaviorSubject(borderThemaSubject);

    useEffect(() => {
        requestVocab(kapitel, thema);
    }, [kapitel, thema]);
    useEffect(() => {
        requestThemas();
    }, []);


    if (vocab.length === 0) {
        return <div/>;
    }

    const currentThema= themas != null && themas.find(t => t.kapitel === kapitel && t.thema === thema);
    const quizlet = currentThema != null && currentThema.quizletEmbedCode != null &&
        <div className='w-10/12 place-self-center'>
            <div className={`mt-4 border-double border-t-8 ${borderThema} h-8 w-full`}/>
            <h3>ÜBUNGEN</h3>
            <iframe title="quizlet" src={"https://quizlet.com/" + currentThema.quizletEmbedCode + "/flashcards/embed"} height="400" width="100%" style={{border:0}}></iframe>
        </div>


    const localVocab = vocab
        .filter(vocab => vocab.kapitel === kapitel && vocab.thema === thema)
        .sort((a: Vocab, b: Vocab) => a.word.toLowerCase().localeCompare(b.word.toLowerCase()));

    return (
        <div className={'w-full'}>
            <div className='bg-black text-white h-20 grid uppercase font-sans'>
                <div className='w-10/12 place-self-center'>
                    <h3>WORTSCHATZ</h3>
                    <h4>KAPITEL {kapitel}: THEMA {thema}</h4>
                </div>
            </div>

            <div className='grid'>
                <div className='w-10/12 place-self-center divide-y divide-stone-300 font-serif text-lg'>
                {
                    localVocab.map((word) => <div className='py-2'><VocabWord vocab={word} textClass={textThema} key={word.word}/></div>)
                }
                </div>
                {quizlet}
            </div>
        </div>
    );
}

export default ThemaVocabPage;
