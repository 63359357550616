import { BehaviorSubject, combineLatest } from 'rxjs';
import produce from "immer";
import qs from 'qs';
import axios from 'axios';

import { RequestStatus, apiUri } from "../constants/AppConstants";
import { userDataSubject, jwtSubject, UserData } from './AuthStore';
import { apiCall } from "../utilities/RequestHelpers";
import { getJson, postJson, del } from "../utilities/Requests";
import { kapitelSubject, themaSubject } from './EnvironmentStore';
import { getObjectValues } from '../utilities';
import { map } from 'rxjs/operators';
import { handleErrorMessage } from './ErrorStore';

export type ResponseStatuses = { [formId: string]: RequestStatus }

export interface ResponseState {
    readonly activeFormId: string | null;
    readonly responses: { [id: string]: Response };
    readonly areResponsesFetching: boolean;
    readonly isResponseUpdating: boolean;
    readonly responseStatuses: ResponseStatuses
}

export interface ResponseQuery {
    readonly kapitel: number;
    readonly thema: number;
    userId?: number;
    slideId?: number;
    courseSectionId?: number;
}

export interface Response {
    readonly formId: string;
    readonly kapitel: number;
    readonly thema: number;
    readonly userId: number;
    readonly formData: string;
    readonly slideId: number;
}

export const getResponseId = (response: Response) => `${response.formId}_${response.userId}`;

export const activeFormIdSubject = new BehaviorSubject<string | null>(null);
export const repsonsesSubject = new BehaviorSubject<{ [id: string]: Response }>({});
export const responseRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const repsonseUpdateStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const responseStatusesSubject = new BehaviorSubject<{[responseId: string]: RequestStatus}>({});
export const currentContentResponsesSubject = new BehaviorSubject<Response[]>([]);

export const setActiveFormId = (formId: string | null) => activeFormIdSubject.next(formId);

const currentContentResponsesSelector = (kapitel: number, thema: number, userData: UserData | null, responses: Response[]) =>
    responses.filter(x =>
        x.kapitel === kapitel
        && x.thema === thema
        && userData
        && x.userId === userData.id)

combineLatest(kapitelSubject, themaSubject, userDataSubject, repsonsesSubject)
    .pipe(map(([kapitel, thema, userData, responses]) => currentContentResponsesSelector(kapitel, thema, userData, getObjectValues(responses))))
    .subscribe(responses => currentContentResponsesSubject.next(responses));

export const requestUserResponses = async (kapitel: number, thema: number) => {
    if (!userDataSubject.value) {
        // Cannot request user responses without an id.
        return;
    }
    const userId = userDataSubject.value.id;

    await apiCall(async () => {
        const responses = await getJson<Response[]>({
            url: `${apiUri}/responses?userId=${userId}&kapitel=${kapitel}&thema=${thema}`,
        }, jwtSubject.value);

        repsonsesSubject.next(produce(repsonsesSubject.value, (draft: {[id: string]: Response}) => {
            responses.forEach((x: Response) => {
                draft[getResponseId(x)] = x;
                return draft;
            });
        }));
    }, responseRequestStatusSubject);
};

export const requestResponseCsv = async (query: ResponseQuery) => {
    const queryString = qs.stringify(query);

    await apiCall(async () => {
        const authHeader = jwtSubject.value ? { 'Authorization': `Bearer ${jwtSubject.value}` } : {};
        const headers = { ...authHeader };
        const options = { headers };
        const response = await axios.get(`${apiUri}/responses/csv?${queryString}`, options);
        {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ddd_responses.csv');
            document.body.appendChild(link);
            link.click();
        }

    }, responseRequestStatusSubject);
};

export const requestResponses = async (query: ResponseQuery) => {
    const queryString = qs.stringify(query);

    await apiCall(async () => {
        const responses = await getJson<Response[]>({
            url: `${apiUri}/responses?${queryString}`,
        }, jwtSubject.value);

        repsonsesSubject.next(produce(repsonsesSubject.value, (draft: {[id: string]: Response}) => {
            responses.forEach((x: Response) => {
                draft[getResponseId(x)] = x;
                return draft;
            });
        }));
    }, responseRequestStatusSubject);
};

export const createOrUpdateResponse = async (response: Response) => {
    try {
        const responseId = getResponseId(response);
        setActiveFormId(response.formId);
        repsonsesSubject.next(produce(repsonsesSubject.value, (draft: {[id: string]: Response}) => {
            draft[getResponseId(response)] = response;
            return draft;
        }));

        repsonseUpdateStatusSubject.next('PROCESSING');
        responseStatusesSubject.next(produce(responseStatusesSubject.value, draft => {
            draft[responseId] = 'PROCESSING';
        }));

        const updatedResponse = await postJson<Response>({
            url: `${apiUri}/responses`,
            data: JSON.stringify(response),
        }, jwtSubject.value);

        repsonsesSubject.next(produce(repsonsesSubject.value, (draft: {[id: string]: Response}) => {
            draft[getResponseId(updatedResponse)] = updatedResponse;
            return draft;
        }));

        responseStatusesSubject.next(produce(responseStatusesSubject.value, draft => {
            draft[responseId] = 'SUCCESS';
        }));
        repsonseUpdateStatusSubject.next('SUCCESS');

        setTimeout(() => {
            // responseStatusesSubject.next(produce(responseStatusesSubject.value, draft => {
            //     draft[responseId] = null;
            // }));
            repsonseUpdateStatusSubject.next(null);
        }, 3000);
    }
    catch (err) {
        const responseId = getResponseId(response);
        responseStatusesSubject.next(produce(responseStatusesSubject.value, draft => {
            draft[responseId] = 'ERROR';
        }));
        repsonseUpdateStatusSubject.next('ERROR');

        handleErrorMessage('dddResponseCreateOrUpdateError', err, 'Response creation', false);

        setTimeout(() => {
            // responseStatusesSubject.next(produce(responseStatusesSubject.value, draft => {
            //     draft[responseId] = null;
            // }));
            repsonseUpdateStatusSubject.next(null);
        }, 3000);
    }
};

export const deleteResponses = async (kapitel: number, thema: number) => {
    if (!userDataSubject.value) {
        return;
    }

    await apiCall(async () => {
        await del({
            url: `${apiUri}/responses?kapitel=${kapitel}&thema=${thema}`,
        }, jwtSubject.value);

        repsonsesSubject.next(produce(repsonsesSubject.value, (draft) => {
            const keys = Object.keys(draft);
            keys.forEach(key => {
                const response = draft[key];
                if (userDataSubject.value && response.userId === userDataSubject.value.id && response.kapitel === kapitel && response.thema === thema) {
                    delete draft[key];
                }

                return draft;
            });
        }));
    }, repsonseUpdateStatusSubject)
}
