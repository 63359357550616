import React, { useState } from 'react';

import FullLayout from '../Layout/FullLayout';
import SubmitStatus from '../Core/SubmitStatus';
import Button, { buttonStyle } from '../Core/Button';
import { vocabUpdateStatusSubject, uploadVocabFile } from '../../store/VocabStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { colorSubject } from '../../store/EnvironmentStore';
import Loader from '../Core/Loader';

const VocabImportPage = () => {
    const [file, setFile] = useState<File | null>(null);

    const vocabUpdateStatus = useBehaviorSubject(vocabUpdateStatusSubject);
    const color = useBehaviorSubject(colorSubject);

    const handleUpdate = () => {
        uploadVocabFile(file);
        setFile(null);
    }

    const setVocabFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setFile(file);
        }
        if (ev.target) {
            ev.target.value = '';
        }
    }

    const renderUpdateButton = () => {
        return file !== null && <Button _key={'vocab_update_button'} color={color} onClick={() => handleUpdate()}>Update Vocab</Button>;
    }

    return (
        <FullLayout>
            <Loader isLoading={vocabUpdateStatus === 'PROCESSING'}/>
            <div className='bg-stone-100 pt-12 pb-24 grid'>
                <div className='w-content place-self-center bg-white p-6'>
                    <span>
                        <label htmlFor="vocab-upload" style={{...buttonStyle, backgroundColor: color, marginRight: '20px'}}>Choose File</label>
                        <input style={{display: 'none'}} id="vocab-upload" type="file" name="vocabUpload" onChange={(ev) => setVocabFile(ev)} accept=".xlsx" />
                    </span>
                    {renderUpdateButton()}
                    <SubmitStatus color={color} status={vocabUpdateStatus}/>
                </div>
                {file !== null
                    && <div>File to upload: {file.name}. Click 'Update Vocab' to import.</div>}
            </div>
        </FullLayout>
    );
}

export default VocabImportPage;