import React from 'react';

import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { infoSubject, clearInfo } from '../store/ErrorStore';
import DDDModal from './Core/DDDModal';

const InfoModal = () => {
    const info = useBehaviorSubject(infoSubject);
    const show = info !== null;
    const title = show && info.header;

    return (
        <DDDModal show={show}
            title={title || 'Info'}
            close={clearInfo}
            dismissText='OK'
            >
            {info && info.message}
        </DDDModal>
    );
};

export default InfoModal;
