import React from 'react';
import CognitoUserComponent from './CognitoUser';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { locationSubject } from '../../history';
import { userIdSelector, activeUserSubject, activeCognitoUsersSubject } from '../../store/UserEdit';


const CognitoUserSummary: React.FC = () => {
    const userId = userIdSelector(useBehaviorSubject(locationSubject));
    const activeCognitoUsers = useBehaviorSubject(activeCognitoUsersSubject);
    const activeUser = useBehaviorSubject(activeUserSubject);
    const email = (activeUser && activeUser.email) || '';

    if (activeCognitoUsers && activeCognitoUsers.length === 0) {
        return (
            <>
                <Header/>
                <div className="alert alert-warning" role="alert">
                    <p>No login activity yet. This typically means the user has not signed in for the first time.</p>

                    <p>If they have attempted to sign in and cannot access the site, confirm that they are using the email <strong>{email}</strong>.</p>
                </div>
            </>
        )
    }

    return (
        <>
            <Header/>
            <div>
                {userId && activeCognitoUsers && activeCognitoUsers
                    .map(u => <CognitoUserComponent cognitoUser={u} key={`cognito_user_${u.username}`} email={email} userId={userId} />)}
            </div>
        </>
    )
}

const Header: React.FC = () => 
    <div>
        <h3>Login Info</h3>
    </div>

export default CognitoUserSummary;
