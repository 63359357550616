import React, { useEffect } from 'react';

import './css/main.css';
import { Routes } from './routes';
import { requestKapitels } from './store/KapitelStore';
import { requestInstitutions } from './store/InstitutionStore';
import { requestRoles } from './store/RolesStore';
import { requestThemas } from './store/ThemaStore';
import { Router } from 'react-router';
import history from './history';
import { requestVersions } from './store/VersionStore';
import ReactModal from 'react-modal';
import { containerDivId } from './constants/AppConstants';

const App: React.FunctionComponent = () => {
  ReactModal.setAppElement(containerDivId)

  // request initial state
  useEffect(() => {
    requestKapitels();
    requestThemas();
    requestInstitutions();
    requestRoles();
    requestVersions();
  }, []);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

export default App;
