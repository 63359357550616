import React from 'react';
import { HashLink } from '../Core/HashLink';

const Arrow = (props: {
  baseWidth: string,
  sidesWidth: string,
  color: string,
  radius: string,
  className?: string,
  href?: string,
  point: 'up' | 'left' | 'right' | 'down'
}) => {
  const {baseWidth, sidesWidth, color, radius, className, href} = props;

  let style = {};
  switch (props.point) {
    case 'down': {
      style = {
        borderLeft: `${sidesWidth} solid transparent`,
        borderRight: `${sidesWidth} solid transparent`,
        borderTop: `${baseWidth} solid ${color}`,
        borderRadius: radius,
      };
      break;
    }
    case 'up': {
      style = {
        borderLeft: `${sidesWidth} solid transparent`,
        borderRight: `${sidesWidth} solid transparent`,
        borderBottom: `${baseWidth} solid ${color}`,
        borderRadius: radius,
      };
      break;
    }
    case 'left': {
      style = {
        borderBottom: `${sidesWidth} solid transparent`,
        borderTop: `${sidesWidth} solid transparent`,
        borderRight: `${baseWidth} solid ${color}`,
        borderRadius: radius,
      };
      break;
    }
    case 'right': {
      style = {
        borderBottom: `${sidesWidth} solid transparent`,
        borderTop: `${sidesWidth} solid transparent`,
        borderLeft: `${baseWidth} solid ${color}`,
        borderRadius: radius,
      };
      break;
    }
  }

  return href === undefined
    ? <div className={className} style={style}/>
    : <HashLink to={href} className={className} style={style}></HashLink>;
}

export default Arrow;
