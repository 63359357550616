import React, { useState, useEffect, useCallback, useRef } from 'react';
import qs from 'qs';

import { SectionType } from '../../constants/AppConstants';

import SliderSideBar from './SliderSideBar';

import * as SlidesStore from '../../store/SlideStore';
import {Response} from '../../store/ResponseStore';

import history, { locationSubject } from '../../history';
import {Location} from "history";
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { kapitelSubject, themaSubject } from '../../store/EnvironmentStore';
import SliderContent from './SliderContent';

interface Props {
    slides: SlidesStore.Slide[];
    responses: Response[];
    type: SectionType;
}

function useQueryParams(
    kapitel: number,
    thema: number,
    queryParameter: string,
    slides: SlidesStore.Slide[],
    location: Location,
) {
    // Keep track of whether the hook has been run before
    const firstUpdate = useRef(true);

    const getQueryStringLabel = () : string => {
        if (location) {
            // TODO: better typing here
            const query = qs.parse(location.search.replace('?', '')) as any;
            return query[queryParameter] || '';
        }
        return '';
    }

    const getIndexFromLabel = useCallback((label: string) => {
        if (slides) {
            const index = slides.findIndex(
                (x: SlidesStore.Slide) => x.label === label);
            return index === -1 ? 0 : index;
        }
        return 0;
    }, [slides]);

    const [label, setLabel] = useState(getQueryStringLabel());
    const [index, setIndex] = useState(getIndexFromLabel(label));

    const selected = {
        index,
        label
    };

    const setSelected = useCallback((index: number, label: string) => {
        setIndex(index);
        setLabel(label);
    }, []);

    const query: any = location
        ? qs.parse(location.search.replace('?', ''))
        : {};
    query[queryParameter] = label;
    if (index === 0) {
        delete query[queryParameter];
    }
    const queryString = qs.stringify(query);
    const renderedQueryString = queryString.length > 0 ? `?${queryString}` : ''

    // Hook to update the query string
    useEffect(() => {
        history.push(`/content/${kapitel}/${thema}${renderedQueryString}`);
    }, [kapitel, thema, renderedQueryString]);


    // Hook to reset selections due to navigating to different kapitel/thema
    useEffect(() => {
        // Exclude from running on the first render
        if (firstUpdate.current) {
            return;
        }
        setSelected(0, '');
    }, [kapitel, thema, setSelected]);

    // Hook to reset index if label changes
    // (This handles a fresh page load with query params)
    useEffect(() => {
        if (label) {
            const newIndex = getIndexFromLabel(label);
            if (newIndex > 0 && newIndex !== index) {
                setSelected(newIndex, label);
            }
        }
    }, [setSelected, getIndexFromLabel, label, index]);

    // Hook to update whether it's the first render
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }
    }, []);

    return {
        selected,
        setSelected
    };
}

const Slider = (props: Props) => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const location = useBehaviorSubject(locationSubject);
    const queryParameter = props.type;
    const { slides } = props;

    const { selected, setSelected } = useQueryParams(kapitel, thema, queryParameter, slides, location);
    const activeIndex = selected.index;

    return (
        <div className='h-[37.5rem] flex'>
            <SliderSideBar
              slides={props.slides}
              activeIndex={activeIndex}
              setSelected={setSelected}
              />
            <SliderContent slides={props.slides} responses={props.responses} activeIndex={activeIndex}/>
        </div>
    );
};

export default Slider;
