import { BehaviorSubject } from 'rxjs';
import { getJson } from '../utilities/Requests';
import { apiUri, baseUri, CustomErrorType, authConstants, Roles, DDD_LOGIN_STATE } from '../constants/AppConstants';
import { DDD_JWT, DDD_USER_DATA, DDD_TOKEN_EXPIRY_UTC, DDD_NEXT_PATHNAME } from '../constants/AppConstants';
import history from '../history';
import { handleGenericError, handleErrorMessage, logData, LogLevel } from './ErrorStore';
import { DDDError } from '../constants/JsonError';
import { makeId } from '../utilities';

export interface UserData {
    readonly id: number;
    readonly dateCreated: Date;
    readonly dateModified: Date;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly institutionId: number;
    readonly roleIds: number[];
    readonly roles: string[];
}

interface LoginResponse {
    readonly user: UserData;
    readonly jwt: string;
    readonly expiration: string;
}

const expirationIntervalMs = 10000;

const isAdminSelector = (userData: UserData | null) => userData !== null && userData !== undefined && userData.roles.indexOf('admin') > -1;
const isCourseInstructorSelector = (userData: UserData | null) => userData !== null && userData !== undefined && userData.roleIds.indexOf(Roles.CourseInstructor) > -1;
const isSectionInstructorSelector = (userData: UserData | null) => userData !== null && userData !== undefined && userData.roleIds.indexOf(Roles.SectionInstructor) > -1;
const isAuthenticatedSelector = (jwt: string | null) => jwt !== null && jwt !== '';
const userInstitutionIdSelector = (userData: UserData | null) => (userData !== null && userData !== undefined && userData.institutionId) || null;
const userIdSelector = (userData: UserData | null) => (userData !== null && userData !== undefined && userData.id) || null;
const isTokenExpiredSelector = (tokenExpiration: string | null) => (tokenExpiration && tokenExpiration < new Date(new Date().getTime() - expirationIntervalMs * 2).toISOString()) || false;

const userDataString = localStorage.getItem(DDD_USER_DATA);
const expirationString = localStorage.getItem(DDD_TOKEN_EXPIRY_UTC);

export const jwtSubject = new BehaviorSubject<string | null>(localStorage.getItem(DDD_JWT));
export const tokenExpirationSubject = new BehaviorSubject<string | null>(expirationString ?? null);
export const userDataSubject = new BehaviorSubject<UserData | null>(userDataString ? JSON.parse(userDataString) : null);
export const isAdminSubject = new BehaviorSubject<boolean>(isAdminSelector(userDataSubject.value));
export const isCourseInstructorSubject = new BehaviorSubject<boolean>(isCourseInstructorSelector(userDataSubject.value));
export const isSectionInstructorSubject = new BehaviorSubject<boolean>(isSectionInstructorSelector(userDataSubject.value));
export const isAuthenticatedSubject = new BehaviorSubject<boolean>(isAuthenticatedSelector(jwtSubject.value));
export const userInstitutionIdSubject = new BehaviorSubject<number | null>(userInstitutionIdSelector(userDataSubject.value));
export const userIdSubject = new BehaviorSubject<number | null>(userIdSelector(userDataSubject.value));
export const showTokenExpirationDialogSubject = new BehaviorSubject<boolean>(isTokenExpiredSelector(tokenExpirationSubject.value));

jwtSubject.subscribe((x) => isAuthenticatedSubject.next(isAuthenticatedSelector(x)));
userDataSubject.subscribe((x) => {
    isAdminSubject.next(isAdminSelector(x));
    isCourseInstructorSubject.next(isCourseInstructorSelector(x));
    isSectionInstructorSubject.next(isSectionInstructorSelector(x));
    userInstitutionIdSubject.next(userInstitutionIdSelector(x));
    userIdSubject.next(userIdSelector(x));
});

setInterval(() => {
    if (isTokenExpiredSelector(tokenExpirationSubject.value)) {
        expireToken();
    }
}, expirationIntervalMs);

export const verifyAwsLogin = async (code: string, redirect_uri: string, attempt: number = 1) => {
    logData('dddIssuingTokenVerificationRequest', `Verifying code ${code}, attempt ${attempt}`, LogLevel.Info);
    if (attempt > 3) {
        history.push('/');
        handleErrorMessage('dddGenericLoginError', 'Encountered an error during the login process. Please try again. If you encounter repeated login errors, please contact the site administrator.', null, true);
        return;
    }

    getJson<LoginResponse>({
        url: `${apiUri}/auth/verifyAwsCode?code=${code}&redirect_uri=${redirect_uri}`,
    })
        .then(data => {
            jwtSubject.next(data.jwt);
            userDataSubject.next(data.user);
            tokenExpirationSubject.next(data.expiration);
            showTokenExpirationDialogSubject.next(false);
            const userId = data.user.id;
            logData('dddVerifyLoginSuccess', `User ${userId} successfully logged in`, LogLevel.Info);

            const nextPathName = localStorage.getItem(DDD_NEXT_PATHNAME) || baseUri;
            logData('dddLoginNextPathName', `Next path name upon login for user ${userId}: ${nextPathName}`, LogLevel.Info);

            localStorage.setItem(DDD_JWT, data.jwt);
            localStorage.setItem(DDD_USER_DATA, JSON.stringify(data.user));
            localStorage.setItem(DDD_TOKEN_EXPIRY_UTC, data.expiration)
            localStorage.removeItem(DDD_NEXT_PATHNAME);
            localStorage.removeItem(DDD_LOGIN_STATE);

            const getLocation = (href: string) => {
                var l = document.createElement("a");
                l.href = href;
                return l;
            };
            const location = getLocation(nextPathName);
            history.push(location.pathname);
        })
        .catch(error => {
            const exceptionInfo = error.body as DDDError;

            // If there is no specific exception, retry
            // if (!exceptionInfo) {
            //     verifyAwsLogin(code, redirect_uri, attempt + 1);
            //     return;
            // }

            history.push('/');

            switch (exceptionInfo?.exceptionTypeId)
            {
                case CustomErrorType.ThirdPartyTokenVerificationFailed:
                    handleErrorMessage('dddThirdPartyTokenVerificationFailure', 'Unable to authenticate with the third-party identity provider. Please contact the site administrator.', null, true);
                    return;

                case CustomErrorType.UserNotFoundInDb:
                    handleErrorMessage('dddUserNotFound', 'Your username was not present in the der-die-das user list. Only pre-approved users are able to use der-die-das. If you would like to request an account, please contact jrankin@princeton.edu.', null, true);
                    return;

                default:
                    handleGenericError();
                    return;
                }
        });
}

export const verifyLogin = async (accessToken: string, attempt: number = 1) => {
    logData('dddIssuingTokenVerificationRequest', `Verifying token ${accessToken}, attempt ${attempt}`, LogLevel.Info);

    if (attempt > 3) {
        history.push('/');
        handleErrorMessage('dddGenericLoginError', 'Encountered an error during the login process. Please try again. If you encounter repeated login errors, please contact the site administrator.', null, true);
        return;
    }

    getJson<LoginResponse>({
        url: `${apiUri}/auth/verifyAccessToken?token=${accessToken}`,
    })
        .then(data => {
            jwtSubject.next(data.jwt);
            userDataSubject.next(data.user);
            tokenExpirationSubject.next(data.expiration);
            showTokenExpirationDialogSubject.next(false);
            const userId = data.user.id;
            logData('dddVerifyLoginSuccess', `User ${userId} successfully logged in`, LogLevel.Info);

            const nextPathName = localStorage.getItem(DDD_NEXT_PATHNAME) || baseUri;
            logData('dddLoginNextPathName', `Next path name upon login for user ${userId}: ${nextPathName}`, LogLevel.Info);

            localStorage.setItem(DDD_JWT, data.jwt);
            localStorage.setItem(DDD_USER_DATA, JSON.stringify(data.user));
            localStorage.setItem(DDD_TOKEN_EXPIRY_UTC, data.expiration)
            localStorage.removeItem(DDD_NEXT_PATHNAME);
            localStorage.removeItem(DDD_LOGIN_STATE);

            const getLocation = (href: string) => {
                var l = document.createElement("a");
                l.href = href;
                return l;
            };
            const location = getLocation(nextPathName);
            history.push(location.pathname);
        })
        .catch(error => {
            const exceptionInfo = error.body as DDDError;

            // If there is no specific exception, retry
            if (!exceptionInfo) {
                verifyLogin(accessToken, attempt + 1);
                return;
            }

            history.push('/');

            switch (exceptionInfo.exceptionTypeId)
            {
                case CustomErrorType.ThirdPartyTokenVerificationFailed:
                    handleErrorMessage('dddThirdPartyTokenVerificationFailure', 'Unable to authenticate with the third-party identity provider. Please contact the site administrator.', null, true);
                    return;

                case CustomErrorType.UserNotFoundInDb:
                    handleErrorMessage('dddUserNotFound', 'Your username was not present in the der-die-das user list. Only pre-approved users are able to use der-die-das. If you would like to request an account, please contact jrankin@princeton.edu.', null, true);
                    return;

                default:
                    handleGenericError();
                    return;
                }
        });
}

export const signin = async () => {
    if (history.location) {
        localStorage.setItem(DDD_NEXT_PATHNAME, history.location.pathname || baseUri);
    }

    const state = makeId(8);
    const clientId = '47bd3qm88eeiacf5tne03d2542';
    const url = `https://${authConstants.DOMAIN}/login?client_id=${clientId}&response_type=code&scope=email+openid&redirect_uri=${baseUri}/callback/`;
    localStorage.setItem(DDD_LOGIN_STATE, state);
    window.location.href = url;
}

export const signout = () => {
    logData('dddUserSignOut', `User ${userIdSubject.value} signed out`, LogLevel.Info);
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem(DDD_TOKEN_EXPIRY_UTC);
    localStorage.removeItem(DDD_USER_DATA);
    localStorage.removeItem(DDD_JWT);
    userDataSubject.next(null);
    jwtSubject.next(null);
    tokenExpirationSubject.next(null);
    history.push('/');
}

export const expireToken = () => {
    logData('dddTokenExpiration', `Expiring token for user ID ${userIdSubject.value}`, LogLevel.Info);
    history.push('/');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem(DDD_TOKEN_EXPIRY_UTC);
    localStorage.removeItem(DDD_USER_DATA);
    localStorage.removeItem(DDD_JWT);
    tokenExpirationSubject.next(null);
    jwtSubject.next(null);
    userDataSubject.next(null);
    showTokenExpirationDialogSubject.next(true);
}

export const resetTokenExpirationDialog = () => {
    showTokenExpirationDialogSubject.next(false);
}
