import { BehaviorSubject } from 'rxjs';
import { getJson } from '../utilities/Requests';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { apiCall } from '../utilities/RequestHelpers';

export interface Institution {
    iconPath: string;
    name: string;
    id: number;
}

export const institutionsSubject = new BehaviorSubject<Institution[]>([]);
export const institutionRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
let requestedInstitutions = false;

export const requestInstitutions = async () => {
    if (requestedInstitutions) {
        return;
    }

    await apiCall(async () => {
        const institutions = await getJson<Institution[]>({ url: `${apiUri}/institutions`, });
        institutionsSubject.next(institutions);
        requestedInstitutions = true;
    }, institutionRequestStatusSubject);
}