import * as React from 'react';
import { RequestStatus } from '../../constants/AppConstants';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faCheck, faTimes);

interface SubmitStatusIconProps {
    status: RequestStatus;
    color: string;
}

class SubmitStatusIcon extends React.PureComponent<SubmitStatusIconProps> {
    private renderIcon()
    {
        const iconStyle = {
            color: 'white',
            position: 'absolute'
        };
        const successIconStyle: any = { ...iconStyle, left: 4, top: 5 };
        const errorIconStyle: any = { ...iconStyle, left: 6, top: 4};

        if (this.props.status === 'SUCCESS') {
            return <FontAwesomeIcon icon="check" style={successIconStyle}/>;
        }
        if (this.props.status === 'ERROR') {
            return <FontAwesomeIcon icon="times" style={errorIconStyle}/>;
        }
    }

    render() {
        const { color } = this.props;

        const style: any = {
            ...baseStyle,
            backgroundColor: color,
        };

        return (
            <div style={style}>
                {this.renderIcon()}
            </div>
        )
    }
}

const baseStyle = {
    width: 25,
    height: 25,
    display: 'inline-block',
    borderRadius: 13,
    position: 'relative',
    left: 10,
    top: 8
};

export default SubmitStatusIcon;
