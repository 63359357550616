import * as React from 'react'
import { Transition } from 'react-transition-group'

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  display: 'inline-block'
}

const transitionStyles: any = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

export const withFade = (wrappedComponent: any, inProp: boolean) => (
    <Transition in={inProp} timeout={duration}>
        {(state: any) => (
            <div style={{
                ...defaultStyle,
                ...transitionStyles[state]
            }}>
                {wrappedComponent}
            </div>
        )}
    </Transition>
);
