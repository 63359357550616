import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { isAuthenticatedSubject, signin } from '../../store/AuthStore';

interface Props {
    component: any;
    path: string;
}

const AuthenticatedRoute: React.SFC<Props> = (props: Props) => {
    const { component: Component, ...rest } = props;
    const isAuthenticated = useBehaviorSubject(isAuthenticatedSubject);

    if (!isAuthenticated) {
        signin();
    }

    return (
        <Route {...rest} render={() => isAuthenticated ? <Component {...rest}/> : <Redirect to={'/'}/>}/>
    )
}

export default AuthenticatedRoute;
