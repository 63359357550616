import React from 'react';

import { User } from '../../store/UserStore';
import { Response, getResponseId } from '../../store/ResponseStore';

interface IProps {
    responses: Response[];
    excludedIds: string[];
    onCheckmarkClicked: (response: Response) => void;
    users: {[id: string]: User};
}

class ResponseDisplay extends React.PureComponent<IProps> {
    private renderFormData(response: Response) {
        const { userId, formId, formData } = response;
        let data: any = [];
        const formDataJson = JSON.parse(formData);
        Object.keys(formDataJson).forEach((k: any) => {
            const v = formDataJson[k];
            data.push(
                <p key={`${userId}_${formId}_${k}`}><span style={{fontWeight: 'bold'}}>{k}:</span> {v}</p>
            );
        });
    
        return (
            <div>{data}</div>
        )
    }

    render() {
        const { responses, users } = this.props;
        return (
            <div id="ddd-printable" style={{display: responses.length > 0 ? 'block' : 'none'}}>
                <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th className="ddd-checkmark-cell">Print</th>
                                <th style={{minWidth: 100}}>Form ID</th>
                                <th style={{minWidth: 150}}>User Name</th>
                                <th style={{minWidth: 200}}>Responses</th>
                            </tr>
                            {
                                responses
                                    .map(r => {
                                    const responseId = getResponseId(r);
                                    const checked = this.props.excludedIds.indexOf(responseId) === -1;
                                    const user = users[r.userId];
                                    const userName = user ? user.lastName + ", " + user.firstName : "";
                                    return (
                                        <tr key={`response_${r.userId}_${r.formId}`} id={responseId}>
                                            <td className="ddd-checkmark-cell"><input type="checkbox" onChange={() => this.props.onCheckmarkClicked(r)} checked={checked}/></td>
                                            <td>{r.formId}</td>
                                            <td>{userName}</td>
                                            <td>{this.renderFormData(r)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ResponseDisplay;

