import React from 'react';
import { SLIDE_WIDTH } from '../../constants/AppConstants';
import { Slide } from '../../store/SlideStore';
import { Response } from '../../store/ResponseStore';
import SlideElement from './Slide';

interface Props {
  slides: Slide[];
  responses: Response[];
  activeIndex: number;
}

const SliderContent = (props: Props) => {
  const style = {
    'transitionDuration': '600ms',
    'transform': `translate3D(-${props.activeIndex * SLIDE_WIDTH}px, 0, 0)`,
    'transitionTimingFunction': 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'
  };
  return (
    <div className='bg-white w-full h-full overflow-y-auto overflow-x-hidden'>
      <div className={`relative h-full w-full`} style={style}>
          {props.slides.map((slide, index) => {
            const slideResponses = props.responses.filter(r => r.slideId == slide.id);
            return <SlideElement
              key={`slide_${slide.id}_${index}`}
              responses={slideResponses}
              content={slide.content}
              kapitel={slide.kapitel}
              thema={slide.thema}
              id={slide.id}
              index={index}/>
          })}
      </div>
    </div>
  );
};

export default SliderContent;
