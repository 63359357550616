import * as React from 'react';
import * as History from 'history';
import { HashLink as Link } from '../Core/HashLink';

import VocabWord from './VocabWord';
import { Vocab } from '../../store/VocabStore';

interface VocabSectionProps {
    readonly letter: string;
    readonly vocab: Vocab[];
    readonly location: History.Location | null;
    readonly textClass: string;
}

class VocabSection extends React.PureComponent<VocabSectionProps> {
    render() {
        const { location, textClass } = this.props;
        const pathname = location ? location.pathname : '';
        return (
            <section id={this.props.letter} className='mt-8'>
                <div className='flex justify-between'>
                    <h2 className={textClass}>
                        {this.props.letter}
                    </h2>
                    <div className='pt-6'>
                        <Link className='button font-open' style={{color: 'white'}} to={{ pathname, hash: '#thestart' }}>BACK TO THE TOP</Link>
                    </div>
                </div>
                <div className='grid grid-cols-2 text-xl gap-6'>
                {
                    this.props.vocab.map(function(word, index) {
                        return (
                            <div className="border-b border-stone-300 pb-8" key={`${word.word}_${index}`}>
                                <VocabWord vocab={word} textClass={textClass}/>
                            </div>
                        )
                    })
                }
                </div>
            </section>
        );
    }
}

export default VocabSection;
