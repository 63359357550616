import {useState, useEffect} from 'react';
import { BehaviorSubject } from 'rxjs';

function useBehaviorSubject<T>(subject: BehaviorSubject<T>) {
    const [value, setValue] = useState<T>(subject.value);

    useEffect(() => {
        const subscription = subject.subscribe(newValue => {
            setValue(newValue);
        });
        return () => subscription.unsubscribe();
    }, [subject]);

    return value;
}

export default useBehaviorSubject;
