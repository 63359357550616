import * as React from 'react';
import { Link } from 'react-router-dom';
import { assetsUri } from '../constants/AppConstants';
import AudioFile from './Content/AudioFile';

interface SearchResultProps {
    readonly word: string;
    readonly matchedKey: string;
    readonly matchedValue: string;
    readonly color: string;
    readonly translation: string;
    readonly kapitel: number;
    readonly thema: number;
}

class SearchResult extends React.PureComponent<SearchResultProps> {
    render() {
        let word = this.props.word.toLowerCase();
        word = word.replace("/", "_");
        word = word.replace("ä", "ae");
        word = word.replace("ö", "oe");
        word = word.replace("ü", "ue");
        word = word.replace("ß", "ss");
        word = word.replace("•", "_");
        
        // Replace some database column names with more appropriate display keys
        let displayKey = this.props.matchedKey;
        if (displayKey === "past_part")
            displayKey = "past participle";
        else if (displayKey === "plural_noun")
            displayKey = "plural";
        else if (displayKey === "junk_drawer")
            displayKey = "other form";
        else
            displayKey = displayKey.replace(/_/g, " ");
        
        // If the query matched something other than the word or translation, append a blurb
        // explaining which form it matched
        var contextString = "";
        if (this.props.matchedKey && this.props.matchedKey !== "word" && this.props.matchedKey !== "translation") {
            contextString = "[" + displayKey + ": " + this.props.matchedValue + "]";
        }

        return (
            <div>
                <AudioFile style={{color: this.props.color, cursor: 'pointer'}} src={`${assetsUri}/audio/firstoccurrence/${word}.mp3`}>{this.props.word}</AudioFile>&nbsp;
                <span dangerouslySetInnerHTML={{__html: this.props.translation}}/>
                <em>{contextString}</em>
                <Link style={{color: this.props.color}} to={`/content/${this.props.kapitel}/${this.props.thema}`}> [{this.props.kapitel} T{this.props.thema}]</Link>
            </div>
        );
    }
}

export default SearchResult;