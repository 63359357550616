import React from 'react';
import DDDModal from './Core/DDDModal';

import {Styles} from 'react-modal';
import { signin, showTokenExpirationDialogSubject, resetTokenExpirationDialog } from '../store/AuthStore';
import useBehaviorSubject from '../hooks/useBehaviorSubject';

const SessionExpirationDialog = () => {
    const show = useBehaviorSubject(showTokenExpirationDialogSubject);

    return (
        <DDDModal
            show={show}
            style={style}
            title='Your session has expired'
            onSubmit={signin}
            submitText='Log In'
            dismissText='Close'
            close={resetTokenExpirationDialog}
        >
            Please log in to continue using the application.
        </DDDModal>
    );
};

const style: Styles = {
    content: {
        top: '100px',
    },
    overlay: {
        zIndex: 200
    }
}

export default SessionExpirationDialog;
