import * as React from 'react';
import { MAX_SLIDE_LABELS_IN_VIEW } from '../../constants/AppConstants';
import Arrow from '../Utilities/Arrow';

const RsThumbArrow = (props: {
    point: 'down' | 'up';
    activeViewIndex: number;
    setActiveViewIndex: (index: number) => void;
    numSlides: number;
}) => {
    const handleClick = () => {
        if (props.point === 'up') {
            const activeViewIndex = Math.max(props.activeViewIndex - 7, 0);
            props.setActiveViewIndex(activeViewIndex);
        }
        else {
            const activeViewIndex = Math.min(props.activeViewIndex + 7, props.numSlides - MAX_SLIDE_LABELS_IN_VIEW);
            props.setActiveViewIndex(activeViewIndex);
        }
    }
    
    const positionClasses = props.point === 'up'
        ? 'top-0'
        : 'bottom-0';

    return (
        <div className={`absolute z-30 bg-stone-800 ${positionClasses} left-0 w-full h-6 grid cursor-pointer`} onClick={handleClick}>
            <Arrow point={props.point} color='white' baseWidth='0.5rem' sidesWidth='0.3rem' radius='0.1rem' className='place-self-center'/>
        </div>
    );
}

export default RsThumbArrow;
