import React from 'react';

import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { errorSubject, clearError } from '../store/ErrorStore';
import DDDModal from './Core/DDDModal';

const ErrorModal = () => {
    const error = useBehaviorSubject(errorSubject);
    const show = error !== null && error.notifyUser;

    return (
        <DDDModal show={show}
            title='An error occurred'
            close={clearError}
            dismissText='Ok'
            >
            {error && error.message}
        </DDDModal>
    );
};

export default ErrorModal;
