import { useEffect, useState } from 'react';

const playAudio = (el: Element) => {
    const audioFile = el.getAttribute('data-audio');
    if (audioFile) {
        const audio = new Audio(audioFile);
        audio.play();
    }
}

const useAudio = (domId: string) => {
    const [els, setElements] = useState(new Set<Element>())
    useEffect(() => {
        const elements = document.querySelectorAll(`#${domId} [data-audio]`);

        Array.prototype.forEach.call(elements, (el: Element) => {
            if (!els.has(el)) {
                el.addEventListener('click', () => playAudio(el));
                els.add(el);
            }
        });

        setElements(els);

        return () => {
            Array.prototype.forEach.call(els, (el: Element) => {
                els.delete(el);
                el.removeEventListener('click', () => playAudio(el));
            });
        }
    }, [domId, els]);
}

export default useAudio;