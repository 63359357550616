import React, {useRef, useCallback} from 'react';

import SearchResult from './SearchResult';
import * as Utilities from '../utilities';
import useAudio from '../hooks/audioHook';
import { updateQuery, resetSearchResults, vocabSearchResultsSubject, vocabQuerySubject, Vocab } from '../store/VocabStore';
import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { bgThemaSubject, colorSubject } from '../store/EnvironmentStore';
import Show from './Utilities/Show';

const VocabSearch: React.FunctionComponent = () => {
    const searchResults = useBehaviorSubject(vocabSearchResultsSubject);
    const query = useBehaviorSubject(vocabQuerySubject);
    const color = useBehaviorSubject(colorSubject);
    const bgThema = useBehaviorSubject(bgThemaSubject);

    const handleSearchChange = (query: string) => {
        updateQuery(query);
        if (query.length <= 1) {
            resetSearchResults();
        }
    }

    const renderSearchResults = <K extends keyof Vocab>(sortedResults: Vocab[], keysToCompare: K[], query: string) => {
        const regex = RegExp(query, 'i');

        let searchResultsElements = [];
 
        for (let r in sortedResults) {
            let result = sortedResults[r];
            let matchKey = keysToCompare.reduce((key: K, currentKey: K, index) => {
                if (key) return key;
                let value = result[currentKey] as string;

                if (value.search(regex) >= 0) {
                    key = currentKey;
                }

                return key;
            });
 
            let matchedValue = result[matchKey] as string;
            // TODO: TS
            // if (matchKey === "junk_drawer") matchedValue = query;
            
            if (matchKey) {
                searchResultsElements.push(
                    <SearchResult
                        color={color}
                        matchedKey={matchKey}
                        matchedValue={matchedValue}
                        key={`search_result_${result.id}`}
                        word={result.word}
                        translation={result.translation}
                        kapitel={result.kapitel}
                        thema={result.thema}
                    />);
            }
        }

        return searchResultsElements;
    }

    const node: React.RefObject<HTMLDivElement> | null = useRef(null);

    const onOutsideClick = useCallback((e: MouseEvent) => {
        // @ts-ignore
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }

        if (searchResults.length > 0) {
            resetSearchResults();
            if (query) {
                updateQuery('');
            }
        }
    }, [node, searchResults, query]);

    React.useEffect(() => {
        if (searchResults.length > 0) {
            document.addEventListener('mousedown', onOutsideClick);
        }
        else {
            document.removeEventListener('mousedown', onOutsideClick)
        }

        return () => {
            document.removeEventListener('mousedown', onOutsideClick)
        }
    }, [searchResults, onOutsideClick]);

    useAudio('vocabsearch');

    // TODO: keyof Vocab
    const keysToCompare = ["word", "translation", "plural_noun", "simple_past", "stem_change", "past_part", "junk_drawer"] as any;
    const compare = Utilities.priorityCompare(keysToCompare, query);

    const localSearchResults = searchResults.slice();
    localSearchResults.sort(compare);

    // convert the documents to SearchResult elements to be displayed, stripping out fake (mid-word) junk_drawer matches
    const searchResultsElements = renderSearchResults(localSearchResults, keysToCompare, query);

    return (
        <div className=''>
            <div className='relative'>
                <input className="float-left w-36 px-2 h-8 mt-6 rounded-none appearance-none focus:outline-none focus:border-slate-700 text-slate-800 font-serif box-content block"
                    type="search"
                    autoComplete="off"
                    name="search"
                    value={query}
                    onChange={(ev) => handleSearchChange(ev.target.value)} />
                <input className={`${bgThema} float-left px-3 mt-6 h-8 text-base font-bold hover:${bgThema}
                    cursor-pointer font-sans rounded-none appearance-none`}
                    type="submit" value="SUCHEN" id="search" />
            </div>
            <Show when={query.length > 1 && searchResultsElements.length > 0}>
                <div ref={node}
                    className='absolute p-2 mt-0.5 top-14 bg-stone-700
                        z-30 w-64 text-white normal-case text-sm
                        leading-7 border border-stone-500 font-serif
                    '>
                    {searchResultsElements}
                </div>
            </Show>
        </div>
    );
}

export default VocabSearch;
