import * as React from 'react';

import AudioButton from './AudioButton';
import AudioTimeline from './AudioTimeline';

interface AudioPlayerProps {
    readonly src: string;
    readonly color: string;
}

interface AudioPlayerState {
    readonly audio: HTMLAudioElement;
    readonly isPlaying: boolean;
    readonly interval: number | null;
    readonly currentTime: number;
    readonly duration: number;
}

class AudioPlayer extends React.PureComponent<AudioPlayerProps, AudioPlayerState> {
    constructor(props: AudioPlayerProps) {
        super(props);

        let audio = new Audio(props.src);

        this.state = {
            audio: audio,
            duration: 0,
            isPlaying: false,
            interval: null,
            currentTime: 0
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleTimelineClick = this.handleTimelineClick.bind(this);
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        const { audio } = this.state;
        audio.addEventListener('loadedmetadata', () => {
            this.setState({duration: audio.duration})
        });
    }

    private handleButtonClick(type: string) {
        if (type === 'play') {
            this.state.audio.play();
            const interval = window.setInterval(this.tick, 50);
            this.setState({isPlaying: true, interval: interval})
        }
        else if (type === 'pause') {
            this.state.audio.pause();
            this.setState({isPlaying: false, interval: null});

            if (this.state.interval) {
                clearInterval(this.state.interval);
            }
        }
    }

    private handleTimelineClick(percentage: number) {
        const audio = this.state.audio;
        audio.currentTime = this.state.duration * percentage;
        this.setState({...this.state, audio: audio});
        this.forceUpdate();
    }

    private tick() {
        this.setState({currentTime: this.state.audio.currentTime});
        if (this.state.audio.currentTime >= this.state.duration) {
            this.setState({isPlaying: false});
        }
    }

    private formatTime(time: number) {
        const totalSeconds = isNaN(time) ? 0 : parseInt(time.toString());
        const min = parseInt((totalSeconds / 60).toString());
        const sec = totalSeconds % 60;
        const displaySec = sec < 10 ? '0' + sec.toString() : sec.toString();
        return min.toString() + ':' + displaySec;
    }

    render() {
        const type = this.state.isPlaying ? 'pause' : 'play';
        const duration = this.state.audio ? this.formatTime(this.state.duration) : '0:00';
        const played = this.state.audio ? this.formatTime(this.state.audio.currentTime) : '0:00';
        const percentage = this.state.audio ? this.state.audio.currentTime / this.state.duration * 100 : 0;
        const progress = percentage.toString() + '%';
        return (
            <div className="audiojs " id="audiojs_wrapper1">
                <div className="play-pause">
                    <AudioButton color={this.props.color} handleButtonClick={(type: string) => this.handleButtonClick(type)} type={type}/>
                </div>
                <div className="scrubber">
                    <AudioTimeline progress={progress} loaded="100%" handleTimelineClick={(percentage: number) => this.handleTimelineClick(percentage)}/>
                </div>
                <div className="time">
                    <em className="played">{played}</em>/<strong className="duration">{duration}</strong>
                </div>
                <div className="error-message"></div>
            </div>
        )
    }
}

export default AudioPlayer;
