import axios, { AxiosRequestConfig } from 'axios';
import { JsonError } from "../constants/JsonError";

export const processFetch = async <T>(args: AxiosRequestConfig, jwt?: string | null): Promise<T> => {
    const authHeader = jwt ? { 'Authorization': `Bearer ${jwt}` } : {};
    const headers = { ...args.headers, ...authHeader };
    const options = { ...args, headers };

    try {
        const response = await axios.request<T>(options);
        return new Promise((resolve, reject) => {
            if (response.status >= 200 && response.status < 300) {
                resolve(response.data);
            }
            else {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    reject(new JsonError(response.data));
                }
                else {
                    reject(new Error(response.statusText));
                }
            }
        })
    }
    catch (err) {
        return new Promise((resolve, reject) => {
            const contentType = err && err.response && err.response.headers && err.response.headers["content-type"];
            if (contentType && contentType.indexOf("application/json") !== -1 && err.response) {
                reject(new JsonError(err.response.data));
            }
            else {
                const text = (err && err.response && err.response.statusText) || (err && err.message) || err;
                const url = (err && err.config && err.config.url) || '';
                reject(new Error(text + ' at ' + url));
            }
        })
    }
};

export const getJson = <T>(args: AxiosRequestConfig, jwt?: string | null) => 
    processFetch<T>(args, jwt);

export const postJson = <T>(args: AxiosRequestConfig, jwt?: string | null) => 
    processFetch<T>(
    {
        ...args,
        url: args.url,
        method: 'POST',
        headers: {
            ...args.headers,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }, jwt);

export const putJson = <T>(args: AxiosRequestConfig, jwt?: string | null) =>
    processFetch<T>(
    {
        ...args,
        url: args.url,
        method: 'PUT',
        headers: {
            ...args.headers,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }, jwt);

export const del = <T>(args: AxiosRequestConfig, jwt?: string | null) =>
    processFetch<T>(
    {
        ...args,
        url: args.url,
        method: 'DELETE',
    }, jwt);
