import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ResponseDeleteModal from "../Responses/ResponseDeleteModal";
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { isAuthenticatedSubject, signout, signin, isAdminSubject, isCourseInstructorSubject, isSectionInstructorSubject } from '../../store/AuthStore';
import { deleteResponses } from '../../store/ResponseStore';
import { bgThemaSubject, colorSubject } from '../../store/EnvironmentStore';
import { kapitelsSubject } from '../../store/KapitelStore';
import { themasSubject } from '../../store/ThemaStore';
import { showVersionUpdateModal } from '../../store/VersionStore';

const AuthLinks = () => {
    const [showResponseDeleteModal, setShowResponseDeleteModal] = useState(false);
    const isAuthenticated = useBehaviorSubject(isAuthenticatedSubject);
    const color = useBehaviorSubject(colorSubject);
    const bgThema = useBehaviorSubject(bgThemaSubject);
    const kapitels = useBehaviorSubject(kapitelsSubject);
    const themas = useBehaviorSubject(themasSubject);
    const isAdmin = useBehaviorSubject(isAdminSubject);
    const isCourseInstructor = useBehaviorSubject(isCourseInstructorSubject);
    const isSectionInstructor = useBehaviorSubject(isSectionInstructorSubject);

    const linkOptions = [
        {
            name: 'users',
            render: <Link className='block h-10 px-3 py-2' to={`/users`}>Users</Link>,
            show: isAdmin || isCourseInstructor
        },
        {
            name: 'sections',
            render: <Link className='block h-10 px-3 py-2' to={`/sections`}>Sections</Link>,
            show: isAdmin || isCourseInstructor || isSectionInstructor
        },
        {
            name: 'responses',
            render: <Link className='block h-10 px-3 py-2' to={`/responses`}>Responses</Link>,
            show: isAdmin || isCourseInstructor || isSectionInstructor
        },
        {
            name: 'edit',
            render: <Link className='block h-10 px-3 py-2' to={`/edit/1/1`}>Edit Slides</Link>,
            show: isAdmin
        },
        {
            name: 'vocabedit',
            render: <Link className='block h-10 px-3 py-2' to={`/vocabedit`}>Upload Vocab</Link>,
            show: isAdmin
        },
        {
            name: 'versionUpdate',
            render: <button className='h-10 px-3 py-2' onClick={() => showVersionUpdateModal()}><span>Update site version</span></button>,
            show: isAdmin
        },
        {
            name: 'responseDelete',
            render: <button className='h-10 px-3 py-2' onClick={() => setShowResponseDeleteModal(true)}><span>Delete Responses</span></button>,
            show: isAuthenticated
        },
        {
            name: 'signin_aws',
            render: <button className='h-10 px-3 py-2' onClick={() => signin()}>Sign In</button>,
            show: !isAuthenticated
        },
        {
            name: 'signout',
            render: <button className='h-10 px-3 py-2' onClick={() => signout()}>Sign Out</button>,
            show: isAuthenticated
        },
    ]

    const links = linkOptions
        .filter(lo => lo.show)
        .map(lo => <li key={`lo_key_${lo.name}`} className='list-none h-10 hover:bg-stone-700 cursor-pointer'>
            {lo.render}
        </li>);

    return (
        <div className='w-60 h-20 grid justify-center content-center relative group'>
            <Link to='/'>
                <div className='h-20 w-60 grid'>
                    <div className='font-open lowercase text-[1.5rem] relative place-self-center text-white'>
                        <span>der</span>
                        <div className={`relative top-1.5 mx-2 w-1 h-8 inline-block bg-stone-50`}/>
                        <span>die</span>
                        <div className={`relative top-1.5 mx-2 w-1 h-8 inline-block bg-stone-50`}/>
                        <span>das</span>
                    </div>
                </div>
            </Link>
            <ul className="absolute top-20 left-20 bg-black normal-case invisible group-hover:visible w-40 text-sm z-30">
                {links}
            </ul>
            <ResponseDeleteModal
                show={showResponseDeleteModal}
                close={() => setShowResponseDeleteModal(false)}
                deleteResponses={deleteResponses}
                color={color} 
                kapitels={kapitels}
                themas={themas}
            />
        </div>
    );
}

export default AuthLinks;
