import * as React from 'react';

interface ButtonProps {
    readonly _key: string;
    readonly onClick: () => void;
    readonly children: any;
    readonly color: string;
    readonly style?: object | null;
    readonly className?: string;
}

class Button extends React.PureComponent<ButtonProps> {

    render() {
        const { _key, onClick, children, color, style, className } = this.props;

        const additionalStyle = {
            backgroundColor: color,
            ...style
        };
        const combinedStyles: React.CSSProperties = { ...buttonStyle, ...additionalStyle }

        return (
            <span key={_key} style={combinedStyles} className={className} onClick={onClick}>
                {children}
            </span>
        )
    }
}

export const buttonStyle: React.CSSProperties = {
    color: 'white',
    padding: 10,
    textTransform: 'uppercase',
    border: 'none',
    fontFamily: 'Helvetica, Arial, sans',
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    // TODO: TS
    // ':hover': {
    //     color: 'black'
    // }
};

export default Button;
