import React from 'react';
import {marked} from 'marked';
import FullLayout from './Layout/FullLayout';

const Component: React.FC<Props> = ({ markdown, classes }) => {
    const html = marked(markdown);
    return (
        <FullLayout>
            <div className={`ddd-marked ${classes}`}>
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </div>
        </FullLayout>
    )
}

interface Props {
    markdown: string,
    classes?: string,
}

export default Component;