import React, { useState } from 'react';

import { Option } from './Core/OptionTypes';
import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { hideVersionUpdateModal, showVersionUpdateModalSubject, updateVersions } from '../store/VersionStore';
import { rolesSubject } from '../store/RolesStore';
import ThemedReactSelect from './Core/ThemedReactSelect';
import DDDModal from './Core/DDDModal';
import { colorSubject } from '../store/EnvironmentStore';

const VersionUpdateModal = () => {
    const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>([]);

    const show = useBehaviorSubject(showVersionUpdateModalSubject);
    const roles = useBehaviorSubject(rolesSubject);
    const color = useBehaviorSubject(colorSubject);

    const roleOptions = roles.map(x => ({label: x.name, value: x.id}))

    const handleRoleUpdate = (option: Option<number>[]) => {
        if (option === null) {
            setSelectedRoleIds([]);
        }

        else {
            setSelectedRoleIds(option.map(x => x.value));
        }
    }

    const selectedRoles = roleOptions.filter(x => selectedRoleIds.indexOf(x.value) > -1);
    const onSubmit = () => {
        updateVersions(selectedRoleIds);
        setSelectedRoleIds([]);
        hideVersionUpdateModal();
    }

    return (
        <DDDModal
            show={show}
            title='Update versions'
            onSubmit={onSubmit}
            submitText='Update versions'
            close={hideVersionUpdateModal}
            >
            <div className='w-60'>
                <div className='mb-3'>Update site versions for:</div>
                <ThemedReactSelect
                    color={color}
                    isMulti={true}
                    onChange={(option: any) => handleRoleUpdate(option)}
                    value={selectedRoles}
                    options={roleOptions}/>
            </div>
        </DDDModal>
    );
};

export default VersionUpdateModal;
