import React from 'react';
import Button from '../Core/Button';
import {
    CognitoUser,
    cognitoEnableUser,
    cognitoDisableUser,
    cognitoConfirmUserSignup,
    cognitoResetUserPassword,
    cognitoVerifyUserEmail,
} from '../../store/UserStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { colorSubject } from '../../store/EnvironmentStore';
import { isAdminSubject, isCourseInstructorSubject, isSectionInstructorSubject } from '../../store/AuthStore';

interface Props {
    readonly cognitoUser: CognitoUser,
    readonly email: string,
    readonly userId: number,
}

const CognitoUserActionsComponent: React.FC<Props> = (props: Props) => {
    const color = useBehaviorSubject(colorSubject);
    const { cognitoUser, email, userId } = props;
    const { username } = cognitoUser;
    const isAdmin = useBehaviorSubject(isAdminSubject);
    const isCourseInstructor = useBehaviorSubject(isCourseInstructorSubject);
    const isSectionInstructor = useBehaviorSubject(isSectionInstructorSubject);

    const emailVerified = props.cognitoUser.attributes.find(a => a.name === 'email_verified');

    if (!(isAdmin || isCourseInstructor || isSectionInstructor)) {
        return <div/>
    }

    const renderResetUserPasswordButton = () => {
        if (props.cognitoUser.userStatus.value === 'UNCONFIRMED'
            || !props.cognitoUser.enabled
            || props.cognitoUser.userStatus.value === 'EXTERNAL_PROVIDER'
            || emailVerified?.value === 'false') {
            return <div/>;
        }

        return <Button
            _key={'reset_password'}
            onClick={() => cognitoResetUserPassword(username, email, userId)}
            color={color}
            style={{ marginRight: 20 }}
            >Reset Password</Button>;
    }

    const renderConfirmButton = () => {
        if (props.cognitoUser.userStatus.value === 'UNCONFIRMED') {
            return <Button
                _key={'confirm'}
                onClick={() => cognitoConfirmUserSignup(username, email, userId)}
                color={color}
                style={{ marginRight: 20 }}
                >Confirm</Button>
        }
        return <span/>
    }

    const renderVerifyEmailButton = () => {
        if (props.cognitoUser.userStatus.value !== 'EXTERNAL_PROVIDER' && emailVerified?.value === 'false') {
            return <Button
                _key={'verify'}
                onClick={() => cognitoVerifyUserEmail(username, email, userId)}
                color={color}
                style={{ marginRight: 20 }}
                >verify email</Button>
        }
        return <span/>
    }

    const renderEnableDisableUser = () => {
        if (props.cognitoUser.enabled) {
            return <Button
                _key={'disable'}
                onClick={() => cognitoDisableUser(username, email, userId)}
                color={color}
                style={{ marginRight: 20 }}
                >Disable</Button>
        }
        else {
            return <Button
                _key={'enable'}
                onClick={() => cognitoEnableUser(username, email, userId)}
                color={color}
                style={{ marginRight: 20 }}
                >Enable</Button>
        }
    }

    return (
        <div style={{marginBottom: '20px'}}>
            {renderResetUserPasswordButton()}
            {renderConfirmButton()}
            {renderVerifyEmailButton()}
            {renderEnableDisableUser()}
        </div>
    )
}

export default CognitoUserActionsComponent;

