import React, { useState } from 'react';
import SideBarLabel from './SideBarLabel';
import { Slide } from '../../store/SlideStore';
import Show from '../Utilities/Show';
import RsThumbArrow from './RsThumbArrow';
import { MAX_SLIDE_LABELS_IN_VIEW, SLIDER_LABEL_HEIGHT } from '../../constants/AppConstants';

interface Props {
  slides: Slide[];
  activeIndex: number;
  setSelected: (index: number, label: string) => void;
}

const SliderSideBar = (props: Props) => {
  const numSlides = props.slides.length;

  // Intended to represent the index of the first slide in view
  const [activeViewIndex, setActiveViewIndex] = useState(0);
  const shouldRenderUpArrow = numSlides > MAX_SLIDE_LABELS_IN_VIEW && activeViewIndex > 0;
  const shouldRenderDownArrow = numSlides > MAX_SLIDE_LABELS_IN_VIEW && activeViewIndex < numSlides - MAX_SLIDE_LABELS_IN_VIEW;

  const style = {
    'transitionDuration': '600ms',
    'transform': `translate3D(0, -${activeViewIndex * SLIDER_LABEL_HEIGHT}px, 0)`,
    'transitionTimingFunction': 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    marginTop: shouldRenderUpArrow ? '24px' : 0
  };
  
  const setSelected = (index: number, label: string) => {
    // navigating down
    if (index > activeViewIndex + 4) {
      setActiveViewIndex(Math.min(index - 1, numSlides - 8));
    }
    // navigating up
    else if (index <= activeViewIndex + 1) {
      setActiveViewIndex(Math.max(0, Math.min(index - 3, numSlides - 7)));
    }
    props.setSelected(index, label);
  };

  return (
    <div className='overflow-hidden h-full shrink-0 bg-[#323232] text-white relative'>
      <Show when={shouldRenderUpArrow}>
        <RsThumbArrow
           point='up'
           activeViewIndex={activeViewIndex}
           setActiveViewIndex={setActiveViewIndex}
           numSlides={numSlides}
           />
      </Show>
      <div className='w-24 flex flex-col h-[100rem]'>
        <Show when={numSlides > 0}>
          <div className='relative h-full w-full' style={style}>
            {
              props.slides.map((slide, index) =>
                <SideBarLabel
                  key={`sbl_${index}`}
                  slide={slide}
                  isActive={props.activeIndex === index}
                  setSelected={() => setSelected(index, slide.label)}
                  />
              )
            }
          </div>
        </Show>
      </div>
      <Show when={shouldRenderDownArrow}>
        <RsThumbArrow
           point='down'
           activeViewIndex={activeViewIndex}
           setActiveViewIndex={setActiveViewIndex}
           numSlides={numSlides}
           />
      </Show>
    </div>
  );
};

export default SliderSideBar;

