import * as React from 'react';
import { Link } from 'react-router-dom';


interface HashLinkProps {
    children?: any,
    scroll?: Function,
    to: any,
    className?: string;
    style?: any;
}

interface HashLinkState {
    hashFragment: string;
    observer?: MutationObserver;
    asyncTimerId?: number;
}

export class HashLink extends React.Component<HashLinkProps, HashLinkState> {
    constructor(props: HashLinkProps) {
        super(props);

        this.getElAndScroll = this.getElAndScroll.bind(this);

        this.state = {
            hashFragment: ''
        }
    }

    private getElAndScroll() {
        const element = document.getElementById(this.state.hashFragment);
        if (element !== null) {
            this.scrollFunction(element);
            this.reset();
            return true;
        }
        return false;
    }

    private hashLinkScroll() {
        // Push onto callback queue so it runs after the DOM is updated
        window.setTimeout(() => {
            if (this.getElAndScroll() === false) {
                const observer = this.state.observer || new MutationObserver(this.getElAndScroll);
                observer.observe(document, {
                    attributes: true,
                    childList: true,
                    subtree: true,
                });
                // if the element doesn't show up in 10 seconds, stop checking
                const asyncTimerId = window.setTimeout(() => {
                    this.reset();
                }, 10000);

                this.setState({
                    observer,
                    asyncTimerId
                });
            }
        }, 0);
    }

    private scrollFunction(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }

    private reset() {
        const { observer, asyncTimerId } = this.state;
        if (observer) observer.disconnect();
        if (asyncTimerId) {
            window.clearTimeout(asyncTimerId);
        }

        this.setState({
            hashFragment: '',
            observer: undefined,
            asyncTimerId: undefined
        });
    }

    private handleClick() {
        this.reset();
        let hashFragment = '';
        if (typeof this.props.to === 'string') {
            hashFragment = this.props.to
                .split('#')
                .slice(1)
                .join('#');
        }
        else if (typeof this.props.to === 'object' && typeof this.props.to.hash === 'string') {
            hashFragment = this.props.to.hash.replace('#', '');
        }
        if (hashFragment !== '') {
            this.hashLinkScroll();
        }

        this.setState({hashFragment});
    }

    render() {
        const { scroll, ...filteredProps } = this.props;
        return (
            <Link {...filteredProps} onClick={() => this.handleClick()}>
                {this.props.children}
            </Link>
        );
    }
}
