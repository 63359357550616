import { BehaviorSubject } from "rxjs";
import produce from "immer";

interface KeyedObject {
    id: number;
}

export const addArrayToBehaviorMap = <T extends KeyedObject>(subject: BehaviorSubject<{[id: number]: T}>, response: T[]) => {
    subject.next(produce(subject.value, (draft: {[id: number]: T}) => {
        response.forEach((x: T) => {
            draft[x.id] = x;
            return draft;
        });
    }));
}

export const addSingleToBehaviorMap = <T extends KeyedObject>(subject: BehaviorSubject<{[id: number]: T}>, response: T) => {
    subject.next(produce(subject.value, (draft: {[id: number]: T}) => {
        draft[response.id] = response;
        return draft;
    }));
}

export const deleteSingleFromBehaviorMap = <T extends KeyedObject>(subject: BehaviorSubject<{[id: number]: T}>, id: number) => {
    subject.next(produce(subject.value, (draft: {[id: number]: T}) => {
        delete draft[id];
        return draft;
    }));
}