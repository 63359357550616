import { Location } from 'history';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { usersSubject, User, CognitoUser, userCognitoUsers } from './UserStore';
import { locationSubject } from '../history';

const emptyUser: User = {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    institutionId: 1,
    roleIds: []
};

const isUserEditRouteSelector = (location: Location | null) => location && /users\/\d+$/i.test(location.pathname);
export const userIdSelector = (location: Location | null) => {
    if (!isUserEditRouteSelector(location)) {
        return null;
    }

    const match = location && location.pathname.match(/users\/(?<userId>\d+)$/i);

    if (match && match.groups && match.groups['userId']) {
        return parseInt(match.groups['userId'], 10);
    }

    return null;
}

export const activeUserSubject = new BehaviorSubject<User>(emptyUser);
const activeUserSelector = (users: {[id: number]: User}, location: Location | null) => {
    const userId = userIdSelector(location);
    return userId !== null ? users[userId] : emptyUser;
}
combineLatest(usersSubject, locationSubject)
    .pipe(map(([users, location]) => activeUserSelector(users, location)))
    .subscribe(value => {
        if (value !== activeUserSubject.value) {
            activeUserSubject.next(value);
        }
    });
    
export const activeCognitoUsersSubject = new BehaviorSubject<CognitoUser[]>([]);
const activeCognitoUsersSelector = (cognitoUsers: {[id: number]: CognitoUser[]}, location: Location | null) => {
    const userId = userIdSelector(location);
    return userId !== null ? cognitoUsers[userId] : [];
}

combineLatest(userCognitoUsers, locationSubject)
    .pipe(map(([users, location]) => activeCognitoUsersSelector(users, location)))
    .subscribe(value => {
        if (value !== activeCognitoUsersSubject.value) {
            activeCognitoUsersSubject.next(value);
        }
    });

export const setEmail = (email: string) => { activeUserSubject.next(activeUserSubject.value && {...activeUserSubject.value, email}); }
export const setFirstName = (firstName: string) => { activeUserSubject.next(activeUserSubject.value && {...activeUserSubject.value, firstName}); }
export const setLastName = (lastName: string) => { activeUserSubject.next(activeUserSubject.value && {...activeUserSubject.value, lastName}); }
export const setInstitutionId = (institutionId: number) => { activeUserSubject.next(activeUserSubject.value && {...activeUserSubject.value, institutionId}); }
export const setRoleIds = (roleIds: number[]) => { activeUserSubject.next(activeUserSubject.value && {...activeUserSubject.value, roleIds}); }