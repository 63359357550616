import React from 'react';
import { Location } from 'history';
import * as ReactRouterDom from 'react-router-dom';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { isAdminSubject } from '../../store/AuthStore';

interface Props {
    component: any;
    path: string;
    location?: Location;
}

const AdminRoute: React.FC<Props> = (props: Props) => {
    const { component: Component, ...rest } = props;
    const isAdmin = useBehaviorSubject(isAdminSubject);

    return (
        <ReactRouterDom.Route {...rest} render={() => isAdmin ? <Component {...rest}/> : <ReactRouterDom.Redirect to={'/'}/>}/>
    )
}

export default AdminRoute;
