import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import FullLayout from '../Layout/FullLayout';
import Loader from '../Core/Loader';
import Button from '../Core/Button';
import UserUploadModal from './UserFileUploadModal';

import { importedUsersSubject, clearImportedUsers, requestUsers, usersSubject, usersToList, createOrUpdateUsers, requestUserStatusSubject, createOrUpdateUserStatusSubject } from '../../store/UserStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { institutionsSubject } from '../../store/InstitutionStore';
import { colorSubject, textThemaSubject } from '../../store/EnvironmentStore';

const UserListPage: React.SFC = () => {
    useEffect(() => {
        requestUsers(null);
    }, []);

    const [userSearch, setUserSearch] = useState('');
    const [showUserUploadModal, setShowUserUploadModal] = useState(false);
    const localImportedUsers = useBehaviorSubject(importedUsersSubject);
    const localUsers = usersToList(useBehaviorSubject(usersSubject));
    const localRequestUserStatus = useBehaviorSubject(requestUserStatusSubject);
    const createOrUpdateUserRequestStatus = useBehaviorSubject(createOrUpdateUserStatusSubject);
    const institutions = useBehaviorSubject(institutionsSubject);
    const color = useBehaviorSubject(colorSubject);
    const textThema = useBehaviorSubject(textThemaSubject);

    const areUsersFetching = localRequestUserStatus === 'PROCESSING' || createOrUpdateUserRequestStatus === 'PROCESSING';

    let newUsers = localUsers.slice();

    if (userSearch !== "") {
        let re = new RegExp(userSearch, 'i');
        newUsers = localUsers.filter(u =>
            (u.firstName && u.firstName.match(re))
            || (u.lastName && u.lastName.match(re))
            || ((u.email) && u.email.match(re))
            || ((institutions.find(i => i.id === u.institutionId) && JSON.stringify(institutions.find(i => i.id === u.institutionId)).match(re))))
    }

    return (
        <FullLayout>
            <Loader isLoading={areUsersFetching}/>
            <div className='bg-stone-100 pt-12 pb-24 grid'>
                <div className='w-content place-self-center bg-white p-6'>
                    <UserUploadModal
                        users={localImportedUsers}
                        show={showUserUploadModal}
                        createOrUpdateUsers={createOrUpdateUsers}
                        close={() => {setShowUserUploadModal(false); clearImportedUsers()}}
                        color={color}
                    />
                    <div className="clearfix" style={{ paddingBottom: 20 }}>
                        <div className="floatleft">
                            <p><label>User Search</label></p>
                            <p><input type="text" name="userSearch" value={userSearch} onChange={(ev) => setUserSearch(ev.target.value)} /></p>
                        </div>
                        <div className="floatright">
                            <Link to={`/users/add`}>
                                <Button _key={`add_user_button`} color={color} onClick={() => {}} style={{ marginRight: '10px' }}>
                                    Add User
                                </Button>
                            </Link>
                            <Button _key={`user_upload_button`} color={color} onClick={() => setShowUserUploadModal(true)}>
                                User Upload
                            </Button>
                        </div>
                    </div>
                    <table className="w-full">
                        <colgroup>
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '17%' }} />
                        </colgroup>
                        <tbody className='divide-y divide-stone-300'>
                            <tr>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Institution</th>
                                <th>Roles</th>
                            </tr>
                            {newUsers.map(user => {
                                const institution = institutions.find(i => user.institutionId === i.id);
                                return (
                                    <tr key={`user_${user.id}`}>
                                        <td><Link className={textThema} to={`/users/${user.id}`}>{user.email}</Link></td>
                                        <td><Link to={`/users/${user.id}`}>{user.firstName}</Link></td>
                                        <td><Link to={`/users/${user.id}`}>{user.lastName}</Link></td>
                                        <td>{institution ? institution.name : null}</td>
                                        <td>{user.roles.join(", ")}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </FullLayout>
    );
}

export default UserListPage;
