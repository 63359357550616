import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import Marked from './components/Marked';
import Index from './components/Index';
import Content from './components/Content/ContentPage';
import KapitelVocabPage from './components/Vocab/KapitelVocabPage';
import ThemaVocabPage from './components/Vocab/ThemaVocabPage';
import UserListPage from './components/Users/UserListPage';
import UserEditPage from './components/Users/UserEditPage';
import SectionListPage from './components/Sections/SectionListPage';
import SectionEditPage from './components/Sections/SectionEditPage';
import ResponsePage from './components/Responses/ResponsePage';
import CallbackPage from './components/CallbackPage';
import SessionExpirationDialog from './components/SessionExpirationDialog';
import VocabImportPage from './components/Vocab/VocabImportPage';
import AdminRoute from './components/Core/AdminRoute';
import AuthenticatedRoute from './components/Core/AuthenticatedRoute';
import asyncComponent from './components/Core/AsyncComponent';
import TechLiaisonMarkdown from './docs/tech-liaison-overview';
import InfoPageMarkdown from './docs/info';
import AuthMarkDown from './docs/auth';
import CommonIssues from './docs/common-issues';

const AsyncEditPage = asyncComponent(() => import('./components/SlideEdit/EditPage'));

export const Routes = () =>
<>
    <SessionExpirationDialog />
    <Switch>
        <Route exact path='/' component={ Index } />
        <Route exact path='/info'>
            <Marked markdown={InfoPageMarkdown} classes="ddd-marked-block"/>
        </Route>
        <Route exact path='/info/techliaison'>
            <Marked markdown={TechLiaisonMarkdown}/>
        </Route>
        <Route exact path='/info/techliaison/commonissues'>
            <Marked markdown={CommonIssues}/>
        </Route>
        <Route exact path='/info/auth'>
            <Marked markdown={AuthMarkDown}/>
        </Route>
        <Route path='/callback' component={ CallbackPage } />
        <AuthenticatedRoute path='/content/:kapitel/:thema' component={ Content } />
        <AuthenticatedRoute path='/responses' component={ ResponsePage } />
        <AuthenticatedRoute path='/vocab/:kapitel/:thema' component={ ThemaVocabPage } />
        <AuthenticatedRoute path='/vocab/:kapitel' component={ KapitelVocabPage } />
        <AuthenticatedRoute path='/createUser' component={ UserEditPage } />
        <AuthenticatedRoute path='/users/:userId' component={ UserEditPage } />
        <AuthenticatedRoute path='/users' component={ UserListPage } />
        <AuthenticatedRoute path='/sections/:sectionId' component={ SectionEditPage } />
        <AuthenticatedRoute path='/sections' component={ SectionListPage } />
        <AdminRoute path='/edit/:kapitel/:thema' component={ AsyncEditPage } />
        <AdminRoute path='/vocabedit' component={ VocabImportPage } />
    </Switch>
</>
