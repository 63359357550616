const markdown = `
* [**Tech liaison overview**](/info/techliaison)
* [Auth overview](/info/auth)
* [Common issues](/info/techliaison/commonissues)

## Guide for der | die | das technical liaisons

This guide includes documentation intended to provide **der | die | das** technical liaisons with
the context required to effectively administrate **der | die | das** at participating institutions.

### Scope of the role

The purpose of the role is to administrate **der | die | das** accounts and to be a first line of
defense against technical issues encountered at each participating institution.

Specifically, this can involve the following tasks, within the scope of a participating institution:
* Administrating user accounts
* Triaging incoming issues
* Resolving [common ones](info/techliaison/commonissues)
* Escalating more complex ones to Jamie and/or the site administrator.

The role does not include feature development or site support.

### User administration

See [Auth overview](/info/auth) for a summary of the design of authentication and authorization
within **der | die | das**.

New accounts can be created from the [users](/users) page, either one-by-one or by uploading a CSV
of accounts in batch. Accounts are typically created in advance of the first day of a semester and
updated throughout the semester as students add or drop classes.

### Course Sections

Course sections can be created and modified from the [sections](/sections) page. Instructors and
students can be added to sections within the section edit page.

If a student is a member of an instructor's section, the instructor is permitted to view student
responses for completion / grading purposes.

### Common Issues

See [common issues](/info/techliaison/commonissues) for a list of common issues users face when
getting access to the site for the first time.

### Getting help

If at any point you need help, or if you have ideas for improvement, feel free to get in touch with
[Jamie Rankin](mailto:jrankin@princeton.edu) (course author, content creator, and Senior Lecturer at
Princeton University) and/or [Adam Gallagher](maito:asgpng@gmail.com) (Princeton CS alumnus and site
developer).
`

export default markdown;
