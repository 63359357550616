const markdown = `
* [Tech liaison overview](/info/techliaison)
* [Auth overview](/info/auth)
* [**Common issues**](/info/techliaison/commonissues)

## Common issues

This page contains a list of the most common types of issues encountered during administration of
**der | die | das** and a summary of resolution steps.

### Username not present

**Symptom**

Affected users typically encounter the following error message when attempting to sign up for a new
account:

"Your username was not present in the der-die-das user list."

**Underlying cause**

As described in the [auth overview](/info/auth), users cannot create accounts or sign in until an
administrator or instructor has made an account for them in the **der | die | das** user list.

**Required resolution permissions**

Members of the Instructor II or admin role.

**Resolution steps**

* Verify that the user _should_ have access (i.e. that they are a student or instructor at a
  participating institution).
* Navigate to the [users page](/users).
* Ensure that the email address used by the user is in the system. The email address used to sign in
  must be an exact match with the email address in the user list. The match is case-insensitive.
* Confirm that there are no typos. If possible, confirm with the user the exact email they are using
  when signing in.

### Verification email not clicked on

**Symptoms**

* Affected users will typically experience issues signing in after having already signed up for an
  account.
* Users will exist in the 'ddd' user list, ruling out a classification in the above scenario.

**Underlying causes**

As described in the [auth overview](/info/auth), users cannot fully sign in until their account is
confirmed in AWS Cognito. This step is required to prove that the user owns the email address used
to sign in.

There are two ways this proof can be provided:

* In the case of Google sign-in, if the user has a valid Google session, we trust Google as the
  identity provider. No other validation is necessary.
* In the case of email/password sign-in, we confirm identity through email verification.

In the latter case, if the email verification link is not clicked on within 24 hours, it expires,
leaving the user's AWS Cognito account in an _unconfirmed_ state.

**Required resolution permissions**

Some of the resolution steps, like communicating with the user and triaging can be done without
extra privileges, but resolutions involving escalation likely require AWS administration
permissions.

**Resolution steps**

* Confirm whether the user has ever been able to successfully sign in before. If so, it's likely
  that there's a separate root cause.
* Confirm whether the user received the confirmation email. Advise that they check their spam
  filter. If fewer than 24 hours have passed, they may still be able to click on it and gain access.
* If applicable, suggest they sign in with a Google account instead. (**Note:** This is only
  recommended if their institution issues _.edu_ email addresses federated with Google. If not,
  using a personal GMail address should only be used as a last resort.)
* If necessary, [escalate the issue](mailto:asgpng@gmail.com) to unlock the user's AWS Cognito
  account. Include the following information:

  * Whether the user has ever been able to successfully sign in before.
  * Whether the user received the confirmation email.
  * Any other relevant context on the specific issue.

  Resolution typically involves deleting their stale account in AWS Cognito (_not in the ddd user
  list_), which allows them to sign in again as if it were the first time.

### Site unresponsive

Thankfully this is not a 'common issue'! But if this happens, [notify the site administrator](
mailto:asgpng@gmail.com).

### Conclusion

The large majority of access issues to date are of the first class ("username not present"). These
can be resolved fully by tech liaisons or instructors. Other issues will require escalation to an
AWS admin. Over time, we may be able to build resolution workflows requiring fewer permissions for
some of those issues if we observe a growth over time.
`

export default markdown;
