import { postJson } from '../utilities/Requests';
import { BehaviorSubject, timer } from "rxjs";
import { jwtSubject } from './AuthStore';
import { apiUri } from '../constants/AppConstants';
import { debounce } from 'rxjs/operators';
import produce from 'immer';

interface ErrorMessage {
    readonly code: string;
    readonly message: string;
    readonly stackTrace: string | null;
    readonly notifyUser: boolean;
}

interface InfoMessage {
    readonly code: string;
    readonly message: string;
    readonly logLevel: LogLevel;
}

interface UserInfoMessage {
    readonly code: string;
    readonly header: string;
    readonly message: string;
}

export const errorSubject = new BehaviorSubject<ErrorMessage | null>(null);
export const infoSubject = new BehaviorSubject<UserInfoMessage | null>(null);
export const clearError = () => errorSubject.next(null);
export const clearInfo = () => infoSubject.next(null);
const logSubject = new BehaviorSubject<InfoMessage[]>([]);

// 10-second debounced lag
logSubject.pipe(debounce(() => timer(1000 * 10)))
    .subscribe((logs) => { flushLogs(logs); logSubject.next([]) })

export const showInfoModal = (code: string, message: string, header: string) => {
    infoSubject.next(({code, message, header}));
}

export const handleErrorMessage = (code: string, message: string, stackTrace: string | null, notifyUser: boolean) => {
    errorSubject.next(({code, message, stackTrace, notifyUser}));
}

export const handleGenericError = () => {
    errorSubject.next({code: 'genericError', message: 'An unknown error occurred. Please contact the site administrator.', notifyUser: false, stackTrace: null});
}

export enum LogLevel {
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
    Fatal = 5,
}

errorSubject.subscribe((message: ErrorMessage | null) => {
    if (message === null) {
        return;
    }

    logData(message.code, message.message, LogLevel.Error);
});

infoSubject.subscribe((message: UserInfoMessage | null) => {
    if (message === null) {
        return;
    }

    logData(message.code, message.message, LogLevel.Info);
})

export const logData = (code: string, message: string, logLevel: LogLevel) => {
    logSubject.next(produce(logSubject.value, (draft: InfoMessage[]) => {
        draft.push({ code, message, logLevel });
        return draft;
    }))
}

const flushLogs = (logs: InfoMessage[]) => {
    if (logs.length > 0)
    {
        try {
            const uri = jwtSubject.value === null ? 'unauthenticatedlogbatch' : 'authenticatedlogbatch';
            postJson({
                url: `${apiUri}/log/${uri}`,
                data: logs
            }, jwtSubject.value);
        }
        catch (err) {
            console.log(`An error occurred while trying to flush logs.`);
        }
    }
}