import * as React from 'react';

import ThemaMenu from './ThemaMenu';
import DefaultMenu from './DefaultMenu';
import VocabMenu from '../Vocab/VocabMenu';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { locationSubject } from '../../history';
import { kapitelSubject, themaSubject } from '../../store/EnvironmentStore';

const NavMenu = () => {
    const location = useBehaviorSubject(locationSubject);
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    if (location) {
        const pathNameSplit = location.pathname.split('/');
        if (pathNameSplit.length >= 2 && pathNameSplit[1] === 'content') return <ThemaMenu kapitel={kapitel} thema={thema}/>;
        if (pathNameSplit.length >= 2 && pathNameSplit[1] === 'vocab') return <VocabMenu kapitel={kapitel} thema={thema}/>;
    }
    return <DefaultMenu />;
}

export default NavMenu;
