import React, { useEffect } from 'react';
import qs from 'qs';

import FullLayout from './Layout/FullLayout';
import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { locationSubject } from '../history';
import { verifyLogin, verifyAwsLogin } from '../store/AuthStore';
import { DDD_LOGIN_STATE, baseUri } from '../constants/AppConstants';
import { logData, LogLevel, handleErrorMessage } from '../store/ErrorStore';
import history from '../history';

const CallbackPage = () => {
    const location = useBehaviorSubject(locationSubject);
    const hash = location.hash.replace('#', '');
    const values: any = qs.parse(hash);

    const accessToken = values.access_token;
    const state = values.state;
    const loginState = localStorage.getItem(DDD_LOGIN_STATE);

    const query = qs.parse(location.search.replace('?', ''));
    const code: any = query.code;
    const error: any = query.error_description;

    useEffect(() => {
        if (accessToken && state === loginState) {
            verifyLogin(accessToken);
        }

        if (accessToken && state !== loginState) {
            logData("dddInvalidLoginState", `Invalid login state: (localStorage: ${loginState}, url: ${state})`, LogLevel.Error);
        }
    }, [accessToken, loginState, state]);

    useEffect(() => {
        if (code) {
            verifyAwsLogin(code, `${baseUri}/callback/`);
        }
    }, [code, loginState, state]);

    if (error) {
        history.push('/');
        handleErrorMessage('dddGenericLoginError', error, null, true);
    }

    return (
        <FullLayout>
            <section className='bg-white pt-12 pb-24 grid'>
                <div className='w-content place-self-center flex justify-between'>
                      Logging you in...
                </div>
            </section>
        </FullLayout>
    );
}

export default CallbackPage;
