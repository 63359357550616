import { getJson, del, postJson } from '../utilities/Requests';
import history from '../history';
import { jwtSubject } from './AuthStore';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { BehaviorSubject } from 'rxjs';
import { handleGenericError } from './ErrorStore';
import { addArrayToBehaviorMap, addSingleToBehaviorMap, deleteSingleFromBehaviorMap } from '../utilities/SubjectHelpers';
import { apiCall } from '../utilities/RequestHelpers';

export interface Section {
    id: number;
    instructorIds: number[];
    studentIds: number[];
    institutionId: number;
    name: string;
}

export const sectionsSubject = new BehaviorSubject<{[id: number]: Section}>({});
export const sectionRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const createorUpdateSectionStatusSubject = new BehaviorSubject<RequestStatus>(null);

export const requestSections = async () => {
    await apiCall(async () => {
        const sections = await getJson<Section[]>({
            url: `${apiUri}/sections`,
        }, jwtSubject.value);
        
        addArrayToBehaviorMap(sectionsSubject, sections);
    }, sectionRequestStatusSubject);
}

export const createOrUpdateSection = async (section: Section) => {
    await apiCall(async () => {
        const response = await postJson<Section>({
            url: `${apiUri}/sections`,
            data: JSON.stringify(section)
        }, jwtSubject.value) as Section;

        addSingleToBehaviorMap(sectionsSubject, response);

        history.push('/sections/' + response.id)
    }, createorUpdateSectionStatusSubject);
}

export const deleteSection = async (sectionId: number) => {
    try {
        await del({
            url: `${apiUri}/sections/${sectionId}`,
        }, jwtSubject.value);

        deleteSingleFromBehaviorMap(sectionsSubject, sectionId);

        history.push('/sections');
    }
    catch {
        handleGenericError();
    }
}