import * as React from 'react';
import { MenuContent, MenuItem } from '../../constants/MenuContent';
import { kapitels } from '../../constants/AppConstants';
import { Link } from 'react-router-dom';

class DefaultMenu extends React.PureComponent {
    render() {
        let menuContentByKapitel: {[id: number]: MenuItem[]}  = {};

        MenuContent.forEach((menuItem) => {
            if (!menuContentByKapitel[menuItem.kapitel]) {
                menuContentByKapitel[menuItem.kapitel] = [];
            }
            menuContentByKapitel[menuItem.kapitel].push(menuItem);
        });

        const kapitelList = kapitels.map((kapitel, kapitelIndex) => {
            const links = menuContentByKapitel[kapitel].map((content, index) => {
                return (
                <li key={`second_default_menu_${index}`}
                    className={`h-10 grid content-center pl-4 bg-black hover:bg-stone-700
                        cursor-pointer invisible group-${kapitelIndex}-hover:visible
                        h-0 group-${kapitelIndex}-hover:h-10 transition-height ease-out
                        `}>
                    <Link to={{ pathname: `/content/${content.kapitel}/${content.thema}`, state: {strukt: content.link, active: 'strukt', update: true}} }>
                        <span dangerouslySetInnerHTML={{__html:content.title}}/>
                    </Link>
                </li>
                );
            });
            return (
                <li
                    className={`grid content-center pl-4 h-0 bg-black invisible hover:bg-stone-700 group-hover:visible
                        cursor-pointer relative group-${kapitelIndex} transition-height -out group-hover:h-10`}
                    key={`first_default_menu_${kapitel}`}>
                    <Link to={`/content/${menuContentByKapitel[kapitel][0].kapitel}/${menuContentByKapitel[kapitel][0].thema}`}>
                        {menuContentByKapitel[kapitel][0].kapitel}
                    </Link>
                    <ul className={`absolute left-44 bg-black normal-case invisible h-0
                        group-${kapitelIndex}-hover:h-10 group-${kapitelIndex}-hover:visible
                        transition-height w-80 z-10 font-light`}>{links}</ul>
                </li>
            )
        });

        return (
            <div className='group relative h-20 grid content-center hover:bg-stone-700'>
                <span className='pl-4'>Inhalt</span>
                <ul className="absolute top-20 bg-black normal-case
                    h-0 group-hover:h-full w-44 z-10 font-light text-sm leading-none">
                    {kapitelList}
                </ul>
            </div>
        )
    }
}

export default DefaultMenu;
