import axios from 'axios';
import { BehaviorSubject, timer } from "rxjs";
import { RequestStatus, apiUri } from "../constants/AppConstants";
import { getJson, postJson } from "../utilities/Requests";
import { jwtSubject } from "./AuthStore";
import { apiCall } from "../utilities/RequestHelpers";
import { addArrayToBehaviorMap } from "../utilities/SubjectHelpers";
import produce from 'immer';
import { handleErrorMessage } from './ErrorStore';
import { debounce } from 'rxjs/operators';

export interface Vocab {
    readonly id: number;
    readonly kapitel: number;
    readonly thema: number;
    readonly word: string;
    readonly translation: string;
    readonly pluralNoun: string;
    readonly simplePast: string;
    readonly stemChange: string;
    readonly pastPart: string;
    readonly junkDrawer: string;
    readonly article: string;
    readonly letter: string;
}

export const vocabSubject = new BehaviorSubject<{[id: number]: Vocab}>({});
export const vocabRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const vocabSearchResultsSubject = new BehaviorSubject<Vocab[]>([]);
export const vocabSearchStatusSubject = new BehaviorSubject<RequestStatus>(null);
export const vocabQuerySubject = new BehaviorSubject<string>('');
export const vocabUpdateStatusSubject = new BehaviorSubject<RequestStatus>(null);

export const requestVocab = async (kapitel: number, thema: number | null) => {
    await apiCall(async () => {
        vocabRequestStatusSubject.next('PROCESSING');
        const vocab = await getJson<Vocab[]>({
            url: `${apiUri}/vocab/${kapitel}${thema ? `/${thema}` : ''}`,
        }, jwtSubject.value);

        addArrayToBehaviorMap(vocabSubject, vocab);
    }, vocabRequestStatusSubject);
};

export const searchVocab = async (query: string) => {
    await apiCall(async () => {
        const vocab = await getJson<Vocab[]>({
            url: `${apiUri}/vocab?query=${query}`,
        }, jwtSubject.value);

        vocabSearchResultsSubject.next(vocab);
    }, vocabSearchStatusSubject);
};

export const resetSearchResults = () => vocabSearchResultsSubject.next([]);

export const updateQuery = (query: string) => vocabQuerySubject.next(query);
vocabQuerySubject.pipe(debounce(() => timer(500)))
    .subscribe(query => { if (query.length > 1) searchVocab(query) });

export const uploadVocabFile = async (file: any) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        vocabUpdateStatusSubject.next('PROCESSING');
        const nextData = await axios.post<Vocab[]>(`${apiUri}/vocab/importFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${jwtSubject.value}`
            }
        })
        vocabSubject.next(produce(vocabSubject.value, () => nextData.data));
        vocabUpdateStatusSubject.next('SUCCESS');

        setTimeout(() => vocabUpdateStatusSubject.next(null), 3000);
    }
    catch (err) {
        handleErrorMessage('dddUploadVocabFileError', 'An error occurred while uploading vocab.', err.stackTrace, true);
        vocabUpdateStatusSubject.next('ERROR');
        setTimeout(() => vocabUpdateStatusSubject.next(null), 3000);
    }
}


export const updateVocab = async (vocab: Vocab[]) => {
    await apiCall(async () => {
        const updatedVocab = await postJson<Vocab[]>({
            url: `${apiUri}/vocab`,
            data: JSON.stringify(vocab),
        }, jwtSubject.value);

        addArrayToBehaviorMap(vocabSubject, updatedVocab);
    }, vocabUpdateStatusSubject);
}