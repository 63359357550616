import * as React from 'react';
import DDDModal from '../Core/DDDModal';

import {NumberOption, NumberValueType, singleSelectOnChange} from '../Core/OptionTypes';
import { Kapitel } from '../../store/KapitelStore';
import { Thema } from '../../store/ThemaStore';
import ThemedReactSelect from '../Core/ThemedReactSelect';
import { logData, LogLevel } from '../../store/ErrorStore';
import { useState } from 'react';

interface Props {
    show: boolean;
    close: () => void;
    deleteResponses: (kapitel: number, thema: number) => void;
    color: string;
    kapitels: Kapitel[];
    themas: Thema[];
}

const ResponseDeleteModal: React.FunctionComponent<Props> = (props) => {
    const [kapitel, setKapitel] = useState<number>(1);
    const [thema, setThema] = useState<number>(1);

    const handleDeleteResponse = () => {
        logData('dddResponseDeletion', `Deleting responses for kapitel ${kapitel}, thema ${thema}`, LogLevel.Info);
        props.deleteResponses(kapitel, thema);
        props.close();
    }
    
    const handleKapitelChange = (option: NumberOption) => {
        if (option && option.value) {
            setKapitel(option.value)
        }
    };

    // @ts-ignore
    const handleThemaChange = (option: NumberOption) => {
        if (option && option.value) {
            setThema(option.value);
        }
    };

      const { color, kapitels, themas } = props;
      const kapitelOptions = kapitels
          .sort((a, b) => a.kapitel - b.kapitel)
          .map(x => ({ value: x.kapitel, label: `${x.kapitel}. ${x.name}` }));
      const kapitelOption = kapitelOptions.find(x => x.value === kapitel);
      const themaOptions = themas
          .filter(x => x.kapitel === kapitel)
          .sort((a, b) => a.thema - b.thema)
          .map(x => ({ value: x.thema, label: `${x.thema}. ${x.name}` }));
      const themaOption = themaOptions.find(x => x.value === thema);
      return (
          <DDDModal
              close={props.close}
              show={props.show}
              title='Delete Responses'
              onSubmit={handleDeleteResponse}
              submitText='Delete'
          >
            <div className='font-serif'>
                <div className='my-3'>
                    <ThemedReactSelect
                        color={color}
                        onChange={(option: NumberValueType) => singleSelectOnChange(option, handleKapitelChange)}
                        value={kapitelOption}
                        options={kapitelOptions}
                    />
                </div>
                <div className='my-3'>
                    <ThemedReactSelect
                        color={color}
                        onChange={(option: NumberValueType) => singleSelectOnChange(option, handleThemaChange)}
                        formatOptionLabel={(option: any) => <span dangerouslySetInnerHTML={{__html: option.label}}/>}
                        value={themaOption}
                        options={themaOptions}
                    />
                </div>
                <p>Select a kapitel and thema to clear your responses in the specified range. This can be useful
                    as it allows you to re-do an exercise when reviewing the material. Note that some instructors
                    check responses for completion, so confirm with your instructor before clearing responses to
                    ensure that you receive credit for work you've already done.
                </p>
            </div>
        </DDDModal>
    )
}

export default ResponseDeleteModal;
