import React, { useState, useEffect } from 'react';

import FullLayout from '../Layout/FullLayout';
import Loader from '../Core/Loader';
import Button from '../Core/Button';
import ResponseFilterOptions from './ResponseFilterOptions';
import ResponseDisplay from './ResponseDisplay';

import { requestUsers, usersSubject } from '../../store/UserStore';
import { requestSections } from '../../store/SectionStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { kapitelSubject, themaSubject, colorSubject } from '../../store/EnvironmentStore';
import { sectionIdSubject, studentIdSubject, currentResponsesSubject } from '../../store/ResponseFilteringState';
import { requestResponses, Response, ResponseQuery, getResponseId, responseRequestStatusSubject, requestResponseCsv } from '../../store/ResponseStore';

const ResponsePage = () => {
    const [excludedIds, setExcludedIds] = useState<string[]>([]);
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const studentId = useBehaviorSubject(studentIdSubject);
    const sectionId = useBehaviorSubject(sectionIdSubject)
    const responses = useBehaviorSubject(currentResponsesSubject);
    const users = useBehaviorSubject(usersSubject);
    const color = useBehaviorSubject(colorSubject);
    const responseRequestStatus = useBehaviorSubject(responseRequestStatusSubject);

    useEffect(() => {
        requestUsers(null);
        requestSections();
    }, []);

    const generateReport = () => {
        let query: ResponseQuery = {
            kapitel,
            thema
        };
        if (studentId) {
            query.userId = studentId;
        }
        if (sectionId) {
            query.courseSectionId = sectionId;
        }
        requestResponses(query);
    }

    const generateCsv = () => {
        let query: ResponseQuery = {
            kapitel,
            thema
        };
        if (studentId) {
            query.userId = studentId;
        }
        if (sectionId) {
            query.courseSectionId = sectionId;
        }
        requestResponseCsv(query);
    }

    // TODO: refactor this to just pass in responses to print
    const printReport = () => {
        let newDoc = document.implementation.createHTMLDocument('Print Student Responses');
        let printableArea = document.getElementById('ddd-printable') as HTMLElement;

        newDoc.write('<html><head><title></title><link rel="stylesheet" type="text/css" href="/dist/site.css"></head><body>');
        newDoc.body.innerHTML = printableArea.innerHTML;
        newDoc.write('</body></html>');

        // Remove those reponses we've toggled off
        for (let idx in excludedIds) {
            let id = excludedIds[idx];

            let el = newDoc.getElementById(id) as HTMLElement;
            if (el && el.parentNode) {
                el.parentNode.removeChild(el);
            }
            else {
                console.log('bad' + id);
            }
        }

        // Remove the checkmarks from the page to be printed
        var checkmarks = newDoc.getElementsByClassName("ddd-checkmark-cell");
        for (let idx = checkmarks.length - 1; idx >= 0; idx--) {
            let el = checkmarks[idx];
            if (el && el.parentNode) {
                el.parentNode.removeChild(el);
            }
        }
        
        let newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title></title>
                        <link rel="stylesheet" type="text/css" href="/dist/site.css">
                    </head>
                    <body>
                        ${newDoc.body.innerHTML}
                    </body>
                </html>`);

            newWindow.document.close();
            setTimeout(() => { (newWindow as Window).print(); }, 500);
        }
    }

    const checkmarkClicked = (response: Response) => {
        const responseId = getResponseId(response);
        const index = excludedIds.indexOf(responseId);
        const newExcludedIds = excludedIds.slice();
        if (index < 0) {
            newExcludedIds.push(responseId);
        } else {
            newExcludedIds.splice(index, 1);
        }
        setExcludedIds(newExcludedIds);
    }

    return (
        <FullLayout>
            <div style={{width: 940, marginRight: 'auto', marginLeft: 'auto', marginTop: 70, fontSize: 12, background: 'white', padding: 20, marginBottom: 50}}>
                <ResponseFilterOptions/>

                <div style={{marginTop: 30, marginBottom: 30}}>
                    <Button onClick={generateReport} _key={"generate_report_button"} color={color} >Load to page</Button>
                    <Button style={{marginLeft: 10}} onClick={generateCsv} _key={"generate_csv_button"} color={color} >Generate CSV</Button>
                    {
                        responses.length
                            ? <Button style={{marginLeft: 10}} onClick={printReport} _key={"print_report_button"} color={color}>Print Report</Button>
                            : null
                    }
                </div>
                <div className="formBorder" style={{borderTop: `double ${color} 8px`}}></div>
                {
                    responseRequestStatus === 'PROCESSING'
                        ? <p> Loading responses... </p>
                        : responses.length
                            ? <p>Showing {responses.length} responses. </p>
                            : <p> Student responses will appear here. </p>
                }
            
                <Loader isLoading={responseRequestStatus === 'PROCESSING'}/>

                <ResponseDisplay responses={responses} users={users} excludedIds={excludedIds} onCheckmarkClicked={(response: Response) => checkmarkClicked(response)} />
            </div>
        </FullLayout>
    );
}

export default ResponsePage;
