import * as React from 'react';
import { Slide } from '../../store/SlideStore';

import Slider from '../Content/Slider';
import { bgThemaSubject, colorSubject, kapitelSubject, textThemaSubject, themaSubject } from '../../store/EnvironmentStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { themasSubject } from '../../store/ThemaStore';
import { Response } from '../../store/ResponseStore';
import { assetsUri, SectionType } from '../../constants/AppConstants';
import Arrow from '../Utilities/Arrow';

interface Props {
  type: SectionType;
  slides: Slide[];
  responses: Response[];
}

function getTitle(type: SectionType) {
  switch(type) {
    case 'int1':
      return 'INTERAKTIONEN I';
    case 'int2':
      return 'INTERAKTIONEN II';
    case 'strukt':
      return 'Strukturen';
  }
}

function renderArrow(type: SectionType, color: string) {
  switch(type) {
    case 'int1':
      return  <Arrow point='down' href='#strukt' color={color} baseWidth='2rem' sidesWidth='2rem' radius='0.0rem' className='place-self-center'/>
    case 'int2':
      return <Arrow point='up' href='#thestart' color={color} baseWidth='4rem' sidesWidth='4rem' radius='0.0rem' className='place-self-center'/>
    case 'strukt':
      return <Arrow point='down' href='#int2' color='white' baseWidth='2rem' sidesWidth='2rem' radius='0.0rem' className='place-self-center'/>
  }
}

const Section = (props: Props) => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const textThema = useBehaviorSubject(textThemaSubject);
    const bgThema = useBehaviorSubject(bgThemaSubject);
    const themas = useBehaviorSubject(themasSubject);
    const color = useBehaviorSubject(colorSubject);
    const themaValue = themas.find(x => x.kapitel === kapitel && x.thema === thema);
    const renderVocab = (themaValue && themaValue.renderVocab) || false;

    const handleWortschatzClick = () =>
        window.open(`/vocab/${kapitel}/${thema}`, 'newwindow', 'height=700,width=970,scrollbars=yes');

    const renderWortschatzButton = () =>
        <button className={`h-12 p-2 text-lg uppercase ${bgThema} text-white font-sans`} onClick={handleWortschatzClick}>Wortschatz</button>

    const { type, slides } = props;

    const title = getTitle(type);
    const headingClass = () => type === 'strukt' ? 'text-slate-200' : textThema;
    const style = type === 'strukt' ? {
      'backgroundImage': `url(${assetsUri}/images/kapitel${kapitel}/smalltalkthema${thema}.jpg)`,
      'backgroundSize': 'cover',
      'backgroundRepeat': 'no-repeat',
      'backgroundPosition': 'center center',
    } : {};

    const className = type === 'int2' ? '' : 'pb-12'
    return (
      <section id={type} className={`bg-stone-100 pt-12 grid ${className}`} style={style}>
          <div className='w-content place-self-center flex justify-between'>
              <h2 className={`text-[2.5rem] font-bold font-open ${headingClass()}`}>{title}</h2>
              {renderVocab && renderWortschatzButton()}
          </div>
          <div className='mt-7 mb-12 w-content place-self-center'>
              <Slider slides={slides} responses={props.responses} type={type}/>
          </div>
          {renderArrow(type, color)}
      </section>
    );
}

export default Section;
