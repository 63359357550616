import React from 'react';

import Navbar from './Navbar';
import Footer from './Footer';
import ErrorModal from '../ErrorModal';
import InfoModal from '../InfoModal';
import VersionUpdateModal from '../VersionUpdateModal';

const FullLayout: React.FunctionComponent = (props) => {
    return (
        <>
            <Navbar />
            {props.children}
            <Footer />
            <ErrorModal />
            <InfoModal />
            <VersionUpdateModal />
        </>
    );
}

export default FullLayout;
