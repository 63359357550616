import { BehaviorSubject } from 'rxjs';
import { getJson } from '../utilities/Requests';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { apiCall } from '../utilities/RequestHelpers';

export interface Role {
    id: number;
    name: string;
}

export const rolesSubject = new BehaviorSubject<Role[]>([]);
export const roleRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
let requestedRoles = false;

export const requestRoles = async () => {
    if (requestedRoles) {
        return;
    }

    await apiCall(async () => {
        const roles = await getJson<Role[]>({ url: `${apiUri}/roles`, });
        rolesSubject.next(roles);
        requestedRoles = true;
    }, roleRequestStatusSubject);
}