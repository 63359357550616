import React from 'react';
import Tippy from '@tippyjs/react';
import { renameFile } from '../../utilities';

interface Props {
    audioSrc: string | null;
    text: string;
    assetsUri: string;
    tooltip: string | null;
    color: string;
}

const FirstOccurrence: React.FunctionComponent<Props> = (props: Props) => {
    const { text, audioSrc, assetsUri, tooltip } = props;
    let audioFile = audioSrc;

    if (!audioFile) {
        const word = renameFile(text.trim().toLowerCase());
        audioFile = `${assetsUri}/audio/firstoccurrence/${word}.mp3`;
    }
    else if (audioFile.indexOf(assetsUri) === -1) {
        audioFile = `${assetsUri}/audio/firstoccurrence/${audioFile}.mp3`;
    }

    const playAudio = () => {
        if (audioFile) {
            const audio = new Audio(audioFile);
            audio.play();
        }
    }

    return <Tippy content={tooltip || ''} duration={100} hideOnClick={false}>
        <span style={{cursor: 'pointer', color: props.color, outline: 'none'}} onClick={playAudio}>{text}</span>
    </Tippy>;
}

export default FirstOccurrence;