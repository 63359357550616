import React from 'react';

import DDDModal from '../Core/DDDModal';

interface UserDeleteModalProps {
    show: boolean;
    close: () => void;
    firstName: string;
    lastName: string;
    userId: number;
    deleteUser: (userId: number) => void;
    isAdmin: boolean;
    color: string;
}

const UserDeleteModal = (props: UserDeleteModalProps) => {
    const handleDeleteUser = () => {
        props.deleteUser(props.userId);
        props.close();
    }

    const { firstName, lastName } = props;
    return (
        <DDDModal
            show={props.show}
            title='Delete User'
            onSubmit={handleDeleteUser}
            submitText='Delete'
            close={props.close}
            dismissText='Cancel'
            >
            Are you sure you want to delete the user account for {firstName} {lastName}?
        </DDDModal>
    )
}

export default UserDeleteModal;
