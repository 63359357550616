import { BehaviorSubject, combineLatest } from 'rxjs';
import { getThemaColor } from '../constants/AppConstants';
import { locationSubject } from '../history';
import { Location } from 'history';
import { Kapitel, kapitelsSubject } from './KapitelStore';
import { Thema, themasSubject } from './ThemaStore';

export const kapitelSubject = new BehaviorSubject<number>(1);
export const themaSubject = new BehaviorSubject<number>(1);
export const colorSubject = new BehaviorSubject<string>(getThemaColor(themaSubject.value));
export const bgThemaSubject =new BehaviorSubject(`bg-thema${themaSubject.value}`);
export const textThemaSubject =new BehaviorSubject(`text-thema${themaSubject.value}`);
export const borderThemaSubject =new BehaviorSubject(`border-thema${themaSubject.value}`);
export const audioPlayerIdsSubject = new BehaviorSubject<Set<string>>(new Set());
export const kapitelValueSubject = new BehaviorSubject<Kapitel | null>(null);
export const themaValueSubject = new BehaviorSubject<Thema | null>(null);
export const kapitelThemasSubject = new BehaviorSubject<Thema[]>([]);

combineLatest(kapitelSubject, kapitelsSubject)
    .subscribe(([kapitel, kapitels]) => kapitelValueSubject.next(kapitels.find(x => x.kapitel === kapitel) || null));
combineLatest(kapitelSubject, themasSubject)
    .subscribe(([kapitel, themas]) => kapitelThemasSubject.next(themas.filter(x => x.kapitel === kapitel)));
combineLatest(kapitelSubject, themaSubject, themasSubject)
    .subscribe(([kapitel, thema, themas]) => themaValueSubject.next(themas.find(x => x.kapitel === kapitel && x.thema === thema) || null));

themaSubject.subscribe((thema: number) => {
    colorSubject.next(getThemaColor(thema));
    bgThemaSubject.next(`bg-thema${thema}`);
    textThemaSubject.next(`text-thema${thema}`);
    borderThemaSubject.next(`border-thema${thema}`);
});

export const setKapitel = (kapitel: number) => kapitelSubject.next(kapitel);
export const setThema = (thema: number) => themaSubject.next(thema);

locationSubject.subscribe((location: Location) => {
    const elements = location.pathname.split('/');

    // TODO: make this logic more generic
    if (elements.length !== 4) {
        setThema(1);
        return;
    }

    const potentialKapitel = parseInt(elements[2]);
    const potentialThema = parseInt(elements[3]);

    if (!isNaN(potentialKapitel) && potentialKapitel !== kapitelSubject.value) {
        setKapitel(potentialKapitel);
    }

    if (!isNaN(potentialThema) && potentialThema !== themaSubject.value) {
        setThema(potentialThema);
    }
});
